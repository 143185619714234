import React, { useState, useEffect, useCallback } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useRestApi } from 'context/RestApiContext';
import ImageImport from 'views/ClientCards/ImageImport';
import { MessageModal } from 'components/modal/MessageModal';

// サムネイル画像編集モーダル画面
function CardThumbnailModal(props) {
  const restApi = useRestApi();

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});
  const [show, setShow] = useState(false);
  const [client, setClient] = useState({});

  // 初期表示
  useEffect(() => {
    if (props?.params?.show) {
      setClient({ ...props?.params?.client });
      setShow(true);
    }
  }, [props.params]);

  // 画像のアップロードのコールバック処理
  const imageUploadCallback = (prop, image) => {
    setClient({ ...client, [prop]: image });
  };

  // 保存処理
  const handleSave = () => {
    restApi.post('/api/client/image/save', client, (data) => {
      props.callback();
      setShow(false);
    });
  };

  // ダイアログを閉じる
  const handleClose = () => setShow(false);

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          '& .MuiPaper-root': {
            width: '800px',
            height: '588px',
            backgroundColor: '#f1f2f4',
            marginTop: '160px',
          },
        },
      }}
      open={show}
    >
      <DialogTitle>
        サムネイル画像
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Row>
          <Col md={6} className="image-col">
            <label className="client-card-label">画像1</label>
            <br />
            <div>
              <ImageImport
                image={client.IMAGE_1}
                clientCd={client.CLIENT_CD}
                imageNo={1}
                setDialog={setDialog}
                callBack={(image) => {
                  imageUploadCallback('IMAGE_1', image);
                }}
              ></ImageImport>
            </div>
          </Col>
          <Col md={6} className="image-col">
            <label className="client-card-label">画像2</label>
            <br />
            <div>
              <ImageImport
                image={client.IMAGE_2}
                clientCd={client.CLIENT_CD}
                imageNo={2}
                setDialog={setDialog}
                callBack={(image) => {
                  imageUploadCallback('IMAGE_2', image);
                }}
              ></ImageImport>
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col md={6} className="image-col">
            <label className="client-card-label">画像3</label>
            <br />
            <div>
              <ImageImport
                image={client.IMAGE_3}
                clientCd={client.CLIENT_CD}
                imageNo={3}
                setDialog={setDialog}
                callBack={(image) => {
                  imageUploadCallback('IMAGE_3', image);
                }}
              ></ImageImport>
            </div>
          </Col>
          <Col md={6} className="image-col">
            <label className="client-card-label">画像4</label>
            <br />
            <div>
              <ImageImport
                image={client.IMAGE_4}
                clientCd={client.CLIENT_CD}
                imageNo={4}
                setDialog={setDialog}
                callBack={(image) => {
                  imageUploadCallback('IMAGE_4', image);
                }}
              ></ImageImport>
            </div>
          </Col>
        </Row>
        <MessageModal dialog={dialog}></MessageModal>
      </DialogContent>
      <DialogActions>
        <Button className="btn-fill" variant="primary" size="sm" onClick={handleSave}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default CardThumbnailModal;
