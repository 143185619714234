import React, { useState, useEffect } from 'react';
import { Avatar } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { formatExpiryDate, isPastDate, isToday } from 'utils/dateUtils';
import { toInt } from 'utils/numberUtils';
('utils/numberUtils');
import './ClientCard.css';

const ClientCard = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [card, setCard] = useState({});
  const [hasNewComent, setHasNewComent] = useState(false);

  useEffect(() => {
    setCard(props.card);
    setHasNewComent(props.newCommentIds.has(toInt(props.card.CARD_ID)));
  }, [props.lists]);

  // 設定画面呼び出しボタン
  const handleClickEdit = (e) => {
    e.preventDefault();
    props.callEditModal(card);
  };

  // 期限のクリック処理
  const handleClickExpiry = () => {
    props.updateCompleteCard({
      CARD_ID: card.CARD_ID,
      COMPLETE_FLG: card.COMPLETE_FLG ? 0 : 1,
    });
    setCard({ ...card, COMPLETE_FLG: card.COMPLETE_FLG ? 0 : 1 });
  };

  // マウスオーバー時の処理
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  // マウスアウト時の処理
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className="card"
      {...props.provided.draggableProps}
      {...props.provided.dragHandleProps}
      ref={props.provided.innerRef}
      onDoubleClick={handleClickEdit}
    >
      {card.TITLE}
      <a href="#" className="avatar-container" onClick={handleClickEdit}>
        <Avatar sx={{ width: 24, height: 24 }}>
          <i className="material-symbols-rounded" style={{ fontSize: 16 }}>
            mode_edit
          </i>
        </Avatar>
      </a>

      <div className="expiry-comment">
        {/* 新着アイコン */}
        {hasNewComent && (
          <div className="newcomment-icon">
            <NotificationsActiveIcon fontSize="small" />
            New
          </div>
        )}
        {card.EXPIRY && (
          <>
            <div
              className={`expiry ${
                card.COMPLETE_FLG
                  ? 'complete'
                  : isToday(card.EXPIRY)
                  ? 'deadline'
                  : isPastDate(card.EXPIRY)
                  ? 'overdue'
                  : ''
              }`}
              onClick={handleClickExpiry}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={{ cursor: 'pointer' }}
            >
              {isHovered ? (
                card.COMPLETE_FLG === 0 ? (
                  <CheckBoxOutlineBlankOutlinedIcon fontSize="small" />
                ) : (
                  <CheckBoxOutlinedIcon fontSize="small" />
                )
              ) : (
                <AccessTimeIcon fontSize="small" />
              )}
              <span
                style={{
                  marginLeft: '6px',
                  fontSize: '10pt',
                }}
              >
                {formatExpiryDate(card.EXPIRY)}
              </span>
            </div>
          </>
        )}
        {card.COMMENT_COUNT > 0 && (
          <div>
            <ChatBubbleOutlineIcon fontSize="small" />
            <span
              style={{
                marginLeft: '6px',
                fontSize: '10pt',
              }}
            >
              {card.COMMENT_COUNT}
            </span>
          </div>
        )}
        {card.QUOTE_COUNT > 0 && (
          <div>
            <DescriptionOutlinedIcon fontSize="small" />
            <span
              style={{
                marginLeft: '6px',
                fontSize: '10pt',
              }}
            >
              {card.QUOTE_COUNT}
            </span>
          </div>
        )}
        {card.CONTRACT_COUNT > 0 && (
          <div>
            <HandshakeOutlinedIcon fontSize="small" />
            <span
              style={{
                marginLeft: '6px',
                fontSize: '10pt',
              }}
            >
              {card.CONTRACT_COUNT}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientCard;
