import React, { useState, useEffect } from 'react';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextComponent from 'components/Input/TextComponent';
import SelectComponent from 'components/Input/SelectComponent';

import { useRestApi } from 'context/RestApiContext';
import { MessageModal } from 'components/modal/MessageModal';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { checkRequired } from 'utils/validationUtils';
import { format } from 'date-fns';
import { YearManageWorks } from 'constants/constants';

// 年間管理の編集用のモーダル
function YearManageModal(props) {
  const restApi = useRestApi();

  const [show, setShow] = useState(false);
  // メッセージダイアログ
  const [dialog, setDialog] = useState({});
  // エラーフィールド
  const [errorFileds, setErrorFileds] = useState([]);
  // 年間管理ヘッダ
  const [yearManage, setYearManage] = useState({
    SUM_KIN: 0,
  });
  const handleClose = () => setShow(false);

  useEffect(() => {
    setErrorFileds([]);

    if (props?.params?.mode == 'add') {
      restApi.get(
        `/api/year_manage/${props?.params.client.CLIENT_CD}/${props?.params.client.DM_NO}`,
        (data) => {
          setYearManage({
            ...data,
            YEAR: new Date().getFullYear(),
          });
          setShow(true);
        }
      );
    } else if (props?.params?.mode == 'upd') {
      restApi.get(`/api/year_manage/${props?.params.yearManage.MANAGE_ID}`, (data) => {
        setYearManage(data);
        setShow(true);
      });
    }
  }, [props.params]);

  // 編集処理
  const handleOnChange = (prop) => (target) => {
    setYearManage({ ...yearManage, [prop]: target?.value ?? target?.target.value });
    // エラーをクリア
    setErrorFileds([...errorFileds].filter((x) => x != prop));
  };

  // 作業月の編集
  const handleOnChangeMonth = (prop) => (event) => {
    setYearManage({ ...yearManage, [prop]: event.target?.checked ? 1 : 0 });
  };

  // 入力チェック
  const validate = () => {
    let errors = [];
    checkRequired(yearManage.TITLE, '邸名', 'TITLE', errors);
    checkRequired(yearManage.LAST_UPDATE, '更新日', 'LAST_UPDATE', errors);
    Array.from({ length: 12 }, (_, i) => i + 1).forEach((i) => {
      if (yearManage[`M${i}`] == 1) {
        checkRequired(yearManage[`M${i}_WORK`], `${i}月の作業内容`, `M${i}_WORK`, errors, true);
      }
    });

    setErrorFileds(errors.flatMap((x) => x.fields));

    return errors.map((x) => x.msg);
  };

  // 保存ボタンをクリック
  const hendleClickSave = () => {
    // 入力チェック
    var error = validate();
    if (error.length > 0) {
      setDialog({
        type: 'error',
        message: error,
      });
      return;
    }

    restApi.post(
      `/api/year_manage/${props.params.mode}/${props.params.client.DM_NO}`,
      { ...yearManage, LAST_UPDATE: format(yearManage.LAST_UPDATE, 'yyyy/MM/dd') },
      (_) => {
        props.callback();
        setShow(false);
      }
    );
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '1080px',
              backgroundColor: '#f1f2f4',
              marginTop: '40px',
            },
          },
        }}
        open={show}
        disableEnforceFocus={true} // フォーカス制御を無効にする
      >
        <DialogTitle>
          年間管理
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Card className="m-0 p-2">
            <Row>
              <Col className="pr-1" md="8">
                <Form.Group>
                  <label>邸名</label>
                  <TextComponent
                    type="text"
                    value={yearManage.TITLE}
                    onChange={handleOnChange('TITLE')}
                    error={errorFileds.some((x) => x == 'TITLE')}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col className="pr-1" md="2">
                <label>西暦</label>
                <SelectComponent value={yearManage.YEAR} onChange={handleOnChange('YEAR')}>
                  {Array.from({ length: new Date().getFullYear() - 2018 + 2 }, (_, i) => 2018 + i)
                    .reverse()
                    .map((i) => (
                      <option key={i} value={i}>
                        {i}年
                      </option>
                    ))}
                </SelectComponent>
              </Col>
              <Col md="3">
                <label>更新日</label>
                <TextComponent
                  type="date"
                  value={yearManage.LAST_UPDATE}
                  onChange={handleOnChange('LAST_UPDATE')}
                  error={errorFileds.some((x) => x == 'LAST_UPDATE')}
                ></TextComponent>
              </Col>
              <Col md="2">
                <label>価格</label>
                <TextComponent
                  max={999999999}
                  type="number"
                  value={yearManage.KAKAKU}
                  onChange={handleOnChange('KAKAKU')}
                ></TextComponent>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col className="pr-1 pr-4" md="12">
                <label>作業内容</label>
                <TextComponent
                  className="form-control"
                  type="text"
                  value={yearManage?.DESCRIPTION}
                  onChange={handleOnChange('DESCRIPTION')}
                ></TextComponent>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="pr-1" md="12">
                <label>作業月</label>
              </Col>
            </Row>
            {Array.from({ length: 6 }, (_, i) => i * 2 + 1).map((i) => (
              <Row className="mt-2">
                <Col className="pr-1" md="5" style={{ display: 'flex' }}>
                  <label
                    className="mr-3 mt-1"
                    style={{ fontSize: '14pt', color: 'black', whiteSpace: 'nowrap', width: 60 }}
                  >
                    <input
                      className="ml-2"
                      style={{ transform: 'scale(1.5)' }}
                      type="checkbox"
                      checked={yearManage[`M${i}`] == 1}
                      onChange={handleOnChangeMonth(`M${i}`)}
                    ></input>
                    &nbsp;{`${i}`}月
                  </label>
                  <SelectComponent
                    disabled={yearManage[`M${i}`] != 1}
                    value={yearManage[`M${i}_WORK`]}
                    onChange={handleOnChange(`M${i}_WORK`)}
                    error={errorFileds.some((x) => x == `M${i}_WORK`)}
                  >
                    {YearManageWorks.map((x) => (
                      <option key={x.key} value={x.key}>
                        {x.value}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
                <Col className="pr-1" md="5" style={{ display: 'flex' }}>
                  <label
                    className="mr-3 mt-1"
                    style={{ fontSize: '14pt', color: 'black', whiteSpace: 'nowrap', width: 60 }}
                  >
                    <input
                      className="ml-2"
                      style={{ transform: 'scale(1.5)' }}
                      type="checkbox"
                      checked={yearManage[`M${i + 1}`] == 1}
                      onChange={handleOnChangeMonth(`M${i + 1}`)}
                    ></input>
                    &nbsp;{`${i + 1}`}月
                  </label>
                  <SelectComponent
                    disabled={yearManage[`M${i + 1}`] != 1}
                    value={yearManage[`M${i + 1}_WORK`]}
                    onChange={handleOnChange(`M${i + 1}_WORK`)}
                    error={errorFileds.some((x) => x == `M${i + 1}_WORK`)}
                  >
                    {YearManageWorks.map((x) => (
                      <option key={x.key} value={x.key}>
                        {x.value}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
              </Row>
            ))}
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button
            variant="primary"
            className="btn-fill footer-button ml-4 mr-2"
            onClick={hendleClickSave}
            style={{ flexGrow: 1 }}
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
      {/* メッセージダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}
export default YearManageModal;
