import React, { useState, useEffect } from 'react';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextComponent from 'components/Input/TextComponent';
import SelectComponent from 'components/Input/SelectComponent';

import { useRestApi } from 'context/RestApiContext';
import { MessageModal } from 'components/modal/MessageModal';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Print } from '@mui/icons-material';

//  印刷情報の編集用のモーダル
function PrintInfoEditModal(props) {
  const restApi = useRestApi();

  const [show, setShow] = useState(false);
  // メッセージダイアログ
  const [dialog, setDialog] = useState({});
  // エラーフィールド
  const [errorFileds, setErrorFileds] = useState([]);
  // その他情報
  const [printInfo, setPrintInfo] = useState({});
  const handleClose = () => setShow(false);

  useEffect(() => {
    setErrorFileds([]);

    if (props?.params?.show) {
      setPrintInfo(props.params.printInfo);
      setShow(true);
    }
  }, [props.params]);

  // 編集処理
  const handleOnChange = (prop) => (target) => {
    setPrintInfo({ ...printInfo, [prop]: target?.value ?? target?.target.value });
  };

  // 保存ボタンをクリック
  const hendleClickSave = () => {
    restApi.post(`/api/client/printinfo/${printInfo.CLIENT_CD}`, printInfo, (_) => {
      props.params.callback(printInfo);
      setShow(false);
    });
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '1080px',
              backgroundColor: '#f1f2f4',
              marginTop: '40px',
            },
          },
        }}
        open={show}
        disableEnforceFocus={true} // フォーカス制御を無効にする
      >
        <DialogTitle>
          印刷情報情報
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Card className="m-0 p-2">
            <Row>
              <Col className="pr-1" md="2">
                <Form.Group>
                  <label>DMナンバー</label>
                  <div>{printInfo.DM_NO}</div>
                </Form.Group>
              </Col>
              <Col className="pr-1" md="2">
                <Form.Group>
                  <label>氏名</label>
                  <div>{printInfo.NAME_1}</div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="12">
                <Form.Group>
                  <label>住所</label>
                  <div>
                    〒{printInfo.BUILD_POST_CODE} {printInfo.BUILD_CITY}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col className="pr-1" md="11">
                <Form.Group>
                  <label>宛名印刷</label>
                  <SelectComponent
                    value={printInfo.PRINT_FLG}
                    onChange={handleOnChange('PRINT_FLG')}
                  >
                    <option value={1}>印刷する</option>
                    <option value={0}>印刷しない</option>
                  </SelectComponent>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1 mt-2" md="11">
                <label>印刷関連</label>
                <Form.Group>
                  <TextComponent
                    type="textarea"
                    placeholder="印刷関連のメモを記載ください"
                    rows={3}
                    value={printInfo.PRINT_MEMO}
                    onChange={handleOnChange('PRINT_MEMO')}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button
            variant="primary"
            className="btn-fill ml-4 mr-2"
            onClick={hendleClickSave}
            style={{ flexGrow: 1 }}
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
      {/* メッセージダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}
export default PrintInfoEditModal;
