import React, { useState, useEffect, useRef } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { CellType } from '@grapecity/wijmo.grid';

import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { useRestApi } from 'context/RestApiContext';

import { MessageModal } from 'components/modal/MessageModal';
import './ClientCard.css';

// 契約一覧
const ContractList = (props) => {
  const restApi = useRestApi();
  // グリッド
  const flexGrid = useRef();
  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // ダウンロードボダン押下
  const handleClickDownload = (item) => {
    restApi.getToPdf(`api/contract/report/${item.KEI_NO}`);
  };

  // 追加ボタン押下
  const handleClickAdd = () => {
    props.setContractModalParam({
      show: true,
      clientCd: props.contracts[0].CLIENT_CD,
      mode: 'add',
    });
  };

  // 編集ボタン押下
  const handleClickEdit = (item) => {
    props.setContractModalParam({
      show: true,
      clientCd: item.CLIENT_CD,
      keiNo: item.KEI_NO,
      mode: 'upd',
    });
  };

  // 削除ボタン押下
  const handleClickDelete = (item) => {
    setDialog({
      type: `confirm`,
      message: `${item.KOUJI_MEI} の契約を削除します。よろしいですか？`,
      okCallBack: () => {
        restApi.post(`/api/contract/del/${item.CLIENT_CD}/${item.KEI_NO}`, {}, (data) => {
          props.searchCard();
        });
      },
    });
  };

  return props.contracts?.length ? (
    <>
      <Row className="mt-3">
        <Col md="2">
          <div className="description-label">
            <HandshakeOutlinedIcon />
            <b>契約</b>
          </div>
        </Col>
        <Col md="10" className="text-right">
          <Button
            size="sm"
            style={{ width: '108px', height: '22px' }}
            variant="primary"
            className="btn-fill p-0"
            onClick={handleClickAdd}
          >
            <ControlPointIcon style={{ fontSize: 14 }} />
            分割契約を追加
          </Button>
        </Col>
      </Row>
      <FlexGrid
        ref={flexGrid}
        allowSorting={false}
        itemsSource={props.contracts}
        selectionMode={'Row'}
        headersVisibility={'Column'}
        onDoubleClick={(e) => {
          const grid = flexGrid.current.control;
          const ht = grid.hitTest(e);
          if (ht.cellType === CellType.Cell) {
            const item = grid.rows[ht.row].dataItem;
            handleClickEdit(item); // 契約編集ダイアログを開く処理
          }
        }}
      >
        <FlexGridColumn
          binding="KEI_NO_DISPLAY"
          header="契約番号"
          width={82}
          isReadOnly={true}
        ></FlexGridColumn>
        <FlexGridColumn
          binding="KEI_DATE"
          header="契約日"
          width={100}
          isReadOnly={true}
        ></FlexGridColumn>
        <FlexGridColumn
          binding="KOUJI_MEI"
          header="工事名"
          width="*"
          isReadOnly={true}
        ></FlexGridColumn>
        <FlexGridColumn
          binding="SUM_KIN"
          header="請負工事金額"
          width={100}
          isReadOnly={true}
          format="n0"
          dataType="Number"
        ></FlexGridColumn>
        <FlexGridColumn
          binding="KEI_KIN"
          header="契約金"
          width={100}
          isReadOnly={true}
          format="n0"
          dataType="Number"
        ></FlexGridColumn>
        <FlexGridColumn
          binding="ST_KIN"
          header="着工金"
          width={100}
          isReadOnly={true}
          format="n0"
          dataType="Number"
        ></FlexGridColumn>
        <FlexGridColumn
          binding="ED_KIN"
          header="完工金"
          width={100}
          isReadOnly={true}
          format="n0"
          dataType="Number"
        ></FlexGridColumn>
        <FlexGridColumn header="" binding="" isReadOnly={true} width={28}>
          <FlexGridCellTemplate
            cellType="Cell"
            template={(cell) => (
              <Button
                className="btn-fill material-symbols-rounded icon-button-sm"
                variant="primary"
                onClick={() => handleClickEdit(cell.item)}
              >
                edit
              </Button>
            )}
          />
        </FlexGridColumn>
        <FlexGridColumn header="" binding="" isReadOnly={true} width={28}>
          <FlexGridCellTemplate
            cellType="Cell"
            template={(cell) => (
              <Button
                className="btn-fill material-symbols-rounded icon-button-sm"
                variant="primary"
                onClick={() => handleClickDownload(cell.item)}
              >
                print
              </Button>
            )}
          />
        </FlexGridColumn>
        <FlexGridColumn header="" binding="" isReadOnly={true} width={28}>
          <FlexGridCellTemplate
            cellType="Cell"
            template={(cell) => (
              <Button
                className="material-symbols-rounded btn-fill icon-button-sm"
                variant="danger"
                onClick={() => handleClickDelete(cell.item)}
              >
                Delete
              </Button>
            )}
          />
        </FlexGridColumn>
      </FlexGrid>
      {/* メッセージダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
    </>
  ) : (
    <></>
  );
};

export default ContractList;
