import { useState, useEffect, useRef, useCallback } from 'react';
import { FlexGrid, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { Button, Card, Row, Col, Form } from 'react-bootstrap';
import { CellType, CellRange, DataMap } from '@grapecity/wijmo.grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useRestApi } from 'context/RestApiContext';
import { toFloat } from 'utils/numberUtils';
import { toDate } from 'utils/dateUtils';
import TextComponent from 'components/Input/TextComponent';
import SelectComponent from 'components/Input/SelectComponent';
import { ClientRanks } from 'constants/constants';
import PrintInfoEditModal from 'views/ClientManage/PrintInfoEditModal';

function AtenaPrintModal(props) {
  const restApi = useRestApi();

  const [clients, setClients] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const flexGridRef = useRef(null);

  // ランクのマッピング
  var clientRankMap = new DataMap(ClientRanks, 'key', 'value');
  // オリジナルデータ
  const orgClients = useRef([]);
  // スクロール位置
  const scrollPosition = useRef(0);
  // 検索条件
  const [searchCondition, setSearchCondition] = useState({
    DM_FLG: 1,
    DM_START: '',
    DM_END: '',
  });

  const [show, setShow] = useState(false);

  useEffect(() => {
    let uniqueDMNos = new Set();
    if (props.params.show) {
      let _clients = props.params.clients
        .map((x) => ({
          SELECT: x.DM_NO && toFloat(x.PRINT_FLG ?? 1) != 0, // 印刷しない以外は選択
          CLIENT_CD: x.CLIENT_CD,
          NAME_1: x.NAME_1,
          KEISHO: '様',
          BUILD_POST_CODE: x.BUILD_POST_CODE,
          BUILD_CITY: x.BUILD_CITY,
          DM_NO: toFloat(x.DM_NO),
          NO: x.DM_NO ? `No.${x.DM_NO}` : '',
          SEIKYU_DATE: toDate(x.SEIKYU_DATE),
          PRINT_FLG: x.PRINT_FLG ?? 1,
          PRINT_MEMO: x.PRINT_MEMO ?? '',
          LANK: x.LANK ?? 0,
        }))
        // 請求日の昇順でソート
        .sort((a, b) => {
          if (a.DM_NO == b.DM_NO) {
            const aValue = a.SEIKYU_DATE;
            const bValue = b.SEIKYU_DATE;
            if (!aValue) return 1; // aが日付変換できなければaを後ろに
            if (!bValue) return -1; // bが日付変換できなければbを後ろに
            return bValue < aValue ? 1 : -1;
          }

          return a.DM_NO > b.DM_NO ? -1 : 1;
        }) // DMナンバーの重複を排除
        .filter((client) => {
          if (!client.DM_NO) {
            return true;
          }
          if (uniqueDMNos.has(client.DM_NO)) {
            return false;
          }
          uniqueDMNos.add(client.DM_NO);
          return true;
        })
        .sort((a, b) => {
          return a.DM_NO - b.DM_NO;
        });
      orgClients.current = _clients;
      let _condition = {
        DM_FLG: 1,
        DM_START: '',
        DM_END: '',
      };
      setClients(_clients);
      setSearchCondition(_condition);
      filterClients(_condition);
      setShow(true);
    }
  }, [props.params]);

  // 編集処理
  const handleOnChange = (prop) => (target) => {
    let _searchCondition = { ...searchCondition, [prop]: target?.value ?? target?.target.value };
    setSearchCondition(_searchCondition);
    filterClients(_searchCondition);
  };

  // フィルタ処理
  const filterClients = (condition) => {
    let _clients = orgClients.current.filter(
      (x) =>
        ((condition.DM_FLG == 1 && toFloat(x.DM_NO) > 0) ||
          (condition.DM_FLG == 0 && toFloat(x.DM_NO) == 0)) &&
        (!condition.DM_START || toFloat(x.DM_NO) >= toFloat(condition.DM_START)) &&
        (!condition.DM_END || toFloat(x.DM_NO) <= toFloat(condition.DM_END))
    );
    setClients((prev) => _clients.map((x) => prev.find((y) => y.CLIENT_CD == x.CLIENT_CD) ?? x));
  };

  // 全て選択
  const handleSelectAll = (e) => {
    // スクロール位置を保持
    scrollPosition.current = flexGridRef.current.control.scrollPosition;

    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    setClients(clients.map((client) => ({ ...client, SELECT: isChecked })));
    requestAnimationFrame(() => {
      if (flexGridRef.current?.control) {
        flexGridRef.current.control.scrollPosition = scrollPosition.current;
      }
    });
  };

  // ダブルクリックで印刷情報更新ダイアログを開く
  const [printInfoEditModalParam, setPrintInfoEditModalParam] = useState({});

  const handleCellDoubleClick = (printInfo) => {
    // スクロール位置を保持
    scrollPosition.current = flexGridRef.current.control.scrollPosition;
    setPrintInfoEditModalParam({
      show: true,
      printInfo: printInfo,
      callback: (p) => {
        p.SELECT = p.PRINT_FLG != 0;
        setClients(clients.map((x) => (x.CLIENT_CD == p.CLIENT_CD ? p : x)));

        let grid = flexGridRef?.current?.control;
        setTimeout(() => {
          const index = clients.indexOf(clients.find((c) => c.CLIENT_CD == p.CLIENT_CD));
          grid.select(new CellRange(index), true);
          setTimeout(() => {
            grid.scrollPosition = scrollPosition.current;
          }, 50);
        }, 200);
      },
    });
  };

  // // ダブルクリックでチェックボックスを反転
  // const handleCellDoubleClick = (row) => {
  //   // スクロール位置を保持
  //   scrollPosition.current = flexGridRef.current.control.scrollPosition;

  //   const rowIndex = row;
  //   const updatedClients = [...clients];
  //   updatedClients[rowIndex].SELECT = !updatedClients[rowIndex].SELECT; // 状態を反転
  //   setClients(updatedClients);
  //   requestAnimationFrame(() => {
  //     if (flexGridRef.current?.control) {
  //       flexGridRef.current.control.scrollPosition = scrollPosition.current;
  //       flexGridRef.current.control.select(new CellRange(row), true);
  //     }
  //   });
  // };

  // ﾀﾞｳﾝﾛｰﾄﾞ
  const handleOnDownload = () => {
    restApi.postDownload(
      `/api/client/atena_print`,
      { clients: clients.filter((x) => x.SELECT) },
      '宛名印刷.csv',
      () => {}
    );
  };

  // 閉じるボタン
  const handleOnClose = () => {
    setShow(false);
  };

  return (
    <>
      <Dialog fullWidth={true} maxWidth={'xl'} open={show}>
        <DialogTitle className="p-3">宛名印刷</DialogTitle>
        <DialogContent className="px-0 py-0 pr-0 pl-0">
          <Card className="m-0">
            <Card.Body>
              <Row>
                <Col className="d-flex align-items-end  pr-1" md="1">
                  <label
                    className="mr-3"
                    style={{ fontSize: '14pt', color: 'black', whiteSpace: 'nowrap', width: 60 }}
                  >
                    <input
                      className="mr-3"
                      style={{ transform: 'scale(1.5)' }}
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                    ></input>
                    全て選択
                  </label>
                </Col>
                <Col className="pr-1" md="4">
                  <label>DMナンバー</label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <SelectComponent
                      className="mr-2"
                      value={searchCondition.DM_FLG}
                      onChange={handleOnChange('DM_FLG')}
                    >
                      <option value={1}>DMあり</option>
                      <option value={0}>DMなし</option>
                    </SelectComponent>
                    <TextComponent
                      type="text"
                      value={searchCondition.DM_START}
                      onChange={handleOnChange('DM_START')}
                    />
                    &nbsp;～&nbsp;
                    <TextComponent
                      type="text"
                      value={searchCondition.DM_END}
                      onChange={handleOnChange('DM_END')}
                    />
                  </div>
                </Col>
                <Col md="8" className="text-right"></Col>
              </Row>
              <Row>
                <Col className="p-1 table-scroll" style={{ height: '518px' }}>
                  <FlexGrid
                    selectionMode={'Row'}
                    headersVisibility={'Column'}
                    imeEnabled={true}
                    itemsSource={clients}
                    ref={flexGridRef}
                    allowSorting={false}
                    autoGenerateColumns={false}
                    autoRowHeights={true}
                    onDoubleClick={(e) => {
                      const grid = flexGridRef.current.control;
                      const ht = grid.hitTest(e);
                      if (ht.cellType === CellType.Cell) {
                        const item = grid.rows[ht.row].dataItem;
                        handleCellDoubleClick(item);
                      }
                    }}
                    copying={(s, e) => {
                      // セル単位でコピーする
                      const selection = s.selection;
                      const selectedCellContent = s.getCellData(selection.row, selection.col, true);
                      // クリップボードにコピーされる内容を上書き
                      e.cancel = true;
                      navigator.clipboard.writeText(selectedCellContent);
                    }}
                  >
                    <FlexGridColumn binding="SELECT" header="選" width={52} />
                    <FlexGridColumn
                      binding="NO"
                      header="DMナンバー"
                      width={120}
                      isReadOnly={true}
                    />
                    <FlexGridColumn
                      binding="LANK"
                      header="ランク"
                      ataMap={clientRankMap}
                      width={68}
                      isReadOnly={true}
                    />
                    <FlexGridColumn binding="NAME_1" header="氏名" width={140} isReadOnly={true} />
                    <FlexGridColumn binding="KEISHO" header="敬称" width={60} isReadOnly={true} />
                    <FlexGridColumn
                      binding="BUILD_POST_CODE"
                      header="〒"
                      width={120}
                      isReadOnly={true}
                    />
                    <FlexGridColumn
                      binding="BUILD_CITY"
                      header="住所"
                      width={300}
                      isReadOnly={true}
                    />
                    <FlexGridColumn
                      binding="PRINT_MEMO"
                      header="メモ"
                      width={'*'}
                      minWidth={280}
                      isReadOnly={true}
                    />
                  </FlexGrid>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button className="footer-button" variant="secondary" onClick={handleOnClose}>
            閉じる
          </Button>
          <Button
            className="btn-fill footer-button"
            variant="primary"
            onClick={handleOnDownload}
            disabled={clients.length == 0}
          >
            CSVﾀﾞｳﾝﾛｰﾄﾞ
          </Button>
        </DialogActions>
      </Dialog>

      <PrintInfoEditModal params={printInfoEditModalParam}></PrintInfoEditModal>
    </>
  );
}

export default AtenaPrintModal;
