import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { MessageModal } from 'components/modal/MessageModal';
import { useRestApi } from 'context/RestApiContext';
import { useDropzone } from 'react-dropzone';
import { toDate } from 'utils/dateUtils';

// 工事台帳の取り込み用のモーダルダイアログ
function KoujiDaichoImportModal(props) {
  const restApi = useRestApi();

  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // ドロップゾーンの設定
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // ファイルのアップロード処理
  const handleFileUpload = () => {
    restApi.postUpload(`/api/order/import/${props.params.clientCd}`, file, (data) => {
      if (data.ERROR) {
        setDialog({ type: 'error', message: data.ERROR });
        return;
      }

      setFile(null);
      props.params.fixAction(data.map((x) => ({ ...x, CYU_DATE: toDate(x.CYU_DATE) })));
      setShow(false);
    });
  };

  // ダイアログを閉じる
  const handleClose = () => setShow(false);

  // ファイルの削除処理
  const handleFileRemove = () => {
    setFile(null);
  };

  useEffect(() => {
    if (props?.params?.show) {
      setShow(true);
    }
  }, [props.params]);

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
            '& .MuiPaper-root': {
              width: '480px',
              backgroundColor: '#f1f2f4',
              marginTop: '160px',
            },
          },
        }}
        open={show}
      >
        <DialogTitle>
          工事台帳インポート
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!file ? (
            <div
              {...getRootProps()}
              style={{
                border: '2px dashed #ccc',
                borderRadius: '16px',
                padding: '20px',
                textAlign: 'center',
                height: '280px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>ここにファイルをドロップしてください...</p>
              ) : (
                <p>
                  ファイルをドラッグ & ドロップするか、ここをクリックしてファイルを選択してください
                </p>
              )}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '20px',
                height: '280px',
                border: '2px solid #ccc',
                borderRadius: '16px',
              }}
            >
              <DescriptionOutlinedIcon fontSize="large" style={{ marginRight: '10px' }} />
              <div style={{ flexGrow: 1 }}>
                <p style={{ margin: 0 }}>{file.name}</p>
                <p style={{ margin: 0, fontSize: '12px', color: '#555' }}>
                  {(file.size / 1024).toFixed(2)} KB
                </p>
              </div>
              <IconButton aria-label="delete" onClick={handleFileRemove}>
                <DeleteIcon />
              </IconButton>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            className="btn-fill"
            variant="primary"
            size="sm"
            onClick={handleFileUpload}
            disabled={!file}
          >
            インポート
          </Button>
        </DialogActions>
      </Dialog>
      {/* メッセージダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}
export default KoujiDaichoImportModal;
