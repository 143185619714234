import React, { useContext, useState, useEffect, createContext } from 'react';

import { useAuth } from 'context/AuthContext';
import { useRestApi } from 'context/RestApiContext';

const MasterContext = createContext();

/**
 * マスタ情報プロバイダ
 * @param {*} 子要素
 * @returns マスタ情報プロバイダ
 */
export function MasterContextProvider({ children }) {
  const restApi = useRestApi();
  // 社員
  const [staff, setStaff] = useState([]);
  // プランナー
  const [planer, setPlaner] = useState([]);
  // ハウスメーカ
  const [houseMaker, setHouseMaker] = useState([]);
  // 集客要因区分
  const [channelKbn, setChannelKbn] = useState([]);
  // 集客要因
  const [channelCd, setChannelCd] = useState([]);
  // ラベル
  const [label, setLabel] = useState([]);
  // 取引先
  const [tori, setTori] = useState([]);

  useEffect(() => {
    restApi.get('/api/master/list', (data) => {
      // 社員
      setStaff(data['staff']);
      // プランナー（全社員を選択可能に変更）
      setPlaner(data['staff']);
      // ハウスメーカ
      setHouseMaker(data['company']);
      // // 集客チャネル
      setChannelCd(data['channel']);
      // ラベル
      setLabel(data['label']);
      // 取引先
      setTori(data['tori']);
    });
  }, []);

  // マスタの再読み込み処理
  const reloadMaster = () => {
    restApi.get('/api/master/list', (data) => {
      // 社員
      setStaff(data['staff']);
      // プランナー（全社員を選択可能に変更）
      setPlaner(data['staff']);
      // ハウスメーカ
      setHouseMaker(data['company']);
      // // 集客チャネル
      setChannelCd(data['channel']);
      // ラベル
      setLabel(data['label']);
    });
  };

  return (
    <MasterContext.Provider
      value={{
        staff,
        planer,
        houseMaker,
        channelKbn,
        channelCd,
        label,
        tori,
        reloadMaster,
      }}
    >
      {children}
    </MasterContext.Provider>
  );
}

/**
 * マスタ情報を取得
 * @returns マスタ情報
 */
export function useMaster() {
  const context = useContext(MasterContext);
  if (context == undefined) {
    throw new Error('マスタ情報の取得に失敗しました。');
  }
  return context;
}
