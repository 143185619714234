// 日付に変換
export function toDate(d) {
  if (!d) {
    return null;
  }

  const date = new Date(d);

  // 日付が無効な場合はnullを返す
  if (isNaN(date.getTime())) {
    return null;
  }

  return date;
}

// 年度の開始年月を取得
export function getNendoStartMonth(td) {
  td = td ?? new Date();
  // 1月、2月の場合は年度は前年の３月から
  if (td.getMonth() < 2) {
    return `${td.getFullYear() - 1}/03`;
  }
  return `${td.getFullYear()}/03`;
}

// 年度の終了年月を取得
export function getNendoEndMonth(td) {
  td = td ?? new Date();
  // 1月、2月の場合は年度は今年の２月まで
  if (td.getMonth() < 2) {
    return `${td.getFullYear()}/02`;
  }
  return `${td.getFullYear() + 1}/02`;
}

// 対象年月が過去の年月かどうか判定
export function isPastYearMonth(dateString) {
  // 文字列を日付オブジェクトにパース
  const dateObj = new Date(dateString);

  // 現在の年月を取得
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  // 年月を比較して過去の年月かどうかを判定
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth();
  const isPast = year < currentYear || (year === currentYear && month < currentMonth);

  return isPast;
}

// 期限の編集処理
// 今年なら月日をそれ以外はyyyy/MM/dd形式に
export function formatExpiryDate(dateStr) {
  const date = new Date(dateStr);
  const currentYear = new Date().getFullYear();
  const expiryYear = date.getFullYear();
  if (currentYear === expiryYear) {
    return `${date.getMonth() + 1}月${date.getDate()}日`;
  } else {
    return `${expiryYear}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date
      .getDate()
      .toString()
      .padStart(2, '0')}`;
  }
}

// 過去かどうかを判定
export function isPastDate(dateStr) {
  const date = new Date(dateStr);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date.getTime() < today.getTime();
}

// 今日かどうかを判定
export function isToday(dateStr) {
  const date = new Date(dateStr);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return date.getTime() == today.getTime();
}

// 有効な日付かどうかを判定
export function isValidMMDD(dateString) {
  // 正規表現でM/dまたはMM/dd形式をチェック
  const regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[1-2][0-9]|3[0-1])$/;
  if (!regex.test(dateString)) {
    return false;
  }

  // 月と日を分割
  const [month, day] = dateString.split('/').map(Number);

  // 日付の妥当性を確認
  const currentYear = new Date().getFullYear();
  const daysInMonth = new Date(currentYear, month, 0).getDate();
  return day <= daysInMonth;
}
