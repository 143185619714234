import { useState, useEffect, useRef, useCallback } from 'react';

import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';

import { CellType, CellRange, KeyAction, DataMap, ClipStringOptions } from '@grapecity/wijmo.grid';
import { saveFile, Point, CollectionView, Clipboard } from '@grapecity/wijmo';

import { format } from 'date-fns';
import { InputDate, ComboBox, AutoComplete } from '@grapecity/wijmo.input';

import { Button, Card, Row, Col, Form } from 'react-bootstrap';

import { useRestApi } from 'context/RestApiContext';
import { ToastMessage } from 'components/modal/ToastMessage';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { toFloat, toInt } from 'utils/numberUtils';
import TextComponent from 'components/Input/TextComponent';
import KoujiDaichoImportModal from 'views/KoujiDaicho/KoujiDaichoImportModal';
import KoujiItemPdfUploadModal from 'views/KoujiDaicho/KoujiItemPdfUploadModal';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';

import { toDate, isValidMMDD } from 'utils/dateUtils';

import './KoujiDaicho.css';

function KoujiDaichoModal(props) {
  // REST
  const restApi = useRestApi();

  // トースト表示
  const [toast, setToast] = useState({});

  // 商品マスタ一覧
  const products = useRef([]);
  // 商品マスタ一覧の選択肢
  const productsSelect = useRef([]);

  // 顧客情報
  const [client, setClient] = useState({});

  // 工事台帳明細
  const [koujiDetails, setKoujiDetails] = useState(new CollectionView([]));

  const flexGridRef = useRef(null);
  // スクロール位置
  const scrollPosition = useRef(0);

  // 合計
  const [total, setTotal] = useState([]);

  // モーダルの表示
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (props.params.show) {
      restApi.get(`/api/order/${props.params.clientCd}`, (data) => {
        products.current = data['products'];
        productsSelect.current = data['products'].map(
          (x) => `${x.BASE_NO.toString().padStart(3, '0')}: ${x.CONTENT}`
        );

        const collectionView = new CollectionView(
          data.details.map((x) => ({ ...x, CYU_DATE: toDate(x.CYU_DATE) }))
        );
        setKoujiDetails(collectionView);
        setClient(data.client);
        calcTotal(data.details, data.client.SEIKSAN_KIN);
        setShow(true);
      });
    }
  }, [props.params]);

  // モーダルが開かれた際に FlexGrid を初期化する処理
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const flexGrid = flexGridRef.current.control;
        if (flexGrid) {
          flexGrid.collectionView.refresh(); // データをリフレッシュ
          flexGrid.invalidate(); // 再描画を強制
        }
      }, 100);
    }
  }, [show]);

  // 商品マスタの確認PDF表示
  const handleOnClickShohinMaster = () => {
    restApi.getToPdf(`/api/order/master_pdf`);
  };
  // 工事台帳取り込みモーダルパラメタ
  const [koujiImportModalParam, setKoujiImportModalParam] = useState({});
  // 工事台帳インポートをクリック
  const handleClickKoujiImport = () => {
    setKoujiImportModalParam({
      show: true,
      clientCd: client.CLIENT_CD,
      fixAction: (details) => {
        const collectionView = new CollectionView(
          details.map((x) => ({ ...x, CYU_DATE: toDate(x.CYU_DATE) }))
        );
        setKoujiDetails(collectionView);
        calcTotal(details, client.SEIKSAN_KIN);
      },
    });
  };

  // 商品マスタ表のアップロードモーダルパラメタ
  const [koujiItemPdfUploadModalParam, setKoujiItemPdfUploadModalParam] = useState({});
  // 工事台帳インポートをクリック
  const handleClickItemPdfUpload = () => {
    setKoujiItemPdfUploadModalParam({
      show: true,
    });
  };

  // CSVエクスポート
  const handleCsvDownload = () => {
    const grid = flexGridRef.current.control;
    let rng = new CellRange(0, 0, grid.rows.length - 1, grid.columns.length - 1);
    let csv = grid.getClipString(rng, true, true);
    saveFile(csv, '工事台帳.csv');
  };

  // 編集処理
  const handleOnChange = (prop) => (target) => {
    setClient({ ...client, [prop]: target?.value ?? target?.target.value });
  };

  // 編集処理(チェックボックス)
  const handleOnChangeCheck = (prop) => (event) => {
    setClient({ ...client, [prop]: event.target?.checked ? 1 : 0 });
  };

  // 行の追加ボタン押下
  const handleAddRow = () => {
    const newItem = {
      CYU_DATE: new Date(),
      CLIENT_CD: props.params.clientCd,
      CONTENT: '',
      SIYOU: '',
      TANI: '',
      KINGAKU: '',
      BIKO: '',
      BASE_ID: -1,
      BASE_NO: -1,
    };
    koujiDetails.addNew(newItem);
    koujiDetails.commitNew();

    if (flexGridRef?.current?.control) {
      const grid = flexGridRef?.current?.control;
      if (grid) {
        setTimeout(() => {
          const index = grid.rows.length - 1;
          grid.select(new CellRange(index), true);
          setTimeout(() => {
            grid.scrollIntoView(index, 0);
          }, 50);
        }, 200);
      }
    }
  };

  // 削除ボタン押下
  const handleOnClickDelete = (item) => {
    // スクロール位置を保持
    scrollPosition.current = flexGridRef.current.control.scrollPosition;
    koujiDetails.remove(item);
    koujiDetails.refresh();

    // 合計を再計算
    calcTotal(koujiDetails.items, client.SEIKSAN_KIN);

    setTimeout(() => {
      if (flexGridRef.current?.control) {
        flexGridRef.current.control.scrollPosition = scrollPosition.current;
      }
    }, 200);
  };

  // 保存ボタン押下
  const handleOnClickSave = () => {
    const grid = flexGridRef.current.control;
    const currentScrollPosition = grid.scrollPosition;

    const details = koujiDetails.items.map((x) => ({
      ...x,
      CYU_DATE: x.CYU_DATE ? format(x.CYU_DATE, 'yyyy/MM/dd') : null,
      SU: x.SU ?? '',
      TANKA: x.TANKA ?? '',
    }));

    restApi.post(
      `/api/order/${props.params.clientCd}`,
      {
        details: details,
        total: total[0],
        client: client,
      },
      (data) => {
        setToast({ type: 'info', message: '保存しました。' });

        // 日付の昇順で並び替えを行う
        const sortedDetails = [...koujiDetails.items].sort((a, b) => {
          return a.CYU_DATE - b.CYU_DATE;
        });
        const collectionView = new CollectionView(
          sortedDetails.map((x) => ({ ...x, CYU_DATE: toDate(x.CYU_DATE) }))
        );
        setKoujiDetails(collectionView);
        setTimeout(() => {
          grid.scrollPosition = currentScrollPosition;
        }, 100);
      }
    );
  };

  // 閉じるボタン
  const handleClose = useCallback(() => {
    setShow(false);
    props.callback();
  }, [props.params]);

  // 合計の計算処理
  const calcTotal = (details, seikyu) => {
    // 小計
    let shokei = details.reduce((a, x) => a + toFloat(x['KINGAKU']), 0);
    // 消費税
    let tax = Math.round(shokei * 0.1);
    // 工事原価（税込）
    let genka = shokei + tax;
    // 粗利
    let arari = seikyu - genka;
    // 粗利率
    let arariritu = arari / seikyu;

    // 集計結果を反映
    setTotal([
      {
        shokei: shokei,
        tax: tax,
        genka: genka,
        seikyu: seikyu,
        arari: arari,
        arariritu: !isNaN(arariritu) ? arariritu : 0,
      },
    ]);
  };

  // グリッドの初期化処理
  const handleInitialized = useCallback((flexGrid) => {
    flexGrid.columns.forEach((col) => {
      // 注文日(カレンダ選択)
      if (col.binding === 'CYU_DATE') {
        const inputDate = new InputDate(document.createElement('div'), {
          format: 'yyyy/MM/dd',
          isRequired: true,
        });

        // 無効な入力の場合でかつ
        // MM/dd形式の場合に今年を付与した日付に変換
        inputDate.invalidInput.addHandler(() => {
          const value = inputDate.text.trim();
          if (isValidMMDD(value)) {
            const [month, day] = value.split('/').map(Number);
            const currentYear = new Date().getFullYear();
            const date = new Date(currentYear, month - 1, day);
            inputDate.value = date; // 今年の日付として補完
          }
        });

        inputDate.hostElement.classList.add('grid-selector');
        col.editor = inputDate;
      }
      // 商品
      if (col.binding === 'CONTENT') {
        const comboBox = new AutoComplete(document.createElement('div'), {
          itemsSource: productsSelect.current, // 選択肢のリスト
        });
        comboBox.hostElement.classList.add('grid-selector');
        col.editor = comboBox;
      }
    });
  }, []);

  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var col = grid.columns[e.col];
      var dataItem = grid.rows[e.row].dataItem;

      // 注文日
      if (['CYU_DATE'].includes(col.binding)) {
        e.cell.style.background = getBackGroundColor(dataItem.DENPYO);
      } else if (['DENPYO'].includes(col.binding)) {
        // 伝票（背景と文字色を同じにして見えにくく）
        e.cell.style.color = getBackGroundColor(dataItem.DENPYO);
        e.cell.style.background = getBackGroundColor(dataItem.DENPYO);
      } else if (['PALLET'].includes(col.binding)) {
        // ﾊﾟﾚｯﾄ（背景と文字色を同じにして見えにくく）
        e.cell.style.color = getBackGroundColor(dataItem.PALLET);
        e.cell.style.background = getBackGroundColor(dataItem.PALLET);
      }

      // 商品より右は金額がマイナスの場合は赤色
      if (['CONTENT', 'SIYOU', 'SU', 'TANI', 'TANKA', 'KINGAKU', 'BIKO'].includes(col.binding)) {
        e.cell.style.color = toInt(dataItem.KINGAKU) < 0 ? 'red' : '';
      }
    }
  };

  // 背景色を取得
  const getBackGroundColor = (type) => {
    if (type == 1) {
      // 1: 青
      return '#c6d9f0';
    } else if (type == 2) {
      // 2: 赤
      return '#ffbbb0';
    } else if (type == 3) {
      // 3: 黄
      return '#fdfdc5';
    } else if (type == 4) {
      // 4: 茶
      return '#f7d2a8';
    } else if (type == 5) {
      // 5: 紫：黒文字
      return '#e2c6ff';
    } else {
      return '';
    }
  };

  // グリッドの選択時の処理（ime制御)
  const gridSelectionChanged = (grid, args) => {
    var col = grid.columns[args.col];
    // 数量、単価の場合はimeをoffにする
    var imeEnabled = !['SU', 'TANKA'].some((x) => x == col?.binding);
    setTimeout(function () {
      grid.imeEnabled = imeEnabled;
    }, 50);
  };

  // 貼り付け後の処理
  const pasted = (grid, args) => {
    // let dataItem = grid.rows[args.row].dataItem;
    // let col = grid.columns[args.col];
    // let targetData = args.data;

    // // 日付の場合はDate変換処理をおこなう
    // if (col['binding'] == 'CYU_DATE') {
    //   if (isValidMMDD(args.data)) {
    //     // 有効な年月の場合は、今年とする
    //     targetData = new Date().getFullYear() + '/' + targetData;
    //   }
    //   let val = toDate(targetData);
    //   if (!val) {
    //     args.cancel = true;
    //     return;
    //   }
    //   dataItem['CYU_DATE'] = val;
    // }
    cellEditEnded(grid, args);
  };
  // 編集終了処理
  const cellEditEnded = (grid, args) => {
    var dataItem = grid.rows[args.row].dataItem;
    var col = grid.columns[args.col];
    let upd = false;

    // 単価、数量の場合
    if (col['binding'] == 'TANKA' || col['binding'] == 'SU') {
      let tanka = toFloat(dataItem['TANKA']);
      let su = toFloat(dataItem['SU'], 2);
      let kingaku = toInt(tanka * su);
      dataItem['KINGAKU'] = kingaku;
      upd = true;
    }
    // 商品の場合
    if (col['binding'] == 'CONTENT') {
      const productName = dataItem['CONTENT'];
      const product = productName.split(': ');
      let prd = null;
      if (product.length >= 2) {
        const productCd = toInt(product[0]);
        prd = products.current.find((x) => x.BASE_NO === productCd);
      } else if (productName.length == 3 && /^[0-9]+$/.test(productName)) {
        const productCd = toInt(productName);
        prd = products.current.find((x) => x.BASE_NO === productCd);
      }
      if (prd) {
        dataItem['BASE_ID'] = prd['ID'];
        dataItem['BASE_NO'] = prd['BASE_NO'];
        dataItem['CONTENT'] = `${prd.BASE_NO.toString().padStart(3, '0')}: ${prd.CONTENT}`;
        dataItem['SU'] = prd['SU'];
        dataItem['TANI'] = prd['TANI'];
        dataItem['TANKA'] = toInt(prd['TANKA']);
        dataItem['SIYOU'] = prd['SIYOU'];
        dataItem['BIKO'] = prd['BIKO'];
        let tanka = toFloat(dataItem['TANKA']);
        let su = toFloat(dataItem['SU'], 2);
        let kingaku = toInt(tanka * su);
        dataItem['KINGAKU'] = kingaku;
        dataItem['FORMAT_TYPE'] = prd['FORMAT_TYPE'];
        upd = true;
      } else {
        dataItem['BASE_ID'] = -1;
        dataItem['BASE_NO'] = -1;
        dataItem['FORMAT_TYPE'] = 0;
      }
    }

    // 一覧の再描画をおこなう
    if (upd) {
      grid.beginUpdate();
      grid.setCellData(args.row, 4, dataItem['SIYOU'], true);
      grid.setCellData(args.row, 5, dataItem['SU'], true);
      grid.setCellData(args.row, 6, dataItem['TANI'], true);
      grid.setCellData(args.row, 7, dataItem['TANKA'], true);
      grid.setCellData(args.row, 8, dataItem['KINGAKU'], true);
      grid.endUpdate();
      // 合計を計算
      calcTotal(
        grid.rows.map((x) => x.dataItem),
        client.SEIKSAN_KIN
      );
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        open={show}
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
            '& .MuiPaper-root': {
              maxWidth: 'calc(100vw - 180px)',
              height: 'calc(100vh - 65px)',
              marginTop: '20px',
            },
          },
        }}
        disableEnforceFocus={true} // フォーカス制御を無効にする
      >
        <DialogTitle className="p-3">{`${client.TITLE}　工事台帳`}</DialogTitle>
        <DialogContent className="p-0">
          <Card className="m-0">
            <Card.Body className="p-2 pl-3">
              <Row className="pt-0">
                <Col className="pr-1" md="2">
                  <Form.Group>
                    <label>年月</label>
                    <span style={{ display: 'flex' }}>
                      <TextComponent
                        type="date"
                        selectionMode="Month"
                        value={client.CYU_MONTH ?? ''}
                        onChange={handleOnChange('CYU_MONTH')}
                      ></TextComponent>
                    </span>
                  </Form.Group>
                </Col>
                <Col className="pr-1" md="2">
                  <Form.Group>
                    <label>パレット照合</label>
                    <span style={{ display: 'flex' }}>
                      <label
                        className="mr-3 mt-1"
                        style={{
                          fontSize: '14pt',
                          color: 'black',
                          whiteSpace: 'nowrap',
                          width: 60,
                        }}
                      >
                        <input
                          className="mr-3"
                          style={{ transform: 'scale(1.5)' }}
                          type="checkbox"
                          checked={client.PALLET == 1}
                          onChange={handleOnChangeCheck('PALLET')}
                        ></input>
                        パレット照合
                      </label>
                    </span>
                  </Form.Group>
                </Col>
                <Col className="pr-1" md="2">
                  <label>ダンプ</label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextComponent
                      className="dump_day"
                      type="number"
                      max={999}
                      value={client.DUMP_DAY}
                      onChange={handleOnChange('DUMP_DAY')}
                    ></TextComponent>
                    <span className="ml-1 mr-2">日</span>
                    <TextComponent
                      fmt="c"
                      type="number"
                      max={9999999}
                      value={client.DUMP_KIN}
                      onChange={handleOnChange('DUMP_KIN')}
                    ></TextComponent>
                  </div>
                </Col>
                <Col className="d-flex align-items-end pb-2" md="2">
                  <a href="#" onClick={handleOnClickShohinMaster}>
                    商品マスタ表の確認
                  </a>
                </Col>
                <Col className="d-flex align-items-end pb-2" md="2">
                  <a href="#" onClick={handleClickItemPdfUpload} style={{ color: '#ee2d20' }}>
                    商品マスタ表をアップロード
                  </a>
                </Col>
                <Col md="2" className="text-right">
                  {/* <Button
                    size="sm"
                    style={{ width: '168px' }}
                    variant="primary"
                    className="btn-fill p-1"
                    onClick={handleClickKoujiImport}
                  >
                    <FileDownloadIcon style={{ fontSize: 15 }} />
                    工事台帳をインポート
                  </Button> */}
                </Col>
              </Row>
              <Row>
                <Col md="10">
                  <Button
                    size="sm"
                    style={{ width: '100px', height: '22px', marginLeft: '352px' }}
                    variant="primary"
                    className="btn-fill p-0"
                    onClick={handleAddRow}
                  >
                    <AddIcon style={{ fontSize: 14 }} />
                    明細追加
                  </Button>
                  <Button
                    size="sm"
                    style={{ width: '168px', height: '22px', marginLeft: '48px' }}
                    variant="primary"
                    className="btn-fill p-0"
                    onClick={handleClickKoujiImport}
                  >
                    <FileDownloadIcon style={{ fontSize: 15 }} />
                    工事台帳をインポート
                  </Button>
                </Col>

                <Col md="2" className="text-right">
                  <Button
                    size="sm"
                    style={{ width: '100px', height: '22px' }}
                    variant="success"
                    className="btn-fill p-0"
                    onClick={handleCsvDownload}
                  >
                    <SaveIcon style={{ fontSize: 14 }} />
                    エクスポート
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col
                  id="koujiGrid"
                  className="pt-0 pl-3 table-scroll"
                  style={{ height: 'calc(100vh - 380px)' }}
                >
                  <FlexGrid
                    itemsSource={koujiDetails}
                    ref={flexGridRef}
                    imeEnabled={true}
                    allowSorting={false}
                    autoGenerateColumns={false}
                    keyActionTab={KeyAction.Cycle}
                    initialized={handleInitialized}
                    selectionChanged={gridSelectionChanged}
                    cellEditEnded={cellEditEnded}
                    pasted={pasted}
                    autoRowHeights={true}
                    formatItem={formatItem}
                  >
                    <FlexGridColumn
                      binding="CYU_DATE"
                      header="日付"
                      format="MM/dd"
                      width={142}
                      isRequired={true}
                    />
                    <FlexGridColumn
                      binding="DENPYO"
                      header="伝票"
                      width={60}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="PALLET"
                      header="ﾊﾟﾚｯﾄ"
                      width={60}
                      isRequired={false}
                    ></FlexGridColumn>
                    <FlexGridColumn binding="CONTENT" header="商品" width={332}></FlexGridColumn>
                    <FlexGridColumn binding="SIYOU" header="発注先" width={200} />
                    <FlexGridColumn
                      binding="SU"
                      header="数量"
                      dataType="Number"
                      format="n2"
                      width={100}
                      isRequired={false}
                    />
                    <FlexGridColumn binding="TANI" header="単位" width={50} />
                    <FlexGridColumn
                      binding="TANKA"
                      header="単価"
                      width={100}
                      dataType="Number"
                      isRequired={false}
                    />
                    <FlexGridColumn
                      binding="KINGAKU"
                      header="金額"
                      width={100}
                      dataType="Number"
                      isReadOnly={true}
                      cssClass="cell-readonly"
                    />
                    <FlexGridColumn binding="BIKO" header="備考" width={'*'} />

                    <FlexGridColumn
                      header="　"
                      binding=""
                      isReadOnly={true}
                      width={48}
                      cssClass="cell-icon-button"
                    >
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <Button
                            className={`material-symbols-rounded btn-fill cell-icon-sm2`}
                            variant="danger"
                            onClick={() => {
                              handleOnClickDelete(cell.item);
                            }}
                          >
                            Clear
                          </Button>
                        )}
                      />
                    </FlexGridColumn>
                  </FlexGrid>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button className="footer-button" variant="secondary" onClick={handleClose}>
            閉じる
          </Button>
          <div id="koujiTotalGrid" style={{ width: '760px' }}>
            <FlexGrid
              itemsSource={total}
              selectionMode={'None'}
              headersVisibility={'Column'}
              isReadOnly={true}
              allowSorting={false}
            >
              <FlexGridColumn
                binding="shokei"
                header="小計"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn binding="tax" header="消費税" format="c0" width={90}></FlexGridColumn>
              <FlexGridColumn
                binding="genka"
                header="工事原価（税込）"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="seikyu"
                header="精算金額"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="arari"
                header="粗利益額"
                format="c0"
                width={'1*'}
              ></FlexGridColumn>
              <FlexGridColumn
                binding="arariritu"
                header="利益率"
                format="p1"
                width={80}
              ></FlexGridColumn>
            </FlexGrid>
          </div>
          <Button className="btn-fill footer-button" variant="primary" onClick={handleOnClickSave}>
            保存
          </Button>
        </DialogActions>
      </Dialog>
      {/* 工事台帳取り込みモーダル */}
      <KoujiDaichoImportModal params={koujiImportModalParam} />
      {/* 商品マスタ表のアップロードモーダル */}
      <KoujiItemPdfUploadModal params={koujiItemPdfUploadModalParam} />
      {/* トーストメッセージ */}
      <ToastMessage toast={toast}></ToastMessage>
    </>
  );
}

export default KoujiDaichoModal;
