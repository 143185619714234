import { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { Tooltip } from '@mui/material';
import { DataMap } from '@grapecity/wijmo.grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import BuildIcon from '@mui/icons-material/Build';
import EventNoteIcon from '@mui/icons-material/EventNote';

import { useRestApi } from 'context/RestApiContext';
import { useMaster } from 'context/MasterContext';
import { useAuth } from 'context/AuthContext';
import { MessageModal } from 'components/modal/MessageModal';
import './ClientCard.css';
import ExpiryModal from 'views/ClientCards/ExpiryModal';
import QuoteImportModal from 'views/ClientCards/QuoteImportModal';
import QuoteList from 'views/ClientCards/QuoteList';
import ContractList from 'views/ClientCards/ContractList';
import ClientCardInquiryModal from 'views/ClientCards/ClientCardInquiryModal';
import ClientCardInquiryDisplay from 'views/ClientCards/ClientCardInquiryDisplay';
import CardThumbnailModal from 'views/ClientCards/CardThumbnailModal';
import ContractModal from 'views/ClientCards/ContractModal';
import KoujiDaichoModal from 'views/KoujiDaicho/KoujiDaichoModal';
import YearManageModal from 'views/ClientCards/YearManageModal';
import YearManageHistory from 'views/ClientCards/YearManageHistory';

import { toDate } from 'utils/dateUtils';

// カードの編集画面
function ClientCardEditModal(props) {
  const baseUrl = process.env.REACT_APP_REST_URL ?? '';
  const master = useMaster();
  const restApi = useRestApi();
  const auth = useAuth();
  const currentUserId = auth.auth.user_id;

  // コードマッピング
  var staffMap = new DataMap([...master.staff, { id: 0, name: '' }], 'id', 'name');

  const [show, setShow] = useState(false);
  // カード情報
  const [card, setCard] = useState({});
  // コメント一覧
  const [comments, setComments] = useState([]);
  // 追加コメント
  const [newComment, setNewComment] = useState('');
  // タイトルを編集中か？
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  // 説明を編集中か？
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  // 編集中のタイトル
  const [editedTitle, setEditedTitle] = useState('');
  // 編集中の説明
  const [editedDescription, setEditedDescription] = useState('');
  // 編集中のコメント
  const [editedComment, setEditedComment] = useState('');
  // 編集中のコメントID
  const [editCommentId, setEditCommentId] = useState(null);
  // 確認ダイアログ
  const [dialog, setDialog] = useState({});
  // 顧客情報
  const [client, setClient] = useState({});
  //　見積一覧
  const [quotes, setQuotes] = useState([]);
  //　契約一覧
  const [contracts, setContracts] = useState([]);
  //　入金一覧
  const [nyukins, setNyukins] = useState([]);
  //  年間管理
  const [yearManages, setYearManages] = useState([]);
  // タグ一覧
  const [koujiTags, setKoujiTags] = useState([]);
  // その他情報
  const [otherInfo, setOtherInfo] = useState({});

  // 選択中のタブ
  const [selectedTab, setSelectedTab] = useState(props.selectedTab ?? 'inquiry');

  // 問い合わせ情報モーダルのパラメタ
  const [inquiryModalparam, setInquiryModalparam] = useState({});

  // 問い合わせ情報モーダルの呼び出し処理
  const callInquiryEitModal = () => {
    setInquiryModalparam({
      mode: 'upd',
      inquiry: { ...client, TITLE: card.TITLE },
    });
  };

  // カード情報を検索して取得
  const searchCard = (selectedTab) => {
    restApi.get(`/api/cards/${props?.params?.card.CARD_ID}`, (data) => {
      const card = data.card;
      setCard(card);
      setIsEditingTitle(false);
      setIsEditingDescription(false);
      setEditedTitle(card.TITLE);
      setEditedDescription(card.DESCRIPTION || '');
      setComments(data.comments);
      setQuotes(data.quotes);
      setContracts(data.contracts);
      setClient({ ...data.client });
      setKoujiTags(data.tags);
      setNyukins(data.nyukins.map((x) => ({ ...x, NYUKIN_DATE: toDate(x.NYUKIN_DATE) })));
      setYearManages(data.year_manages.map((x) => ({ ...x, LAST_UPDATE: toDate(x.LAST_UPDATE) })));
      setSelectedTab(selectedTab ? selectedTab : card.CONTRACT_COUNT == 0 ? 'inquiry' : 'contract');
      setOtherInfo(data.other_info);
      setShow(true);
    });
  };

  useEffect(() => {
    if (props?.params?.card) {
      searchCard(props.selectedTab);
      setEditCommentId('');
      setNewComment('');
    }
  }, [props.params]);

  // 追加コメントの保存ボタン押下
  const handleAddComment = () => {
    if (newComment.trim()) {
      restApi.post(`/api/cards/${card.CARD_ID}/comments`, { CONTENT: newComment }, (data) => {
        setComments([data, ...comments]);
        setNewComment('');
      });
    }
  };

  // 追加コメントの変更イベント
  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  // 閉じるボタンのクリック処理
  const handleClose = () => {
    props.loadCards();
    setShow(false);
  };

  // タイトルのクリック処理
  const handleTitleClick = () => {
    setIsEditingTitle(true);
  };

  // 説明のクリック処理
  const handleDescriptionClick = () => {
    setIsEditingDescription(true);
  };

  // タイトルの変更イベント
  const handleTitleChange = (e) => {
    setEditedTitle(e.target.value);
  };

  // 説明の変更イベント
  const handleDescriptionChange = (e) => {
    setEditedDescription(e.target.value);
  };

  // タイトルの確定処理
  const handleTitleBlur = () => {
    setIsEditingTitle(false);
    restApi.post(`/api/cards/${card.CARD_ID}`, { TITLE: editedTitle }, (_) => {
      setCard({ ...card, TITLE: editedTitle });
    });
  };

  // 説明の保存ボタン押下
  const handleSaveDescription = () => {
    restApi.post(`/api/cards/${card.CARD_ID}`, { DESCRIPTION: editedDescription }, (_) => {
      setCard({ ...card, DESCRIPTION: editedDescription });
      setIsEditingDescription(false);
    });
  };

  // 説明の編集キャンセルボタン押下
  const handleCancelDescription = () => {
    setIsEditingDescription(false);
    setEditedDescription(card.DESCRIPTION);
  };

  // タイトルのキーダウンイベント
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (isEditingTitle) {
        handleTitleBlur();
      }
    }
  };

  // コメントの編集リンクの選択
  const handleEditComment = (comment) => {
    setEditCommentId(comment.CARD_COMMENT_ID);
    setEditedComment(comment.CONTENT);
  };

  // コメントの内容変更
  const handleEditCommentChange = (e) => {
    setEditedComment(e.target.value);
  };

  // コメントの保存ボタン押下
  const handleSaveEditedComment = (commentId) => {
    restApi.post(
      `/api/cards/${card.CARD_ID}/comments/${commentId}`,
      { CONTENT: editedComment },
      (_) => {
        const updatedComments = comments.map((comment) =>
          comment.CARD_COMMENT_ID === commentId ? { ...comment, CONTENT: editedComment } : comment
        );
        setComments(updatedComments);
        setEditCommentId(null);
        setEditedComment('');
      }
    );
  };

  // コメントの削除リンク押下
  const handleDeleteComment = (commentId) => {
    setDialog({
      type: 'confirm',
      message: `削除します。よろしいですか？`,
      okCallBack: () => {
        restApi.doDelete(`/api/cards/${card.CARD_ID}/comments/${commentId}`, (_) => {
          setComments(comments.filter((x) => x.CARD_COMMENT_ID !== commentId));
        });
      },
    });
  };

  // グッドアイコンのクリック処理
  const handleGoodIcon = (commentId) => {
    restApi.post(`/api/cards/${card.CARD_ID}/comments/${commentId}/good`, {}, (data) => {
      let newComments = comments.map((x) =>
        x.CARD_COMMENT_ID !== commentId ? x : { ...x, GOOD_USERS: data.GOOD_USERS }
      );
      setComments(newComments);
    });
  };

  // コメントのキャンセルボタン押下
  const handleCancelEditComment = () => {
    setEditCommentId(null);
  };

  // 期限モーダルパラメタ
  const [expiryModalParam, setExpiryModalParam] = useState({});

  // 期限を選択
  const hendleClickExpiry = () =>
    setExpiryModalParam({
      show: true,
      expiry: card.EXPIRY,
      expiryTime: card.EXPIRY_TIME,
      cardId: card.CARD_ID,
      saveAction: (exp) => {
        searchCard(selectedTab);
      },
    });

  // 見積取り込みモーダルパラメタ
  const [quoteImportModalParam, setImportModalParam] = useState({});
  // 見積を追加を選択
  const hendleClickQuoteImport = () => {
    setImportModalParam({
      show: true,
      clientCd: card.CLIENT_CD,
      mode: quotes.length > 0 ? 'upd' : 'add', // データありは上書き更新
      saveAction: (quote) => {
        searchCard(selectedTab);
      },
    });
  };

  // 契約モーダルパラメタ
  const [contractModalParam, setContractModalParam] = useState({});
  // 契約登録を選択
  const hendleClickContract = () => {
    setContractModalParam({
      show: true,
      clientCd: card.CLIENT_CD,
      mode: 'add',
    });
  };

  // 契約編集を選択
  const hendleClickContractEdit = () => {
    setContractModalParam({
      show: true,
      clientCd: card.CLIENT_CD,
      keiNo: contracts[contracts.length - 1].KEI_NO,
      mode: 'upd',
    });
  };

  // 工事台帳モーダルパラメタ
  const [koujiDaichoModalParam, setKoujiDaichoModalParam] = useState({});
  // 工事台帳を選択
  const handleClickKoujiDaicho = () => {
    setKoujiDaichoModalParam({
      show: true,
      clientCd: card.CLIENT_CD,
    });
  };

  const [yearManageModalParam, setYearManageModalParam] = useState({});
  // 年間管理を選択
  const handleClickYearManege = () => {
    if (!client.DM_NO) {
      setDialog({
        type: 'error',
        message: `DMナンバーが未登録のため、年間管理の登録はおこなえません`,
      });
      return;
    }
    setYearManageModalParam({
      show: true,
      client: client,
      yearManage: yearManages[0],
      mode: yearManages.length ? 'upd' : 'add',
    });
  };

  const [cardThumbnailModalParam, setCardThumbnailModalParam] = useState({});
  // 画像を編集を選択
  const handleClickThumbnail = () => {
    setCardThumbnailModalParam({
      show: true,
      client: client,
    });
  };

  // カードを案件全般から非表示
  const hendleClickArchive = () => {
    setDialog({
      type: 'confirm',
      message: `カードを案件全般から非表示にします。よろしいですか？`,
      okCallBack: () => {
        restApi.post(`/api/cards/archive/${card.CARD_ID}`, { ARCHIVE_FLG: '1' }, (_) => {
          props.loadCards();
          setShow(false);
        });
      },
    });
  };

  // 削除を選択
  const hendleClickDelete = () => {
    setDialog({
      type: 'confirm',
      message: `カードを削除します。よろしいですか？（※元に戻すことはできません）`,
      okCallBack: () => {
        restApi.post(`/api/cards/archive/${card.CARD_ID}`, { DEL_FLG: '1' }, (_) => {
          props.loadCards();
          setShow(false);
        });
      },
    });
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '1280px',
              backgroundColor: '#f1f2f4',
              marginTop: '20px',
            },
          },
        }}
        open={show}
        onClose={(e, reason) => {
          if (reason === 'backdropClick') {
            handleClose();
          }
        }}
      >
        <DialogTitle className="p-3">
          {isEditingTitle ? (
            <Form.Control
              type="text"
              value={editedTitle}
              onChange={handleTitleChange}
              onBlur={handleTitleBlur}
              onKeyDown={handleKeyDown}
              autoFocus
              style={{ width: '600px' }}
            />
          ) : (
            <span className="editable-title" onClick={handleTitleClick}>
              {card.TITLE}
            </span>
          )}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="px-3 py-3">
          <div style={{ display: 'flex' }}>
            <div style={{ width: '80%', flex: 1 }}>
              {card.EXPIRY && (
                <>
                  <div className="description-label">
                    <i className="material-symbols-rounded">date_range</i>
                    <b>期限</b>
                  </div>
                  <div className="expiry_label mb-2" onClick={hendleClickExpiry}>
                    <span>
                      {card.EXPIRY ? card.EXPIRY : '期限なし'}
                      {card.EXPIRY_TIME && card.EXPIRY_TIME != '00:00' && ` ${card.EXPIRY_TIME}`}
                    </span>
                    <ExpandMoreIcon style={{ marginLeft: 'auto' }} />
                  </div>
                </>
              )}
              {client && (
                // 問い合わせ情報/契約後情報/年間管理タブ/その他情報
                <ClientCardInquiryDisplay
                  client={client}
                  card={card}
                  callInquiryEitModal={callInquiryEitModal}
                  searchCard={searchCard}
                  contracts={contracts}
                  nyukins={nyukins}
                  koujiTags={koujiTags}
                  yearManages={yearManages}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  otherInfo={otherInfo}
                />
              )}
              {/* メンテナンス履歴 */}
              {selectedTab == 'year_manage' && (
                <YearManageHistory
                  yearManages={yearManages}
                  callback={() => {
                    searchCard('year_manage');
                  }}
                />
              )}
              {/* 説明 */}
              <div className="description-label mt-2">
                <i className="material-symbols-rounded">format_align_left</i>
                <b>説明</b>
              </div>
              {isEditingDescription ? (
                <>
                  <Form.Control
                    as="textarea"
                    placeholder="説明を入力してください。"
                    rows={5}
                    value={editedDescription}
                    onChange={handleDescriptionChange}
                    autoFocus
                  />
                  <div className="mt-2">
                    <Button
                      className="btn-fill"
                      variant="primary"
                      size="sm"
                      onClick={handleSaveDescription}
                    >
                      保存
                    </Button>
                    <Button
                      className="btn-fill ml-2"
                      variant="secondary"
                      size="sm"
                      onClick={handleCancelDescription}
                    >
                      キャンセル
                    </Button>
                  </div>
                </>
              ) : (
                <span className="editable-description" onClick={handleDescriptionClick}>
                  {card.DESCRIPTION || '説明を入力してください。'}
                </span>
              )}
              {/* 見積 */}
              <QuoteList
                quotes={quotes}
                setQuotes={setQuotes}
                setImportModalParam={setImportModalParam}
                clientCd={card?.CLIENT_CD}
              />
              {/* 契約 */}
              <ContractList
                contracts={contracts}
                setContracts={setContracts}
                setContractModalParam={setContractModalParam}
                clientCd={card?.CLIENT_CD}
                searchCard={searchCard}
              />
              <div className="description-label mt-2">
                <i className="material-symbols-rounded">format_list_bulleted</i>
                <b>コメント</b>
              </div>
              <Form.Control
                as="textarea"
                rows={2}
                value={newComment}
                onChange={handleCommentChange}
                placeholder="コメントを入力してください"
              />
              <div className="mt-2 mb-2">
                <Button
                  className="btn-fill"
                  variant="primary"
                  size="sm"
                  disabled={!newComment}
                  onClick={handleAddComment}
                >
                  保存
                </Button>
              </div>
              <ul className="comments-list">
                {comments.map((comment, index) => (
                  <span key={index}>
                    <span style={{ fontSize: '11pt' }}>
                      <b>{comment.TAN_NAME}</b>さんがコメント
                    </span>
                    {editCommentId === comment.CARD_COMMENT_ID ? (
                      <>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={editedComment}
                          onChange={handleEditCommentChange}
                          className="mb-2"
                        />
                        <Button
                          className="btn-fill"
                          variant="primary"
                          size="sm"
                          onClick={() => handleSaveEditedComment(comment.CARD_COMMENT_ID)}
                        >
                          保存
                        </Button>
                        <Button
                          className="btn-fill ml-2"
                          variant="secondary"
                          size="sm"
                          onClick={handleCancelEditComment}
                        >
                          キャンセル
                        </Button>
                        <br />
                      </>
                    ) : (
                      <>
                        <span style={{ fontSize: '10pt' }} className="pl-2">
                          &nbsp;{comment.INS_DATE}&nbsp;{comment.INS_TIME}
                        </span>
                        <li key={index} className="comment-item">
                          {comment.CONTENT}
                        </li>
                        <div
                          className="mt-1 ml-1 mb-3"
                          style={{ display: 'flex', alignItems: 'center' }}
                        >
                          <a
                            href="#"
                            style={{
                              fontSize: '10pt',
                              color: '#44546f',
                              textDecoration: 'none',
                              display: 'inline-flex',
                              alignItems: 'center',
                            }}
                            onClick={() => handleGoodIcon(comment.CARD_COMMENT_ID)}
                          >
                            <div
                              className={
                                comment.GOOD_USERS.includes(currentUserId)
                                  ? 'goodicon_mine'
                                  : 'goodicon'
                              }
                            >
                              {comment.GOOD_USERS?.length >= 1 ? (
                                <Tooltip
                                  title={comment.GOOD_USERS?.map((x) =>
                                    staffMap.getDisplayValue(x)
                                  ).join(', ')}
                                >
                                  <ThumbUpAltRoundedIcon
                                    style={{ color: '#fcc625' }}
                                    fontSize="small"
                                  />
                                  <span
                                    style={{
                                      marginLeft: '8px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {comment.GOOD_USERS?.length}
                                  </span>
                                </Tooltip>
                              ) : (
                                <ThumbUpAltOutlinedIcon fontSize="small" />
                              )}
                            </div>
                          </a>
                          <a
                            href="#"
                            style={{
                              fontSize: '10pt',
                              color: '#44546f',
                              textDecoration: 'none',
                              marginLeft: '8px',
                            }}
                            onClick={() => handleEditComment(comment)}
                          >
                            <u>編集</u>
                          </a>
                          ・
                          <a
                            href="#"
                            style={{
                              fontSize: '10pt',
                              color: '#44546f',
                              textDecoration: 'none',
                              marginLeft: '8px',
                            }}
                            onClick={() => handleDeleteComment(comment.CARD_COMMENT_ID)}
                          >
                            <u>削除</u>
                          </a>
                        </div>
                      </>
                    )}
                  </span>
                ))}
              </ul>
            </div>
            <div
              style={{
                width: '20%',
                padding: '0px',
                marginLeft: '10px',
              }}
            >
              <div className="description-label">
                <b>操作</b>
              </div>
              <div className="menu-item" onClick={hendleClickExpiry}>
                <AccessTimeIcon></AccessTimeIcon>
                <span
                  style={{
                    marginLeft: '6px',
                  }}
                >
                  期限を編集
                </span>
              </div>
              <div className="menu-item mt-2" onClick={hendleClickQuoteImport}>
                <DescriptionOutlinedIcon></DescriptionOutlinedIcon>
                <span
                  style={{
                    marginLeft: '6px',
                  }}
                >
                  見積を登録
                </span>
              </div>
              {/* 契約がない場合、契約の登録を表示 */}
              {contracts.length === 0 && (
                <div className="menu-item mt-2" onClick={hendleClickContract}>
                  <HandshakeOutlinedIcon></HandshakeOutlinedIcon>
                  <span
                    style={{
                      marginLeft: '6px',
                    }}
                  >
                    契約を登録
                  </span>
                </div>
              )}
              {/* 契約がある場合、契約の編集を表示 */}
              {contracts.length > 0 && (
                <div className="menu-item mt-2" onClick={hendleClickContractEdit}>
                  <HandshakeOutlinedIcon></HandshakeOutlinedIcon>
                  <span
                    style={{
                      marginLeft: '6px',
                    }}
                  >
                    契約を編集
                  </span>
                </div>
              )}

              {/* 契約がある場合のみ、工事台帳編集／年間管理を表示 */}
              {card.CONTRACT_COUNT > 0 && (
                <>
                  <div className="menu-item mt-2" onClick={handleClickKoujiDaicho}>
                    <BuildIcon></BuildIcon>
                    <span
                      style={{
                        marginLeft: '6px',
                      }}
                    >
                      工事台帳を編集
                    </span>
                  </div>
                  <div className="menu-item mt-2" onClick={handleClickYearManege}>
                    <EventNoteIcon></EventNoteIcon>
                    <span
                      style={{
                        marginLeft: '6px',
                      }}
                    >
                      年間管理を{yearManages.length ? '編集' : '登録'}
                    </span>
                  </div>
                </>
              )}
              <div className="menu-item mt-2" onClick={handleClickThumbnail}>
                <CameraAltIcon></CameraAltIcon>
                <span
                  style={{
                    marginLeft: '6px',
                  }}
                >
                  画像を編集
                </span>
              </div>
              <div className="menu-item mt-2" onClick={hendleClickArchive}>
                <Inventory2OutlinedIcon></Inventory2OutlinedIcon>
                <span
                  style={{
                    marginLeft: '6px',
                  }}
                >
                  カードを非表示
                </span>
              </div>

              {/* 契約がない場合のみ削除可能 */}
              {card.CONTRACT_COUNT == 0 && (
                <div className="menu-item mt-2" onClick={hendleClickDelete}>
                  <DeleteForeverOutlinedIcon style={{ fontSize: 30 }} />
                  <span
                    style={{
                      marginLeft: '6px',
                    }}
                  >
                    削除
                  </span>
                </div>
              )}
              {(client.IMAGE_1 || client.IMAGE_2 || client.IMAGE_3 || client.IMAGE_4) && (
                <>
                  <div className="description-label mt-3 mb-0">
                    <b>画像</b>
                  </div>
                  {[1, 2, 3, 4]
                    .filter((i) => client[`IMAGE_${i}`])
                    .map((i) => (
                      <img
                        className="mt-2"
                        src={`${baseUrl}/api/client/image/${client.CLIENT_CD}/${
                          client[`IMAGE_${i}`]
                        }?${Date.now()}`}
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          borderRadius: '16px',
                          objectFit: 'cover',
                        }}
                      />
                    ))}
                </>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {/* メッセージダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
      {/* 期限入力 */}
      <ExpiryModal params={expiryModalParam}></ExpiryModal>
      {/* 見積取り込みダイアログ */}
      <QuoteImportModal params={quoteImportModalParam}></QuoteImportModal>
      {/** 問い合わせ情報モーダル画面 */}
      <ClientCardInquiryModal params={inquiryModalparam} loadCards={searchCard} />
      {/** 契約モーダル画面 */}
      <ContractModal params={contractModalParam} loadCards={searchCard} />
      {/** 工事台帳モーダル画面 */}
      <KoujiDaichoModal params={koujiDaichoModalParam} callback={searchCard} />
      {/** サムネイル画像モーダル画面 */}
      <CardThumbnailModal params={cardThumbnailModalParam} callback={searchCard} />
      {/** 年間管理モーダル画面 */}
      <YearManageModal
        params={yearManageModalParam}
        callback={() => {
          searchCard('year_manage');
        }}
      />
    </>
  );
}

export default ClientCardEditModal;
