import React, { useState, useEffect } from 'react';
import ForestIcon from '@mui/icons-material/Forest';

import { useRestApi } from 'context/RestApiContext';
import { MessageModal } from 'components/modal/MessageModal';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';

// 年間管理のメンテナンス履歴
function YearManageHistory(props) {
  const restApi = useRestApi();
  const yearManage = props.yearManages[0];

  // 編集中のメンテ履歴
  const [editedDescription, setEditedDescription] = useState('');
  // メンテ履歴を編集中か？
  const [isEditingDescription, setIsEditingDescription] = useState(false);

  // 年間管理に変更があった場合は編集中を解除
  useEffect(() => {
    setIsEditingDescription(false);
    setEditedDescription(props.yearManages[0]?.HISTORY);
  }, props.yearManages);

  // メンテ履歴のクリック処理
  const handleDescriptionClick = () => {
    setIsEditingDescription(true);
  };
  // メンテ履歴の変更イベント
  const handleDescriptionChange = (e) => {
    setEditedDescription(e.target.value);
  };
  // 保存ボタン押下
  const handleSaveDescription = () => {
    restApi.post(
      `/api/year_manage/history/${yearManage.DM_NO}`,
      { HISTORY: editedDescription },
      (_) => {
        props.callback();
      }
    );
  };
  // 編集キャンセルボタン押下
  const handleCancelDescription = () => {
    setIsEditingDescription(false);
    setEditedDescription(yearManage.HISTORY);
  };

  return (
    <>
      <div className="description-label mt-2">
        <ForestIcon />
        <b>メンテ履歴</b>
      </div>
      {isEditingDescription ? (
        <>
          <Form.Control
            as="textarea"
            placeholder="メンテ履歴を入力してください。"
            rows={5}
            value={editedDescription}
            onChange={handleDescriptionChange}
            autoFocus
          />
          <div className="mt-2">
            <Button
              className="btn-fill"
              variant="primary"
              size="sm"
              onClick={handleSaveDescription}
            >
              保存
            </Button>
            <Button
              className="btn-fill ml-2"
              variant="secondary"
              size="sm"
              onClick={handleCancelDescription}
            >
              キャンセル
            </Button>
          </div>
        </>
      ) : (
        <span className="editable-description" onClick={handleDescriptionClick}>
          {yearManage?.HISTORY || 'メンテ履歴を入力してください。'}
        </span>
      )}
    </>
  );
}
export default YearManageHistory;
