import React, { useState, useEffect } from 'react';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextComponent from 'components/Input/TextComponent';
import SelectComponent from 'components/Input/SelectComponent';

import { useRestApi } from 'context/RestApiContext';
import { MessageModal } from 'components/modal/MessageModal';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';

// その他情報の編集用のモーダル
function OtherEditModal(props) {
  const restApi = useRestApi();

  const [show, setShow] = useState(false);
  // メッセージダイアログ
  const [dialog, setDialog] = useState({});
  // エラーフィールド
  const [errorFileds, setErrorFileds] = useState([]);
  // その他情報
  const [otherInfo, setOtherInfo] = useState({});
  const handleClose = () => setShow(false);

  useEffect(() => {
    setErrorFileds([]);

    if (props?.params?.show) {
      setOtherInfo(props.params.otherInfo);
      setShow(true);
    }
  }, [props.params]);

  // 編集処理
  const handleOnChange = (prop) => (target) => {
    setOtherInfo({ ...otherInfo, [prop]: target?.value ?? target?.target.value });
    // エラーをクリア
    setErrorFileds([...errorFileds].filter((x) => x != prop));
  };

  // 保存ボタンをクリック
  const hendleClickSave = () => {
    restApi.post(`/api/client/other/${props.params.client?.CLIENT_CD}`, otherInfo, (_) => {
      props.callback();
      setShow(false);
    });
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '1080px',
              backgroundColor: '#f1f2f4',
              marginTop: '40px',
            },
          },
        }}
        open={show}
        disableEnforceFocus={true} // フォーカス制御を無効にする
      >
        <DialogTitle>
          その他情報
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Card className="m-0 p-2">
            <Row>
              <Col className="pr-1" md="11">
                <Form.Group>
                  <label>工事履歴</label>
                  <TextComponent
                    type="textarea"
                    placeholder="工事履歴を記載ください"
                    rows={3}
                    value={otherInfo.KOUJI_HISTORY}
                    onChange={handleOnChange('KOUJI_HISTORY')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="11">
                <Form.Group>
                  <label>工事関連</label>
                  <TextComponent
                    type="textarea"
                    placeholder="工事関連のメモを記載ください"
                    rows={3}
                    value={otherInfo.KOUJI_MEMO}
                    onChange={handleOnChange('KOUJI_MEMO')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="11">
                <Form.Group>
                  <label>その他</label>
                  <TextComponent
                    type="textarea"
                    placeholder="その他のメモを記載ください"
                    rows={3}
                    value={otherInfo.OTHER_MEMO}
                    onChange={handleOnChange('OTHER_MEMO')}
                  />
                </Form.Group>
              </Col>
            </Row>
            {props.params.client?.DM_NO && (
              <>
                <Row>
                  <Col className="pr-1 mt-2" md="11">
                    <label>印刷関連</label>
                    <Form.Group>
                      <TextComponent
                        type="textarea"
                        placeholder="印刷関連のメモを記載ください"
                        rows={3}
                        value={otherInfo.PRINT_MEMO}
                        onChange={handleOnChange('PRINT_MEMO')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button
            variant="primary"
            className="btn-fill ml-4 mr-2"
            onClick={hendleClickSave}
            style={{ flexGrow: 1 }}
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
      {/* メッセージダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}
export default OtherEditModal;
