import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import GetAppIcon from '@mui/icons-material/GetApp';

import { useRestApi } from 'context/RestApiContext';

import { MessageModal } from 'components/modal/MessageModal';
import './ClientCard.css';

// 見積一覧
const QuoteList = (props) => {
  const restApi = useRestApi();

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // ダウンロードボダン押下
  const handleClickDownload = (item) => {
    restApi.postDownload(
      `api/quotes/${props.clientCd}/downloads/${item.QUOTE_NO}`,
      {},
      item.FILE_NAME,
      () => {}
    );
  };

  // 追加ボタン押下
  const handleClickAdd = () => {
    props.setImportModalParam({
      show: true,
      clientCd: props.clientCd,
      mode: 'add',
      saveAction: (quote) => {
        props.setQuotes((prev) => [...prev, quote]);
      },
    });
  };

  // 削除ボタン押下
  const handleClickDelete = (item) => {
    setDialog({
      type: `confirm`,
      message: `${item.FILE_NAME} を削除します。よろしいですか？`,
      okCallBack: () => {
        restApi.doDelete(`/api/quotes/${props.clientCd}/uploads/${item.QUOTE_NO}`, (data) => {
          props.setQuotes(props.quotes.filter((x) => x.QUOTE_NO != item.QUOTE_NO));
        });
      },
    });
  };

  return props.quotes?.length ? (
    <>
      <Row className="mt-3">
        <Col md="2">
          <div className="description-label">
            <DescriptionOutlinedIcon />
            <b>見積</b>
          </div>
        </Col>
        <Col md="10" className="text-right">
          <Button
            size="sm"
            style={{ width: '108px', height: '22px' }}
            variant="primary"
            className="btn-fill p-0"
            onClick={handleClickAdd}
          >
            <ControlPointIcon style={{ fontSize: 14 }} />
            見積を追加
          </Button>
        </Col>
      </Row>
      <FlexGrid
        allowSorting={false}
        itemsSource={props.quotes}
        selectionMode={'None'}
        headersVisibility={'Column'}
        updatedView={(grid) => {
          grid.rows.forEach((ele) => {
            ele.height = 26;
          });
        }}
      >
        <FlexGridColumn
          binding="QUOTE_NO"
          header="見積番号"
          width={62}
          format="d"
          isReadOnly={true}
        ></FlexGridColumn>
        <FlexGridColumn
          binding="KOUJI_MEI"
          header="工事名"
          width="*"
          isReadOnly={true}
        ></FlexGridColumn>
        <FlexGridColumn
          binding="FILE_NAME"
          header="ファイル名"
          width="*"
          isReadOnly={true}
        ></FlexGridColumn>
        <FlexGridColumn
          binding="MITSU_KIN"
          header="見積金額（税込）"
          width={120}
          isReadOnly={true}
          format="n0"
          dataType="Number"
        ></FlexGridColumn>
        <FlexGridColumn
          binding="CREATE_DATE"
          header="登録日時"
          width={148}
          isReadOnly={true}
        ></FlexGridColumn>
        <FlexGridColumn header="" binding="" isReadOnly={true} width={28}>
          <FlexGridCellTemplate
            cellType="Cell"
            template={(cell) => (
              <Button
                className="btn-fill icon-button-sm"
                variant="success"
                onClick={() => handleClickDownload(cell.item)}
              >
                <GetAppIcon></GetAppIcon>
              </Button>
            )}
          />
        </FlexGridColumn>
        <FlexGridColumn header="" binding="" isReadOnly={true} width={28}>
          <FlexGridCellTemplate
            cellType="Cell"
            template={(cell) => (
              <Button
                className="material-symbols-rounded btn-fill icon-button-sm"
                variant="danger"
                onClick={() => handleClickDelete(cell.item)}
              >
                Delete
              </Button>
            )}
          />
        </FlexGridColumn>
      </FlexGrid>
      {/* メッセージダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
    </>
  ) : (
    <></>
  );
};

export default QuoteList;
