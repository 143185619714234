import { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import MasterEditModal from 'views/MasterManagement/MasterEditModal';

// マスタ編集画面
function MasterManagement() {
  const navigate = useNavigate();

  // マスタ編集モーダルへのパラメタ
  const [editModalParams, setEditModalParams] = useState({});

  // マスタ編集
  const handleOnClickCallModal = (e, tableName) => {
    e.preventDefault();
    setEditModalParams({ tableName: tableName });
  };

  // 工事台帳商品マスタ画面
  const handleOnClickQuoteBase = (e) => {
    e.preventDefault();
    navigate('/admin/ProductsMaster');
  };
  // 取引先マスタ画面
  const handleOnClickTriManage = (e) => {
    e.preventDefault();
    navigate('/admin/trimanage');
  };

  return (
    <>
      <Card className="table-container">
        <Card.Body>
          <Row>
            <Col>
              <div className="bs-callout-info">各種マスタ</div>
            </Col>
          </Row>
          <Row className="mt-2 ml-1">
            <Col md="2">
              <a href="#" onClick={(e) => handleOnClickCallModal(e, 'M_STAFF')}>
                ・職員
              </a>
            </Col>
            {/* <Col md="2">
              <a href="#" onClick={(e) => handleOnClickTriManage(e)}>
                ・取引先
              </a>
            </Col> */}
          </Row>
          <Row className="mt-2 ml-1">
            <Col md="2">
              <a href="#" onClick={(e) => handleOnClickCallModal(e, 'M_LABEL')}>
                ・工事内容タグ
              </a>
            </Col>
          </Row>
          <Row className="mt-2 ml-1">
            <Col md="2">
              <a href="#" onClick={(e) => handleOnClickQuoteBase(e)}>
                ・工事台帳商品マスタ
              </a>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* マスタ編集モーダル */}
      <MasterEditModal params={editModalParams}></MasterEditModal>
    </>
  );
}

export default MasterManagement;
