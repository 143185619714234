// あり・なし
export const Exists = Object.freeze([
  {
    key: 0,
    value: 'なし',
  },
  {
    key: 1,
    value: 'あり',
  },
]);

// 問い合わせ方法
export const ToiawaseCodes = Object.freeze([
  {
    key: 0,
    value: ' ',
  },
  {
    key: 1,
    value: '電話',
  },
  {
    key: 2,
    value: 'HPフォーム',
  },
  {
    key: 3,
    value: 'メール',
  },
  {
    key: 4,
    value: '飛び込み来店',
  },
  {
    key: 5,
    value: '植田',
  },
  {
    key: 6,
    value: 'LINE',
  },
  {
    key: 99,
    value: 'その他',
  },
]);

// 作業区分
export const WorkKbns = Object.freeze([
  {
    key: 0,
    value: ' ',
  },
  {
    key: 1,
    value: '外構＆ガーデン',
  },
  {
    key: 2,
    value: '住宅リフォーム',
  },
]);

// 希望プラン
export const WishPlans = Object.freeze([
  {
    key: 0,
    value: ' ',
  },
  {
    key: 1,
    value: '新築外構(建築前)',
  },
  {
    key: 2,
    value: '新築外構(建築中)',
  },
  {
    key: 3,
    value: '新築外構(入居前)',
  },
  {
    key: 4,
    value: '新築外構(入居済)',
  },
  {
    key: 5,
    value: '外構リフォーム',
  },
  {
    key: 6,
    value: 'ガーデンリフォーム',
  },
  {
    key: 7,
    value: '手つかずスペース',
  },
  {
    key: 8,
    value: '追加工事',
  },
  {
    key: 9,
    value: '住宅（水まわり）',
  },
  {
    key: 10,
    value: '住宅（内装）',
  },
  {
    key: 11,
    value: '住宅（外壁＆屋根塗装）',
  },
  {
    key: 12,
    value: '住宅（その他）',
  },
  {
    key: 14,
    value: 'メンテ',
  },
  {
    key: 15,
    value: '補修',
  },
  {
    key: 16,
    value: '店舗',
  },
  {
    key: 13,
    value: 'その他',
  },
]);

// 予算
export const Budgets = Object.freeze([
  {
    key: 0,
    value: ' ',
  },
  {
    key: 1,
    value: '50 ～ 100万',
  },
  {
    key: 2,
    value: '100 ～ 150万',
  },
  {
    key: 3,
    value: '150 ～ 200万',
  },
  {
    key: 4,
    value: '200 ～ 250万',
  },
  {
    key: 5,
    value: '250 ～ 300万',
  },
  {
    key: 6,
    value: '300 ～ 350万',
  },
  {
    key: 7,
    value: '350 ～ 500万',
  },
  {
    key: 8,
    value: '500万以上',
  },
  {
    key: 9,
    value: '未定',
  },
]);

// ガーデンリフォームローン会社
export const LoanCompanies = Object.freeze([
  {
    key: 0,
    value: ' ',
  },
  {
    key: 1,
    value: 'オリコ',
  },
  {
    key: 2,
    value: 'LIXIL',
  },
  {
    key: 3,
    value: 'タカショー',
  },
  {
    key: 99,
    value: 'その他',
  },
]);

// 期間
export const Periods = Object.freeze([
  {
    key: 0,
    value: ' ',
  },
  {
    key: 1,
    value: '上旬',
  },
  {
    key: 2,
    value: '中旬',
  },
  {
    key: 3,
    value: '下旬',
  },
]);

// お客様ランク
export const ClientRanks = Object.freeze([
  {
    key: '',
    value: ' ',
  },
  {
    key: 'S',
    value: 'Ｓ',
  },
  {
    key: 'A',
    value: 'Ａ',
  },
  {
    key: 'B',
    value: 'Ｂ',
  },
  {
    key: 'C',
    value: 'Ｃ',
  },
]);

// LINE登録
export const Lines = Object.freeze([
  {
    key: 0,
    value: ' ',
  },
  {
    key: 1,
    value: 'あり',
  },
  {
    key: 2,
    value: 'なし',
  },
]);

// 年間管理内容
export const YearManageWorks = Object.freeze([
  {
    key: 0,
    value: ' ',
  },
  {
    key: 1,
    value: '水量調整',
  },
  {
    key: 2,
    value: '剪定＆消毒',
  },
  {
    key: 3,
    value: '低木管理',
  },
  {
    key: 4,
    value: '剪定＆消毒・水量調整',
  },
  {
    key: 6,
    value: '剪定＆消毒・低木管理',
  },
  {
    key: 5,
    value: '剪定＆消毒・水量調整・低木管理',
  },
]);

// 打合せ状況
export const MeetingStatus = Object.freeze([
  {
    key: 0,
    value: ' ',
  },
  {
    key: 1,
    value: '他のお店にも行く予定',
  },
  {
    key: 2,
    value: '他のお店に行っている',
  },
  {
    key: 3,
    value: '他のお店に行っていない',
  },
  {
    key: 4,
    value: '他のお店に行く予定はない',
  },
  {
    key: 5,
    value: 'Assemble Gardenがダメだった場合行く',
  },
]);
