import { useState, useEffect } from 'react';
import { DataMap } from '@grapecity/wijmo.grid';
import { Row, Col, Button } from 'react-bootstrap';
import { useMaster } from 'context/MasterContext';
import { IconButton } from '@mui/material';
import { useRestApi } from 'context/RestApiContext';
import DeleteIcon from '@mui/icons-material/Delete';
import './ClientCard.css';
import './ClientCardInquiry.css';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { MessageModal } from 'components/modal/MessageModal';
import ClientCardAfterContractModal from 'views/ClientCards/ClientCardAfterContractModal';
import YearManageModal from 'views/ClientCards/YearManageModal';
import OtherEditModal from 'views/ClientCards/OtherEditModal';
import { KoujiTagComponent } from 'components/Input/KoujiTagComponent';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import NotesIcon from '@mui/icons-material/Notes';
import { format } from 'date-fns';
import * as wjcCore from '@grapecity/wijmo';
import { toDate } from 'utils/dateUtils';

import {
  ToiawaseCodes,
  WorkKbns,
  WishPlans,
  Budgets,
  Periods,
  ClientRanks,
  Lines,
  YearManageWorks,
  MeetingStatus,
} from 'constants/constants';
import { Apartment } from '@mui/icons-material';

// 問い合わせ情報の表示エリア
function ClientCardInquiryDisplay(props) {
  const restApi = useRestApi();
  const master = useMaster();
  const numbers = ['⓪', '①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫', '⑬', '⑭'];
  const selectedTab = props.selectedTab;
  const setSelectedTab = props.setSelectedTab;

  const card = props.card;
  const client = props.client;
  const koujiTags = props.koujiTags;
  const contracts = props.contracts;
  const yearManages = props.yearManages;
  const nyukins = props.nyukins;
  const otherInfo = props.otherInfo;

  // メッセージダイアログ
  const [dialog, setDialog] = useState({});

  // 精算前の入金
  const [seisanBeforeNyukins, setSeisanBeforeNyukins] = useState([]);
  // 未入金額
  const [minyukin, setMinyukin] = useState(0);

  useEffect(() => {
    let seikyuDate = client.SEIKYU_DATE ?? '9999-12-31';

    // 請求日よりまえの入金を設定
    setSeisanBeforeNyukins(props.nyukins.filter((x) => x.NYUKIN_DATE < toDate(seikyuDate)));

    // 未入金額の合計を計算
    setMinyukin(client.SEIKSAN_KIN - client.NYUKIN_TOTAL);
  }, [nyukins]);

  // コードマッピング
  var staffMap = new DataMap([...master.staff, { id: 0, name: '' }], 'id', 'name');
  var workKbnMap = new DataMap([...WorkKbns, { key: 0, value: '' }], 'key', 'value');
  var toiawaseMap = new DataMap([...ToiawaseCodes, { key: 0, value: '' }], 'key', 'value');
  var wishPlanMap = new DataMap([...WishPlans, { key: 0, value: '' }], 'key', 'value');
  var meetingStatusMap = new DataMap([...MeetingStatus, { key: 0, value: '' }], 'key', 'value');
  var lineMap = new DataMap([...Lines, { key: 0, value: '' }], 'key', 'value');
  var yearManageWorkMap = new DataMap([...YearManageWorks, { key: 0, value: '' }], 'key', 'value');
  var periodMap = new DataMap([...Periods, { key: 0, value: '' }], 'key', 'value');
  var clientRankMap = new DataMap([...ClientRanks, { key: 0, value: '' }], 'key', 'value');
  var houseMakerMap = new DataMap([...master.houseMaker, { id: 0, name: '' }], 'id', 'name');
  var toriMap = new DataMap([...master.tori, { id: 0, name: '' }], 'id', 'name');
  var chanelMap = new DataMap(
    [...master.channelCd, { CHANNEL_CD: 0, CHANNEL_NM: '' }],
    'CHANNEL_CD',
    'CHANNEL_NM'
  );

  // タブの選択処理
  const handleOnClickTab = (tab) => {
    setSelectedTab(tab);
  };

  // 問い合わせ情報のダブルクリック処理
  const handleOnDoubleClickInquiry = () => {
    props.callInquiryEitModal();
  };

  // 契約後情報修正モーダルのパラメタ
  const [afterContractModalParam, setAfterContractModalParam] = useState({});

  // 契約情報のダブルクリック処理
  const handleOnDoubleClickContract = () => {
    setAfterContractModalParam({
      show: true,
      client: client,
      searchCard: props.searchCard,
      nyukins: props.nyukins,
      yearManages: yearManages,
      koujiTags: koujiTags,
    });
  };

  // 年間管理の追加のクリック処理
  const handleOnClickAddYearManage = (yearManage) => {
    setYearManageModalParam({
      show: true,
      client: client,
      yearManage: yearManage,
      mode: 'add',
    });
  };

  // 年間管理モーダルのパラメタ
  const [yearManageModalParam, setYearManageModalParam] = useState({});
  // 年間管理のダブルクリック処理
  const handleOnDoubleClickYearManage = (yearManage) => {
    setYearManageModalParam({
      show: true,
      client: client,
      yearManage: yearManage,
      mode: 'upd',
    });
  };

  // 年間管理の削除のクリック処理
  const handleOnClickYearManageDelete = (yearManage) => {
    setDialog({
      type: `confirm`,
      message: `${yearManage.YEAR}年の年間管理を削除します。よろしいですか？`,
      okCallBack: () => {
        restApi.post(`/api/year_manage/del/${yearManage.MANAGE_ID}`, {}, (data) => {
          props.searchCard(yearManages.length > 1 ? 'year_manage' : '');
        });
      },
    });
  };

  // その他情報のクリック処理
  const [otherEditModalParam, setOtherEditModalParam] = useState({});
  const handleOnClickOther = () => {
    setOtherEditModalParam({
      show: true,
      client: client,
      otherInfo: otherInfo,
    });
  };

  //　粗利率を取得
  const getArarititu = () => {
    // 粗利
    let arari = client.SEIKSAN_KIN - client.CYU_KIN_TOTAL;
    // 粗利率
    let arariritu = arari / client.SEIKSAN_KIN;
    arariritu = !isNaN(arariritu) ? arariritu : 0;

    return wjcCore.Globalize.format(arariritu, 'p1');
  };

  // 最終入金情報を取得
  const getLastNyukin = () => {
    // 請求日なしは未入金あつかい
    if (!client.SEIKYU_DATE) {
      return '';
    }
    // 請求日以降の入金
    let lastNyukins = props.nyukins.filter((x) => x.NYUKIN_DATE >= toDate(client.SEIKYU_DATE));
    if (lastNyukins.length <= 0) {
      return '';
    }

    return lastNyukins.map((x) => format(x.NYUKIN_DATE, 'M/d')).join('、') + 'ご入金';
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div
          className={`inquiry-label ${selectedTab == 'inquiry' && 'selected'}`}
          onClick={() => handleOnClickTab('inquiry')}
        >
          <i className="material-symbols-rounded">contact_page</i>
          問い合わせ情報
        </div>
        {card.CONTRACT_COUNT > 0 && (
          <div
            className={`pl-2 inquiry-label ${selectedTab == 'contract' && 'selected'}`}
            onClick={() => handleOnClickTab('contract')}
          >
            <HandshakeOutlinedIcon />
            <span className="ml-1">契約後情報</span>
          </div>
        )}
        {yearManages.length > 0 && (
          <div
            className={`pl-2 inquiry-label ${selectedTab == 'year_manage' && 'selected'}`}
            onClick={() => handleOnClickTab('year_manage')}
          >
            <EventNoteIcon />
            <span className="ml-1">年間管理情報</span>
          </div>
        )}
        <div
          className={`inquiry-label ${selectedTab == 'other' && 'selected'}`}
          onClick={() => handleOnClickTab('other')}
        >
          <NotesIcon />
          その他情報
        </div>
      </div>
      {selectedTab == 'inquiry' && (
        <>
          <div className="inquiry-tab" onDoubleClick={handleOnDoubleClickInquiry}>
            <Row>
              <Col md={2}>
                <b>名前</b>
              </Col>
              <Col md={2}>{client.NAME_1}</Col>
              <Col>{client.NAME_2}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>引き合い日</b>
              </Col>
              <Col>{client.UKE_DATE}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>受付担当</b>
              </Col>
              <Col>{staffMap.getDisplayValue(client.UKE_TAN_CD)}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>区分</b>
              </Col>
              <Col>{workKbnMap.getDisplayValue(client.WORK_KBN)}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>問い合わせ方法</b>
              </Col>
              <Col>{toiawaseMap.getDisplayValue(client.TOIAWASE_CD)}</Col>
            </Row>

            <Row className="mt-1">
              <Col md={2}>
                <b>プラン希望</b>
              </Col>
              <Col>{wishPlanMap.getDisplayValue(client.WISH_PLAN)}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>打合せ状況</b>
              </Col>
              <Col>{meetingStatusMap.getDisplayValue(client.MEETING_STATUS)}</Col>
            </Row>

            <Row className="mt-1">
              <Col md={2}>
                <b>建築前住所</b>
              </Col>
              <Col md={10}>{`〒${client.POST_CODE} ${client.CITY}`}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>建築地</b>
              </Col>
              <Col md={10}>{`〒${client.BUILD_POST_CODE} ${client.BUILD_CITY}`}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>建物完成予定日</b>
              </Col>
              <Col>{client.COMPLETE_DATE}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>引き渡し予定日</b>
              </Col>
              <Col>{client.DELIVERY_DATE}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>引っ越し予定日</b>
              </Col>
              <Col md={2}>{client.MOVING_DATE}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>住宅建築年</b>
              </Col>
              <Col>{client.BUILD_AGE}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>建築会社</b>
              </Col>
              <Col>{houseMakerMap.getDisplayValue(client.HOUSE_MAKER)}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>連絡先</b>
              </Col>
              <Col md={3}>{client.TEL1 ? client.TEL1 + ' (主)' : ''}</Col>
              <Col md={3}>{client.TEL2 ? client.TEL2 + ' (奥)' : ''}</Col>
              <Col md={3}>{client.TEL3 ? client.TEL3 + ' (固)' : ''}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>メールアドレス</b>
              </Col>
              <Col md={10}>{client.MAIL}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>引き合い動機</b>
              </Col>
              <Col md={2}>{chanelMap.getDisplayValue(client.CHANEL_KBN)}</Col>
              <Col md={2}>{chanelMap.getDisplayValue(client.CHANEL_KBN2)}</Col>
              <Col md={2}>{chanelMap.getDisplayValue(client.CHANEL_KBN3)}</Col>
            </Row>
          </div>
        </>
      )}
      {/* 契約後情報 */}
      {selectedTab == 'contract' && (
        <>
          <div className="inquiry-tab" onDoubleClick={handleOnDoubleClickContract}>
            <Row>
              <Col md={2}>
                <b>引き合い動機</b>
              </Col>
              <Col md={2}>{chanelMap.getDisplayValue(client.CHANEL_KBN)}</Col>
              <Col md={2}>{chanelMap.getDisplayValue(client.CHANEL_KBN2)}</Col>
              <Col md={2}>{chanelMap.getDisplayValue(client.CHANEL_KBN3)}</Col>
            </Row>
            <Row>
              <Col md={2} className="mt-1">
                <b>DMナンバー</b>
              </Col>
              <Col md={2}>{client.DM_NO}</Col>
              <Col> {client?.PRINT_FLG == 1 ? '宛名を印刷する' : '宛名を印刷しない'}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>顧客名</b>
              </Col>
              <Col md={2}>{client.NAME_1}</Col>
              <Col>{client.NAME_2}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>住所</b>
              </Col>
              <Col md={10}>{`〒${client.BUILD_POST_CODE} ${client.BUILD_CITY}`}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>電話</b>
              </Col>
              <Col md={3}>{client.TEL1 ? client.TEL1 + ' (主)' : ''}</Col>
              <Col md={3}>{client.TEL2 ? client.TEL2 + ' (奥)' : ''}</Col>
              <Col md={3}>{client.TEL3 ? client.TEL3 + ' (固)' : ''}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>LINE登録</b>
              </Col>
              <Col md={2}>{lineMap.getDisplayValue(client.LINE)}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>お客様ランク</b>
              </Col>
              <Col md={2}>{clientRankMap.getDisplayValue(client.LANK)}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>工事全般</b>
              </Col>
              <Col md={4}>{toriMap.getDisplayValue(client.KOUJI_TORI_CD)}</Col>
              <Col md={2}>
                <b>アルミ工事</b>
              </Col>
              <Col md={4}>{toriMap.getDisplayValue(client.ARUMI_KOUJI_TORI_CD)}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>植栽工事</b>
              </Col>
              <Col md={4}>{toriMap.getDisplayValue(client.PLANT_KOUJI_TORI_CD)}</Col>
              <Col md={2}>
                <b>その他</b>
              </Col>
              <Col md={4}>{toriMap.getDisplayValue(client.OTHER_KOUJI_TORI_CD)}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>契約日</b>
              </Col>
              <Col md={2}>{client.KEI_DATE}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>着工予定</b>
              </Col>
              <Col md={2}>
                {contracts.length > 0 && <>{contracts[contracts.length - 1].KOUKI_ST}</>}
                {contracts.length > 0 &&
                  periodMap.getDisplayValue(contracts[contracts.length - 1].KOUKI_ST_KBN)}
              </Col>
              <Col md={2}>
                <b>着工日</b>
              </Col>
              <Col md={2}>{client.ST_DATE}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>工事完工予定</b>
              </Col>
              <Col md={2}>
                {contracts.length > 0 && contracts[contracts.length - 1].KOUKI_ED}
                {contracts.length > 0 &&
                  periodMap.getDisplayValue(contracts[contracts.length - 1].KOUKI_ED_KBN)}
              </Col>
              <Col md={2}>
                <b>工事完工日</b>
              </Col>
              <Col md={2}>{client.ED_DATE}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>請求日</b>
              </Col>
              <Col md={2}>{client.SEIKYU_DATE}</Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>①請負工事金額</b>
              </Col>
              <Col md={2} className="text-right">
                {client.KEI_KIN.toLocaleString() + '円'}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>②増減工事</b>
              </Col>
              <Col md={2} className="text-right">
                {client.ZOUGEN_KIN.toLocaleString() + '円'}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>③精算金額</b>
              </Col>
              <Col md={2} className="text-right">
                {client.SEIKSAN_KIN.toLocaleString() + '円'}
              </Col>
              <Col md={2}>
                <b>① + ②</b>
              </Col>
            </Row>
            {seisanBeforeNyukins.map((n, i) => (
              <Row className="mt-1">
                <Col md={2}>
                  <b>
                    {numbers[i + 4]}
                    {format(n.NYUKIN_DATE, 'M/d')}ご入金
                  </b>
                </Col>
                <Col md={2} className="text-right">
                  {n.NYUKIN_KIN.toLocaleString() + '円'}
                </Col>
              </Row>
            ))}
            <Row className="mt-1">
              <Col md={2}>
                <b>{numbers[seisanBeforeNyukins.length + 4]}最終請求金額</b>
              </Col>
              <Col md={2} className="text-right">
                {client.KAN_KIN.toLocaleString() + '円'}
              </Col>
              <Col md={2}>
                <b>③{seisanBeforeNyukins.map((_, i) => ` ‐ ${numbers[i + 4]}`)}</b>
              </Col>
              {/* 最終請求の入金日 */}
              <Col md={6}>
                {getLastNyukin()}
                {minyukin == 0 ? (
                  <sapn className="ml-2" style={{ color: 'blue' }}>
                    全額入金
                  </sapn>
                ) : minyukin > 0 ? (
                  <sapn className="ml-2" style={{ color: 'red' }}>
                    {minyukin.toLocaleString()}円未入金
                  </sapn>
                ) : (
                  <sapn className="ml-2" style={{ color: 'red' }}>
                    {(minyukin - 1).toLocaleString()}円超過入金
                  </sapn>
                )}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>工事原価</b>
              </Col>
              <Col md={2} className="text-right">
                {client.CYU_KIN_TOTAL.toLocaleString() + '円'}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>利益率</b>
              </Col>
              <Col md={2} className="text-right">
                {getArarititu()}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>工事内容タグ</b>
              </Col>
              <Col md={10}>
                {koujiTags.map((x) => (
                  <KoujiTagComponent labels={master.label} tagCode={x} />
                ))}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col md={2}>
                <b>保証書発行</b>
              </Col>
              <Col md={2}>
                {client.WARRANTY_RELEASE_FLG == 1 ? (
                  <>
                    <CheckBoxIcon className="mr-2" style={{ color: '#005cc8' }}></CheckBoxIcon>
                    {client.WARRANTY_RELEASE_DATE}
                  </>
                ) : (
                  <>未発行</>
                )}
              </Col>
            </Row>
          </div>
        </>
      )}
      {/* 年間契約 */}
      {selectedTab == 'year_manage' && (
        <>
          <div className="year-manage-tab">
            <Row>
              <Col md={2}>{yearManages[0]?.TITLE}</Col>
              <Col md={10} className="text-right">
                <Button
                  size="sm"
                  style={{ width: '108px', height: '22px' }}
                  variant="primary"
                  className="btn-fill p-0"
                  onClick={handleOnClickAddYearManage}
                >
                  <ControlPointIcon style={{ fontSize: 14 }} />
                  年間管理を追加
                </Button>
              </Col>
            </Row>
            {yearManages.map((x, i) => (
              <div
                key={i}
                className="mt-2 year-manage-item"
                onDoubleClick={() => {
                  handleOnDoubleClickYearManage(x);
                }}
              >
                <Row>
                  <Col md={2}>
                    <b>{x.YEAR}年</b>
                  </Col>
                  <Col md={10}>
                    <IconButton
                      aria-label="delete"
                      className="manage-delete-button"
                      onClick={() => {
                        handleOnClickYearManageDelete(x);
                      }}
                      style={{ position: 'absolute', top: -5, right: 10 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Col>
                </Row>
                <Row className="mt-0">
                  <Col md={3} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>{format(x.LAST_UPDATE, 'M/d')}</div>
                    <div className="text-right">{x.KAKAKU.toLocaleString() + '円'}</div>
                  </Col>
                  <Col md={9}>{x.DESCRIPTION}</Col>
                </Row>
                <Row className="mt-1">
                  <Col md={12}>
                    {Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
                      <>
                        {x[`M${m}`] == 1 &&
                          `・${m}月 ${yearManageWorkMap.getDisplayValue(x[`M${m}_WORK`])} `}
                      </>
                    ))}
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </>
      )}
      {/* その他 */}
      {selectedTab == 'other' && (
        <>
          <div className="inquiry-tab" onDoubleClick={handleOnClickOther}>
            <Row>
              <Col md={2}>
                <b>工事履歴</b>
              </Col>
            </Row>
            <Row className="mt-1 ml-1">
              <Col md={12}>
                {otherInfo?.KOUJI_HISTORY ? otherInfo?.KOUJI_HISTORY : '（工事履歴はありません）'}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={2}>
                <b>工事関連</b>
              </Col>
            </Row>
            <Row className="mt-1 ml-1">
              <Col md={12}>{otherInfo?.KOUJI_MEMO ? otherInfo?.KOUJI_MEMO : '（なし）'}</Col>
            </Row>
            <Row className="mt-3">
              <Col md={2}>
                <b>その他</b>
              </Col>
            </Row>
            <Row className="mt-1 ml-1">
              <Col md={12}>{otherInfo?.OTHER_MEMO ? otherInfo?.OTHER_MEMO : '（なし）'}</Col>
            </Row>
            {client.DM_NO && (
              <>
                <Row className="mt-3">
                  <Col md={2}>
                    <b>印刷関連</b>
                  </Col>
                </Row>
                <Row className="mt-1 ml-1">
                  <Col md={12}>{otherInfo?.PRINT_MEMO}</Col>
                </Row>
              </>
            )}
          </div>
        </>
      )}

      {/* 契約後情報編集ダイアログ */}
      <ClientCardAfterContractModal params={afterContractModalParam} />
      {/* その他情報編集モーダル画面 */}
      <OtherEditModal params={otherEditModalParam} callback={() => props.searchCard('other')} />
      {/** 年間管理モーダル画面 */}
      <YearManageModal
        params={yearManageModalParam}
        callback={() => props.searchCard('year_manage')}
      />
      {/* メッセージダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}

export default ClientCardInquiryDisplay;
