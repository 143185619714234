import ClientManage from 'views/ClientManage/ClientManage';
import MasterManagement from 'views/MasterManagement/MasterManagement';
import QuoteBaseEntry from 'views/MasterManagement/QuoteBaseEntry';
import AnalysisReport from 'views/AnalysisReport/AnalysisReport';
import TriManage from 'views/MasterManagement/TriManage';
import TriEdit from 'views/MasterManagement/TriEdit';
import ClientCards from 'views/ClientCards/ClientCards';
import KoujiDaichoList from 'views/KoujiDaicho/KoujiDaichoList';
import YearManageList from 'views/YearManage/YearManageList';

const dashboardRoutes = [
  {
    path: '/cards',
    name: '案件全般',
    icon: 'nc-icon nc-align-left-2',
    component: ClientCards,
    layout: '/admin',
  },
  {
    path: '/clientmanage',
    name: '顧客リスト',
    icon: 'nc-icon nc-single-02',
    component: ClientManage,
    layout: '/admin',
  },
  {
    path: '/koujiDaichoList',
    name: '工事台帳一覧',
    icon: 'nc-icon nc-settings-tool-66',
    component: KoujiDaichoList,
    layout: '/admin',
  },
  {
    path: '/yearManageList',
    name: '年間管理一覧',
    icon: 'event_note',
    component: YearManageList,
    layout: '/admin',
  },
  {
    path: '/master_manage',
    name: 'マスタ修正',
    icon: 'nc-icon nc-ruler-pencil',
    component: MasterManagement,
    layout: '/admin',
    auth: ['admin'], // 管理者
  },
  {
    path: '/ProductsMaster',
    name: '工事台帳商品マスタ',
    component: QuoteBaseEntry,
    layout: '/admin',
  },
  // {
  //   path: '/trimanage',
  //   name: '取引先マスタ',
  //   component: TriManage,
  //   layout: '/admin',
  // },
  // {
  //   path: '/triedit',
  //   name: '取引先編集',
  //   component: TriEdit,
  //   layout: '/admin',
  // },
];

export default dashboardRoutes;
