/**
 * 必須チェック処理
 * @param {*} val 値
 * @param {*} name 名称
 * @param {*} id ID
 * @param {*} errors エラーリスト
 */
export function checkRequired(val, name, id, errors, cannotZero = false) {
  if (cannotZero && val == 0) {
    errors.push({ msg: `・${name}を入力してください。`, fields: [id] });
    return;
  }
  if (!val?.toString().trim()) {
    errors.push({ msg: `・${name}を入力してください。`, fields: [id] });
  }
}

/**
 * 数値チェック
 * @param {*} val 値
 * @param {*} name 名称
 * @param {*} id ID
 * @param {*} errors エラーリスト
 */
export function checkNumber(val, name, id, errors) {
  // 空文字はOK（別途必須チェックすること）
  if (val == '') {
    return;
  }
  if (!/^\d+$/.test(val)) {
    errors.push({ msg: `・${name}は半角数字で入力してください。`, fields: [id] });
    return;
  }
}
