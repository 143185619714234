import { culture } from '@grapecity/wijmo';
import * as input from '@grapecity/wijmo.input';
import { InputDate } from '@grapecity/wijmo.react.input';
import 'assets/css/textcomponent.css';
import React from 'react';

class CalendarComponent extends React.Component {
  constructor(props) {
    super(props);
    input.InputDate.controlTemplate = `
        <div style="position:relative" class="wj-template">
            <div class="wj-input calendar">
                <div class="wj-input-group wj-input-btn-visible">
                    <input wj-part="input" type="text" class="wj-form-control"/>
                    <span wj-part="btn" class="wj-input-group-btn" tabindex="-1">
                        <button class="wj-btn wj-btn-default" type="button" tabindex="-1">
                            <span class="wj-glyph-calendar"></span>
                        </button>
                    </span>
                </div>
            </div>
            <div wj-part="dropdown" class="wj-content wj-dropdown-panel" style="display:none;position:absolute;z-index:100">
            </div>
        </div>
        `;
    culture.Globalize.calendar.patterns['y'] = 'yyyy/MM';
    this.state = {
      value: null,
    };
  }

  _holidays = {
    '1/1': '元旦',
    '1/2/1': '成人の日',
    '2/11': '建国記念の日',
    '2/23': '天皇誕生日',
    '3/20': '春分の日',
    '4/29': '昭和の日',
    '5/3': '憲法記念日',
    '5/4': 'みどりの日',
    '5/5': 'こどもの日',
    '7/3/1': '海の日',
    '8/11': '山の日',
    '9/23': '秋分の日',
    '9/3/1': '敬老の日',
    '10/2/1': '体育の日',
    '11/3': '文化の日',
    '11/23': '勤労感謝の日',
  };

  getHoliday(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let holiday = this._holidays[month + '/' + day];
    //
    if (!holiday) {
      let weekDay = date.getDay(),
        weekNum = Math.floor((day - 1) / 7) + 1;
      //
      holiday = this._holidays[month + '/' + weekNum + '/' + weekDay];
    }
    //
    return holiday;
  }

  _onInitialized(sender) {
    this.setState({ value: sender.value });
    sender.calendar.formatItem.addHandler((sender, e) => {
      let weekday = e.data.getDay();
      let holiday = this.getHoliday(e.data);
      wijmo.toggleClass(e.item, 'date-holiday', holiday != null || weekday == 0);
      wijmo.toggleClass(e.item, 'date-weekend', weekday == 6);
      e.item.title = holiday ? holiday : '';
    });
  }

  _onValueChanged(event) {
    this.setState({ value: event.value });
    this.props.onChange(event.inputElement, event.inputElement.value);
  }

  render() {
    return (
      <InputDate
        value={this.props.value == '' ? null : this.props.value}
        selectionMode={this.props.selectionMode ?? 'Day'} // デフォルトはDay:日付、Month:月に変更可能
        format={this.props.selectionMode == 'Month' ? 'yyyy/MM' : 'yyyy/MM/dd'}
        isRequired={false}
        isDisabled={this.props.disabled}
        initialized={this._onInitialized.bind(this)}
        valueChanged={this._onValueChanged.bind(this)}
        style={{ backgroundColor: this.props.error ? 'lightpink' : '' }}
      />
    );
  }
}
export default CalendarComponent;
