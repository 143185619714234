import { CellRange, DataMap } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import * as wjcCore from '@grapecity/wijmo';
import { CellType } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import { useRestApi } from 'context/RestApiContext';
import { MessageModal } from 'components/modal/MessageModal';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import KoujiDaichoModal from 'views/KoujiDaicho/KoujiDaichoModal';
import SelectComponent from 'components/Input/SelectComponent';
import TextComponent from 'components/Input/TextComponent';
import { toFloat } from 'utils/numberUtils';

const KoujiDaichoList = (props) => {
  const restApi = useRestApi();

  // グリッド
  const flexGrid = useRef();

  // スクロール位置
  const scrollPosition = useRef(0);

  // 精算金額合計
  const [totalSeisan, setTotalSeisan] = useState(0);

  // 粗利率の平均
  const [averageArariritsu, setAverageArariritsu] = useState(0);

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // 顧客一覧情報
  const [clients, setClients] = useState([]);

  // 工事台帳モーダルパラメタ
  const [koujiDaichoModalParam, setKoujiDaichoModalParam] = useState({});

  // 選択中の顧客ID
  const [targetClientCd, setTargetClientCd] = useState();

  // 顧客検索条件
  const [searchCondition, setSearchCondition] = useState({
    YEAR: new Date().getFullYear(),
    MONTH: '',
    MONTH_END: '',
  });

  // 検索時に再選択
  useEffect(() => {
    if (targetClientCd) {
      const index = clients.indexOf(clients.find((c) => c['CLIENT_CD'] == targetClientCd));
      if (index >= 0) {
        setTimeout(() => {
          flexGrid.current.control.select(new CellRange(index), true);
          setTimeout(() => {
            flexGrid.current.control.scrollPosition = scrollPosition.current;
          }, 50);
        }, 200);
      }
    }
    // 合計金額を計算
    setTotalSeisan(clients.reduce((a, x) => a + toFloat(x['SEIKSAN_KIN']), 0));

    //　粗利率を計算、原価=0円は対象外
    let arariTagets = clients.filter((x) => x.CYU_KIN_TOTAL > 0);
    let _totalSeisan = arariTagets.reduce((a, x) => a + toFloat(x['SEIKSAN_KIN']), 0);
    let _totalArari = arariTagets.reduce((a, x) => a + toFloat(x['ARARI']), 0);

    // 粗利率を計算
    let arariritu = _totalArari / _totalSeisan;
    arariritu = !isNaN(arariritu) ? arariritu : 0;
    arariritu = wjcCore.Globalize.format(arariritu, 'p1');
    setAverageArariritsu(arariritu);
  }, [clients]);

  // 初期表示処理
  useEffect(() => {
    searchClients();
  }, [searchCondition]);

  // 編集処理
  const handleChange = (prop) => (target) => {
    const val = target?.value ?? target?.target.value;
    let _searchCondition = { ...searchCondition, [prop]: val };

    if (prop == 'YEAR' && !val) {
      _searchCondition = { ..._searchCondition, MONTH: '', MONTH_END: '' };
    } else if (prop == 'MONTH' && !val) {
      _searchCondition = { ..._searchCondition, MONTH_END: '' };
    }
    setSearchCondition(_searchCondition);
  };

  //　粗利率を取得
  const getArarititu = (seikyu, order) => {
    // 粗利
    let arari = seikyu - order;
    // 粗利率
    let arariritu = arari / seikyu;
    arariritu = !isNaN(arariritu) ? arariritu : 0;
    return arariritu;
  };

  // 検索処理
  const searchClients = (clientCd = null) => {
    setTargetClientCd(clientCd);
    restApi.post('/api/order/list', searchCondition, (data) => {
      setClients(
        data.map((x) => ({
          ...x,
          ADDRESS: `${x.BUILD_CITY}`,
          ARARI: x.SEIKSAN_KIN - x.CYU_KIN_TOTAL,
          ARARI_RITU: getArarititu(x.SEIKSAN_KIN, x.CYU_KIN_TOTAL),
        }))
      );
    });
  };

  // 編集ボタン
  const handleOnClickEditClient = useCallback(() => {
    // スクロール位置を保持
    scrollPosition.current = flexGrid.current.control.scrollPosition;

    setKoujiDaichoModalParam({
      show: true,
      clientCd: flexGrid.current.control.selectedItems[0].CLIENT_CD,
    });
  }, []);

  // 一覧の更新時に件数を更新する
  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(clients.length);
  }, [clients]);

  // フィルタの変更処理
  const filterApplied = (s, e) => {
    let grid = s.grid;
    setCount(grid?.collectionView?.items.length);
  };

  return (
    <>
      <Card className="m-0 pl-3 pt-1  table-container">
        <Row style={{ display: 'flex' }}>
          <div className="ml-3 pr-1">
            <label>年</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SelectComponent value={searchCondition.YEAR} onChange={handleChange('YEAR')}>
                <option key={0} value={''}>
                  {'　'}
                </option>

                {Array.from({ length: new Date().getFullYear() - 2018 + 1 }, (_, i) => 2018 + i)
                  .reverse()
                  .map((i) => (
                    <option key={i} value={i}>
                      {i}年
                    </option>
                  ))}
              </SelectComponent>
            </div>
          </div>
          {searchCondition.YEAR && (
            <>
              <div className="ml-3 pr-1">
                <label>月</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SelectComponent value={searchCondition.MONTH} onChange={handleChange('MONTH')}>
                    <option key={0} value={''}>
                      {'　'}
                    </option>

                    {Array.from({ length: 12 }, (_, i) => i + 1).map((i) => (
                      <option key={i} value={i}>
                        {i}月
                      </option>
                    ))}
                  </SelectComponent>
                </div>
              </div>
            </>
          )}
          {searchCondition.MONTH && (
            <>
              <div className="ml-3 pr-1">
                <label>　</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  ～
                  <SelectComponent
                    className="ml-3"
                    value={searchCondition.MONTH_END}
                    onChange={handleChange('MONTH_END')}
                  >
                    <option key={0} value={''}>
                      {'　'}
                    </option>

                    {Array.from({ length: 12 }, (_, i) => i + 1).map((i) => (
                      <option key={i} value={i}>
                        {i}月
                      </option>
                    ))}
                  </SelectComponent>
                </div>
              </div>
            </>
          )}
          <div className="ml-3 pr-1">
            <label>精算金額合計</label>
            <div className="mt-2 text-right" style={{ fontSize: '15pt', width: 160 }}>
              {totalSeisan.toLocaleString()}円
            </div>
          </div>
          <div className="ml-3 pr-1">
            <label>利益率</label>
            <div className="mt-2 text-right" style={{ fontSize: '15pt', width: 160 }}>
              {averageArariritsu}
            </div>
          </div>
        </Row>
        <div className="mt-2">件数：{count}件</div>
        <Row>
          <Col>
            <div className="table-scroll" style={{ height: 'calc(100vh - 210px)' }}>
              <FlexGrid
                ref={flexGrid}
                isReadOnly={true}
                itemsSource={clients}
                selectionMode={'Row'}
                headersVisibility={'Column'}
                style={{ height: '100%' }}
                onDoubleClick={(e) => {
                  const grid = flexGrid.current.control;
                  const ht = grid.hitTest(e);
                  if (ht.cellType === CellType.Cell) {
                    const item = grid.rows[ht.row].dataItem;
                    handleOnClickEditClient(); // カードダイアログを開く処理
                  }
                }}
                copying={(s, e) => {
                  // セル単位でコピーする
                  const selection = s.selection;
                  const selectedCellContent = s.getCellData(selection.row, selection.col, true);
                  // クリップボードにコピーされる内容を上書き
                  e.cancel = true;
                  navigator.clipboard.writeText(selectedCellContent);
                }}
              >
                <FlexGridFilter filterApplied={filterApplied} />
                <FlexGridColumn header="" binding="" isReadOnly={true} width={42}>
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) => (
                      <span>
                        <Button
                          className="material-symbols-rounded cell-icon btn-fill"
                          variant="primary"
                          title="編集"
                          onClick={handleOnClickEditClient}
                        >
                          edit
                        </Button>
                      </span>
                    )}
                  />
                </FlexGridColumn>
                <FlexGridColumn binding="CYU_MONTH" header="年月" width={80}></FlexGridColumn>
                <FlexGridColumn binding="TITLE" header="カード" width={240}></FlexGridColumn>
                <FlexGridColumn binding="KOUJI_MEI" header="工事名" width={240}></FlexGridColumn>
                <FlexGridColumn
                  binding="ADDRESS"
                  minWidth={240}
                  header="住所"
                  width={'*'}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="KOUJI_TORI_NAME"
                  header="工事全般"
                  width={160}
                ></FlexGridColumn>

                <FlexGridColumn
                  binding="CYU_KIN_TOTAL"
                  header="工事原価（税込）"
                  dataType={'Number'}
                  format="n0"
                  width={140}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="SEIKSAN_KIN"
                  header="精算金額"
                  dataType={'Number'}
                  format="n0"
                  width={140}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="ARARI"
                  header="粗利益額"
                  dataType={'Number'}
                  format="n0"
                  width={140}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="ARARI_RITU"
                  header="利益率"
                  format="p1"
                  width={100}
                ></FlexGridColumn>
              </FlexGrid>
            </div>
          </Col>
        </Row>
      </Card>
      {/* メッセージダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
      {/** 工事台帳モーダル画面 */}
      <KoujiDaichoModal
        params={koujiDaichoModalParam}
        callback={() => {
          searchClients(koujiDaichoModalParam.clientCd);
        }}
      />
    </>
  );
};

export default KoujiDaichoList;
