import { Chip } from '@mui/material';
import { toInt } from 'utils/numberUtils';

export const getTagName = (labels, tagCode) => {
  // 2000番以下は追加工事
  if (tagCode < 2000) {
    return `${tagCode}期`;
  }
  // 3000番以下は西暦
  else if (tagCode < 3000) {
    return `${tagCode}年`;
  }
  // 上記以外はマスタから取得
  else {
    return labels.find((x) => x.LABEL_CD == tagCode)?.DISPLAY;
  }
};

// 工事タグコンポーネント
export const KoujiTagComponent = (props) => {
  const labels = props.labels;
  const tagCode = toInt(props.tagCode);

  let tagName = getTagName(labels, tagCode);

  return (
    <Chip
      className="m-1"
      style={{
        width: props.width,
        minWidth: '72px',
        color: '#feffe6',
        backgroundColor: '#ec8b4a',
      }}
      size="small"
      label={tagName}
      onClick={props.onClick ? () => props.onClick() : null}
      onDelete={props.onDelete ? () => props.onDelete(props.tagCode) : null}
    />
  );
};
