import React, { useState, useEffect } from 'react';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextComponent from 'components/Input/TextComponent';
import SelectComponent from 'components/Input/SelectComponent';

import { useRestApi } from 'context/RestApiContext';
import { MessageModal } from 'components/modal/MessageModal';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { checkRequired } from 'utils/validationUtils';
import { toInt } from 'utils/numberUtils';
import { Periods } from 'constants/constants';

// 契約の登録用のモーダル
function ContractModal(props) {
  const restApi = useRestApi();

  const [show, setShow] = useState(false);
  // メッセージダイアログ
  const [dialog, setDialog] = useState({});
  // 契約情報
  const [errorFileds, setErrorFileds] = useState([]);
  // 契約情報
  const [contract, setContract] = useState({
    SUM_KIN: 0,
  });
  const handleClose = () => setShow(false);

  useEffect(() => {
    if (props?.params?.mode == 'add') {
      restApi.get(`/api/contract/${props?.params.clientCd}`, (data) => {
        if (data.error) {
          setDialog({ type: 'error', message: data.error });
        } else {
          setContract(data);
          setShow(true);
        }
      });
    } else if (props?.params?.mode == 'upd') {
      restApi.get(`/api/contract/${props?.params.clientCd}/${props?.params.keiNo}`, (data) => {
        setContract(data);
        setShow(true);
      });
    }
    setErrorFileds([]);
  }, [props.params]);

  // 編集処理
  const handleOnChange = (prop) => (target) => {
    setContract({ ...contract, [prop]: target?.value ?? target?.target.value });
    // エラーをクリア
    setErrorFileds([...errorFileds].filter((x) => x != prop));
  };

  // 入力チェック
  const validate = () => {
    let errors = [];
    // checkRequired(contract.KEI_DATE, '契約日', 'KEI_DATE', errors);
    checkRequired(contract.KOUJI_MEI, '工事名称', 'KOUJI_MEI', errors);
    // checkRequired(contract.KOUKI_ST, '着工予定', 'KOUKI_ST', errors);
    // checkRequired(contract.KOUKI_ST_KBN, '着工予定（期間）', 'KOUKI_ST_KBN', errors, true);
    // checkRequired(contract.KOUKI_ED, '完成予定', 'KOUKI_ED', errors);
    // checkRequired(contract.KOUKI_ED_KBN, '完成予定（期間）', 'KOUKI_ED_KBN', errors, true);
    // 契約金を入力した場合は期日を表示
    if (contract.KEI_KIN > 0) {
      checkRequired(contract.KEI_KIN_LIMIT_DATE, '契約金支払い期日', 'KEI_KIN_LIMIT_DATE', errors);
    }

    setErrorFileds(errors.flatMap((x) => x.fields));

    return errors.map((x) => x.msg);
  };

  // 保存ボタンをクリック
  const hendleClickSave = () => {
    // 入力チェック
    var error = validate();
    if (error.length > 0) {
      setDialog({
        type: 'error',
        message: error,
      });
      return;
    }

    restApi.post(
      `/api/contract/${props.params.mode}/${props.params.clientCd}`,
      {
        contract: contract,
      },
      (_) => {
        props.loadCards();
        setShow(false);
      }
    );
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '1080px',
              backgroundColor: '#f1f2f4',
              marginTop: '40px',
            },
          },
        }}
        open={show}
        disableEnforceFocus={true} // フォーカス制御を無効にする
      >
        <DialogTitle>
          契約
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Card className="m-0 p-2">
            <Row>
              <Col className="pr-1" md="2">
                <Form.Group>
                  <label>契約番号</label>
                  <TextComponent
                    type="text"
                    value={contract.KEI_NO_DISPLAY}
                    onChange={handleOnChange('KEI_NO_DISPLAY')}
                    error={errorFileds.some((x) => x == 'KEI_NO_DISPLAY')}
                  />
                </Form.Group>
              </Col>
              <Col className="pr-1" md="3">
                <Form.Group>
                  <label>契約日</label>
                  <TextComponent
                    type="date"
                    value={contract.KEI_DATE}
                    onChange={handleOnChange('KEI_DATE')}
                    error={errorFileds.some((x) => x == 'KEI_DATE')}
                  ></TextComponent>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="3">
                <Form.Group>
                  <label>発注者　氏名</label>
                  <TextComponent
                    type="text"
                    value={contract.CLIENT_NAME}
                    onChange={handleOnChange('CLIENT_NAME')}
                    error={errorFileds.some((x) => x == 'CLIENT_NAME')}
                  ></TextComponent>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="2">
                <Form.Group>
                  <label>発注者　郵便番号</label>
                  <TextComponent
                    type="tel"
                    value={contract.CLIENT_POST_CODE}
                    onChange={handleOnChange('CLIENT_POST_CODE')}
                    error={errorFileds.some((x) => x == 'CLIENT_POST_CODE')}
                  ></TextComponent>
                </Form.Group>
              </Col>
              <Col className="pr-1" md="6">
                <Form.Group>
                  <label>発注者　住所</label>
                  <TextComponent
                    type="text"
                    value={contract.CLIENT_ADDRESS}
                    onChange={handleOnChange('CLIENT_ADDRESS')}
                    error={errorFileds.some((x) => x == 'CLIENT_ADDRESS')}
                  ></TextComponent>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="5">
                <Form.Group>
                  <label>工事名称</label>
                  <TextComponent
                    type="text"
                    value={contract.KOUJI_MEI}
                    onChange={handleOnChange('KOUJI_MEI')}
                    error={errorFileds.some((x) => x == 'KOUJI_MEI')}
                  ></TextComponent>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="8">
                <Form.Group>
                  <label>工事場所</label>
                  <TextComponent
                    type="text"
                    value={contract.KOUJI_BASYO}
                    onChange={handleOnChange('KOUJI_BASYO')}
                    error={errorFileds.some((x) => x == 'KOUJI_BASYO')}
                  ></TextComponent>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="4">
                <Form.Group>
                  <label>工期：着工予定</label>
                  <span style={{ display: 'flex' }}>
                    <TextComponent
                      type="date"
                      selectionMode="Month"
                      value={contract.KOUKI_ST ?? ''}
                      onChange={handleOnChange('KOUKI_ST')}
                      error={errorFileds.some((x) => x == 'KOUKI_ST')}
                    ></TextComponent>
                    <SelectComponent
                      className="ml-2"
                      value={contract.KOUKI_ST_KBN}
                      onChange={handleOnChange('KOUKI_ST_KBN')}
                      error={errorFileds.some((x) => x == 'KOUKI_ST_KBN')}
                    >
                      {Periods.map((x, i) => (
                        <option key={i} value={x.key}>
                          {x.value}
                        </option>
                      ))}
                    </SelectComponent>
                  </span>
                </Form.Group>
              </Col>
              <Col className="pr-1" md="4">
                <Form.Group>
                  <label>工期：完成予定</label>
                  <span style={{ display: 'flex' }}>
                    <TextComponent
                      type="date"
                      selectionMode="Month"
                      value={contract.KOUKI_ED ?? ''}
                      onChange={handleOnChange('KOUKI_ED')}
                      error={errorFileds.some((x) => x == 'KOUKI_ED')}
                    ></TextComponent>
                    <SelectComponent
                      className="ml-2"
                      value={contract.KOUKI_ED_KBN}
                      onChange={handleOnChange('KOUKI_ED_KBN')}
                      error={errorFileds.some((x) => x == 'KOUKI_ED_KBN')}
                    >
                      {Periods.map((x, i) => (
                        <option key={i} value={x.key}>
                          {x.value}
                        </option>
                      ))}
                    </SelectComponent>
                  </span>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="3">
                <Form.Group>
                  <label>請負工事金額</label>
                  <TextComponent
                    type="number"
                    value={contract.SUM_KIN}
                    onChange={handleOnChange('SUM_KIN')}
                  ></TextComponent>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="3">
                <Form.Group>
                  <label>契約金</label>
                  <TextComponent
                    type="number"
                    style={{ textAlign: 'right' }}
                    value={contract.KEI_KIN}
                    onChange={handleOnChange('KEI_KIN')}
                  ></TextComponent>
                </Form.Group>
              </Col>
              <Col className="pr-1" md="3">
                <Form.Group>
                  <label>契約金支払期日</label>
                  <TextComponent
                    type="date"
                    value={contract.KEI_KIN_LIMIT_DATE ?? ''}
                    onChange={handleOnChange('KEI_KIN_LIMIT_DATE')}
                  ></TextComponent>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col className="pr-1" md="3">
                <Form.Group>
                  <label>着工金</label>
                  <TextComponent
                    type="number"
                    style={{ textAlign: 'right' }}
                    value={contract.ST_KIN}
                    onChange={handleOnChange('ST_KIN')}
                  ></TextComponent>
                </Form.Group>
              </Col>
              <Col className="pr-1" md="3"></Col>
            </Row>
            <Row>
              <Col className="pr-1" md="3">
                <Form.Group>
                  <label>完工金（予定）</label>
                  <TextComponent
                    disabled={true}
                    type="number"
                    style={{ textAlign: 'right' }}
                    value={
                      toInt(contract.SUM_KIN) - toInt(contract.KEI_KIN) - toInt(contract.ST_KIN)
                    }
                  ></TextComponent>
                </Form.Group>
              </Col>
              <Col className="pr-1" md="3"></Col>
            </Row>
          </Card>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button
            variant="primary"
            className="btn-fill footer-button ml-4 mr-2"
            onClick={hendleClickSave}
            style={{ flexGrow: 1 }}
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}
export default ContractModal;
