import React, { useState } from 'react';
import { IconButton, Badge, Menu, MenuItem } from '@mui/material';
import './ClientCard.css';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

// 新着アイコンボタン
const NewNotificationButtonIcon = (props) => {
  const newComments = props.newComments;

  const [anchorEl, setAnchorEl] = useState(null); // メニューの表示位置を管理
  // メニューを開く処理
  const handleClick = (event) => {
    if (newComments.length > 0) {
      setAnchorEl(event.currentTarget); // クリックしたボタンを基準にメニューを表示
    }
  };

  // メニューを閉じる処理
  const handleClose = () => {
    setAnchorEl(null); // メニューを非表示
  };

  // メニューを選択
  const handleOnClickMenuItem = (card) => {
    props.callEditModal(card);
    setAnchorEl(null); // メニューを非表示
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Badge badgeContent={newComments.length} color="error">
          <NotificationsNoneOutlinedIcon style={{ fontSize: 26 }} />
        </Badge>
      </IconButton>
      {/* メニューの定義 */}
      <Menu
        anchorEl={anchorEl} // メニューの表示位置
        open={Boolean(anchorEl)} // メニューの開閉を管理
        onClose={handleClose} // メニューを閉じる処理
        anchorOrigin={{
          vertical: 'bottom', // メニューの表示位置をボタンの下に設定
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* メニュー項目（先頭10件まで） */}
        {newComments.slice(0, 10).map((x, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              handleOnClickMenuItem(x);
            }}
            className="p-1"
          >
            <div className="newcomments">
              {x.TITLE}
              <span>
                <div style={{ fontSize: '8pt' }}>
                  <b>{x.TAN_NAME}</b>さんがコメント
                  <span style={{ fontSize: '10pt' }} className="pl-2">
                    &nbsp;{x.INS_DATE}&nbsp;{x.INS_TIME}
                  </span>
                </div>
                <div className="newcomments-item">{x.CONTENT}</div>
              </span>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default NewNotificationButtonIcon;
