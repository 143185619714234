import { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { CellType, KeyAction, DataMap } from '@grapecity/wijmo.grid';

import '@grapecity/wijmo.styles/wijmo.css';

import { Button, Card, Form, Row, Col, MenuItem } from 'react-bootstrap';

import { useNavigate } from 'react-router-dom';
import { useRestApi } from 'context/RestApiContext';

import { MessageModal } from 'components/modal/MessageModal';
import SelectComponent from 'components/Input/SelectComponent';
import IconButtonCompnent from 'components/Input/IconButtonCompnent';

import QuoteBaseHistoryModal from 'views/MasterManagement/QuoteBaseHistoryModal';

import { toFloat, toInt } from 'utils/numberUtils';

function QuoteBaseEntry(props) {
  const navigate = useNavigate();
  const restApi = useRestApi();

  // グリッドとスクロール位置
  const flexGrid = useRef();
  const scrollPosition = useRef(0);

  // メッセージモーダル
  const [dialog, setDialog] = useState({});
  // 見積基盤履歴モーダルのパラメタ
  const [historyParams, setHistoryParams] = useState({});
  // 工事種コードマッピング
  const koujiMap = useRef(new DataMap([]));

  // モード（編集/確認）
  const [mode, setMode] = useState('edit');

  // 見積基盤全データ
  const baseQuotes = useRef({});

  // 登録明細
  const [editDetails, setEditDetails] = useState([]);

  // 確認明細
  const [confirmDetails, setConfirmDetails] = useState([]);

  // リロード用データ
  const [reload, setReload] = useState('');

  // 変更データ
  const updDataList = useRef([]);

  // 初期表示
  useEffect(() => {
    // 見積基盤の全データを取得
    restApi.get('/api/master/baseQuote/list', (data) => {
      const _baseQuotes = data.baseQuotes.map((b, i) => ({
        ...b,
        ORIGIN_INDEX: i + 1,
        SU: toInt(b.SU),
        TANKA: toInt(b.TANKA),
      }));

      // 明細データをクローン
      setEditDetails(JSON.parse(JSON.stringify(_baseQuotes)));

      // 変更前データを退避
      baseQuotes.current = _baseQuotes;
    });
  }, []);

  // 明細の変更時にスクロール位置を復元
  useEffect(() => {
    if (flexGrid.current?.control) {
      setTimeout(() => {
        scrollPosition.current = flexGrid.current.control.scrollPosition = scrollPosition.current;
      }, 200);
    }
  }, [editDetails]);

  // 登録明細の追加ボタン
  const handlenOnClickAdd = () => {
    let addData = {
      ORIGIN_INDEX: null,
    };

    // 更新データに追加
    updDataList.current.push(addData);

    // スクロール位置を保持
    scrollPosition.current = flexGrid.current.control.scrollPosition;
    setEditDetails([...editDetails, addData]);
  };

  // 明細の削除処理
  const handleOnClickDelete = (item, delFlg) => {
    item['DEL_FLG'] = delFlg;

    // スクロール位置を保持
    scrollPosition.current = flexGrid.current.control.scrollPosition;

    // 既存項目は削除フラグを立てる
    if (item.ORIGIN_INDEX != null) {
      appendUpdData(item, 'DEL_FLG');
      setEditDetails(editDetails.map((x) => (x == item ? item : x)));
    }
    // 新規の項目は削除
    else {
      removeUpdData(item);
      setEditDetails(editDetails.filter((x) => x != item));
    }
  };

  // 明細の履歴表示処理
  const handleOnClickHistory = (item) => {
    setHistoryParams({ baseNo: item.ID });
  };

  // 次へボタン押下処理
  const handleOnClickNext = () => {
    updDataList.current = updDataList.current.sort((a, b) =>
      a.KOUJI_CD > b.KOUJI_CD ? 1 : a.SORT > b.SORT ? 1 : -1
    );
    setMode('confirm');
    setConfirmDetails([...updDataList.current]);
  };

  // 戻るボタン押下処理
  const handleOnClickBack = () => {
    // 変更無しの場合はそのまま戻る
    if (!updDataList.current.length) {
      navigate(-1);
    }

    setDialog({
      type: 'confirm',
      message: `修正内容は破棄されます。よろしいですか？`,
      okCallBack: () => {
        navigate(-1);
      },
    });
  };

  // 保存ボタン押下処理
  const handleOnClickSave = () => {
    setDialog({
      type: 'confirm',
      message: `保存します。よろしいですか？`,
      okCallBack: () => {
        restApi.post('/api/master/baseQuote/save', { updList: updDataList.current }, (data) => {
          navigate(-1);
        });
      },
    });
  };

  // 貼り付け処理（編集開始処理をおこなう）
  const pastingCell = (s, args) => {
    beginningEdit(s, args);
  };
  // 編集開始処理
  const beginningEdit = (grid, args) => {
    var dataItem = grid.rows[args.row].dataItem;

    // 削除行は編集不可
    if (dataItem.DEL_FLG == '1') {
      args.cancel = true;
    }
  };

  // グリッドの選択時の処理（ime制御)
  const gridSelectionChanged = (grid, args) => {
    var col = grid.columns[args.col];
    // 数量、単価の場合はimeをoffにする
    var imeEnabled = !['SU', 'TANKA'].some((x) => x == col?.binding);
    setTimeout(function () {
      grid.imeEnabled = imeEnabled;
    }, 50);
  };

  // 貼り付け後の処理
  const pasted = (grid, args) => {
    cellEditEnded(grid, args);
  };
  // 編集終了処理
  const cellEditEnded = (grid, args) => {
    let dataItem = grid.rows[args.row].dataItem;
    let col = grid.columns[args.col];

    // 変更データを保持
    appendUpdData(dataItem, col['binding']);
  };

  // 変更データを削除
  const removeUpdData = (dataItem) => {
    updDataList.current = updDataList.current.filter(
      (u) => u.ORIGIN_INDEX != dataItem.ORIGIN_INDEX
    );
  };

  // 変更データを退避処理
  const appendUpdData = (dataItem, binding) => {
    // 追加データの修正は判定しない
    if (dataItem.ORIGIN_INDEX == null) {
      return;
    }

    if (!dataItem['updColumns']) {
      dataItem['updColumns'] = new Set();
    }

    // 変更列のオリジナルデータを取得取得
    let org = baseQuotes.current.find((b) => b.ORIGIN_INDEX == dataItem.ORIGIN_INDEX);

    // 変更が無い場合は取り除く
    if (org[binding] == dataItem[binding]) {
      dataItem['updColumns'].delete(binding);
    }
    // 変更あり、かつ、ない場合は追加
    else {
      dataItem['updColumns']?.add(binding);
    }

    // 修正データありの場合
    if (updDataList.current.some((u) => u.ORIGIN_INDEX === dataItem.ORIGIN_INDEX)) {
      if (dataItem['updColumns']?.size) {
        updDataList.current = updDataList.current.map((u) =>
          u.ORIGIN_INDEX == dataItem.ORIGIN_INDEX ? dataItem : u
        );
      }
      // 修正なしの場合は修正データから消す
      else {
        updDataList.current = updDataList.current.filter(
          (u) => u.ORIGIN_INDEX != dataItem.ORIGIN_INDEX
        );
      }
    }
    // 修正データなしの場合、
    // カラムに変更があった、もしくは、新規追加の場合は変更データに追加
    else if (dataItem['updColumns']?.size) {
      updDataList.current.push(dataItem);
    }

    // 画面の読み込み直し用にstateの変更
    setReload(new Date().getTime());
  };

  // グリッドのフォーマット処理
  const formatItem = (grid, e) => {
    if (e.panel.cellType == CellType.Cell) {
      var col = grid.columns[e.col];
      var dataItem = grid.rows[e.row].dataItem;

      if (col.binding) {
        // 削除データの場合は取り消し線を引く
        let isDel = dataItem.DEL_FLG == '1';
        e.cell.style.textDecorationLine = isDel ? 'line-through' : '';
        e.cell.style.textDecorationStyle = isDel ? 'double' : '';
        e.cell.style.textDecorationColor = isDel ? 'red' : '';

        // 変更列は青く表示
        let isUpd = dataItem.ORIGIN_INDEX == null || dataItem.updColumns?.has(col.binding);
        e.cell.style.color = isUpd ? 'blue' : '';
        e.cell.style.fontWeight = isUpd ? 'bold' : '';
        e.cell.style.background = isUpd ? '#DDEEFF' : '';
      }
    }
  };

  // IDのゼロパディング
  const zeroPaddingTemplate = (props) => {
    const isNumber = (value) => !isNaN(parseFloat(value)) && isFinite(value);

    if (!isNumber(props.item['BASE_NO'])) {
      return <span></span>;
    }

    // ゼロパディングの処理
    const value = props.item['BASE_NO'] || 0;
    const formattedValue = value.toString().padStart(3, '0');
    return <span>{formattedValue}</span>;
  };

  return (
    <>
      <Card className="m-0">
        <Card.Body>
          {mode == 'edit' ? (
            <>
              <Row className="pt-1">
                <Col className="text-right">
                  <Button
                    className="material-symbols-rounded cell-icon-sm btn-fill"
                    title="追加"
                    onClick={handlenOnClickAdd}
                  >
                    add
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            <>変更内容を確認のうえ、保存ボタンで登録してください。</>
          )}
          <Row>
            <Col
              className="pt-1 pl-3 table-scroll"
              style={{ height: mode == 'edit' ? 'calc(100vh - 216px)' : 'calc(100vh - 210px)' }}
            >
              <FlexGrid
                ref={flexGrid}
                imeEnabled={true}
                allowSorting={false}
                itemsSource={mode == 'edit' ? editDetails : confirmDetails}
                selectionMode={mode == 'edit' ? 'CellRange' : 'None'}
                headersVisibility={'Column'}
                formatItem={formatItem}
                beginningEdit={beginningEdit}
                cellEditEnded={cellEditEnded}
                pastingCell={pastingCell}
                pasted={pasted}
                keyActionTab={KeyAction.Cycle}
                autoGenerateColumns={false}
                selectionChanged={gridSelectionChanged}
                isReadOnly={mode == 'confirm'}
              >
                <FlexGridColumn binding="BASE_NO" header="ID" width={100}>
                  <FlexGridCellTemplate cellType="Cell" template={zeroPaddingTemplate} />
                </FlexGridColumn>
                <FlexGridColumn binding="CONTENT" header="商品" width={200}></FlexGridColumn>
                <FlexGridColumn binding="SIYOU" header="発注先" width={200}></FlexGridColumn>
                <FlexGridColumn
                  binding="SU"
                  header="数量"
                  dataType="Number"
                  isRequired={false}
                  format="n2"
                  width={100}
                  inputType={'tel'}
                ></FlexGridColumn>
                <FlexGridColumn binding="TANI" header="単位" width={50}></FlexGridColumn>
                <FlexGridColumn
                  binding="TANKA"
                  header="単価"
                  dataType="Number"
                  isRequired={false}
                  width={100}
                ></FlexGridColumn>
                <FlexGridColumn binding="BIKO" header="備考" width={'*'}></FlexGridColumn>
                {mode == 'edit' ? (
                  <FlexGridColumn binding="" header="　" width={28} isReadOnly={true}>
                    <FlexGridCellTemplate
                      cellType="Cell"
                      template={(cell) => (
                        <IconButtonCompnent
                          className="cell-icon-sm"
                          variant="warning"
                          title="履歴"
                          disabled={cell.item.ORIGIN_INDEX == null}
                          onClick={() => handleOnClickHistory(cell.item)}
                          icon="History"
                        ></IconButtonCompnent>
                      )}
                    />
                  </FlexGridColumn>
                ) : null}

                {mode == 'edit' ? (
                  <FlexGridColumn header="　" binding="" isReadOnly={true} width={28}>
                    <FlexGridCellTemplate
                      cellType="Cell"
                      template={(cell) =>
                        cell.item.DEL_FLG != '1' ? (
                          <IconButtonCompnent
                            className="cell-icon-sm"
                            variant="danger"
                            title="削除"
                            onClick={() => handleOnClickDelete(cell.item, '1')}
                            icon="Clear"
                          ></IconButtonCompnent>
                        ) : (
                          <IconButtonCompnent
                            className="cell-icon-sm"
                            variant="success"
                            title="元に戻す"
                            onClick={() => handleOnClickDelete(cell.item, '0')}
                            icon="Replay"
                          ></IconButtonCompnent>
                        )
                      }
                    />
                  </FlexGridColumn>
                ) : null}
              </FlexGrid>
            </Col>
          </Row>
          <Row className="pt-2">
            <Col>
              <Row>
                {mode == 'edit' ? (
                  <>
                    <Col className="d-flex align-items-end">
                      <Button
                        className="footer-button"
                        variant="secondary"
                        onClick={() => handleOnClickBack()}
                      >
                        {' '}
                        戻る
                      </Button>
                    </Col>
                    <Col className="d-flex align-items-end flex-row-reverse">
                      <Button
                        variant="success"
                        className="footer-button btn-fill"
                        disabled={!updDataList.current.length}
                        onClick={() => handleOnClickNext()}
                      >
                        次へ
                      </Button>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col className="d-flex align-items-end">
                      <Button
                        className="footer-button"
                        variant="secondary"
                        onClick={() => setMode('edit')}
                      >
                        {' '}
                        戻る
                      </Button>
                    </Col>
                    <Col className="d-flex align-items-end flex-row-reverse">
                      <Button
                        variant="primary"
                        className="btn-fill footer-button"
                        onClick={() => handleOnClickSave()}
                      >
                        保存
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {/** メッセージモーダル */}
      <MessageModal dialog={dialog} />
      {/** 見積もり基盤履歴モーダル */}
      <QuoteBaseHistoryModal params={historyParams} />
    </>
  );
}

export default QuoteBaseEntry;
