import CalendarComponent from 'components/Input/Calendar';
import NumberComponent from 'components/Input/NumberComponent';
import { Form } from 'react-bootstrap';
import 'assets/css/textcomponent.css';

// テキストコンポーネント
function TextComponent(props) {
  switch (props.type) {
    // 日付
    case 'date':
      return (
        // 入力不可の場合は通常のテキスト
        props.disabled ? (
          <Form.Control type="text" value={props.value} disabled={props.disabled} />
        ) : (
          <CalendarComponent
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
            selectionMode={props.selectionMode}
            error={props.error}
          />
        )
      );
    // テキストエリア
    case 'textarea':
      return (
        <Form.Control
          as={props.type}
          placeholder={props.placeholder}
          rows={props.rows}
          value={props.value}
          disabled={props.disabled}
          readOnly={props.readOnly}
          maxLength={props.maxLength}
          onChange={(e) => (props.onChange ? props.onChange(e.target) : null)}
        ></Form.Control>
      );
    // 数値
    case 'number':
      return (
        <NumberComponent
          className={props.className}
          fmt={props.fmt}
          value={props.value ?? 0}
          disabled={props.disabled}
          onChange={props.onChange}
          max={props.max}
        ></NumberComponent>
      );
    // テキスト
    case 'text':
    default:
      return (
        <Form.Control
          className={props.className}
          placeholder={props.placeholder}
          type={props.type}
          value={props.value}
          disabled={props.disabled}
          onChange={(e) => (props.onChange ? props.onChange(e.target) : null)}
          maxLength={props.maxLength}
          style={{
            background: props.error ? 'lightpink' : props.disabled ? '#eeeeee' : '',
            color: props.disabled ? '#444444' : '',
          }}
        ></Form.Control>
      );
  }
}

export default TextComponent;
