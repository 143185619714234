import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import { MasterContextProvider } from 'context/MasterContext';
import AdminLayout from 'layouts/Admin';
import routes from 'routes.js';

export const AuthenticatedGuard = () => {
  const auth = useAuth();
  const location = useLocation();

  // 認証していない場合はログイン画面に戻す
  if (!auth.auth.isAuthed) {
    return <Navigate replace to="/login" />;
  }

  // ルートに登録されていなパスの場合はダッシュボードを表示
  if (!routes.some((x) => x.layout + x.path == location.pathname)) {
    return <Navigate replace to={auth.getDefaultPage()} />;
  }
  return (
    <MasterContextProvider>
      <AdminLayout />
    </MasterContextProvider>
  );
};
