import React, { useState, useEffect } from 'react';
import * as wjInput from '@grapecity/wijmo.react.input';

function NumberComponent(props) {
  const [textValue, setTextValue] = useState(props.value);
  useEffect(() => {
    props.onChange && props.onChange({ value: textValue });
  }, [textValue]);
  useEffect(() => {
    setTextValue(props.value);
  }, [props.value]);

  const valueChanged = (s) => {
    setTextValue(s.value);
  };

  return (
    <wjInput.InputNumber
      className={props.className}
      value={textValue}
      format={props.fmt ? props.fmt : 'n0'}
      isDisabled={props.disabled}
      max={props.max}
      valueChanged={valueChanged}
    />
  );
}
export default NumberComponent;
