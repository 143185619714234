import { useState, useEffect } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { useRestApi } from 'context/RestApiContext';
import { useMaster } from 'context/MasterContext';
import { MessageModal } from 'components/modal/MessageModal';
import './ClientCard.css';
import './ClientCardInquiry.css';
import NyukinList from 'views/ClientCards/NyukinList';

import TextComponent from 'components/Input/TextComponent';
import SelectComponent from 'components/Input/SelectComponent';
import { KoujiTagComponent } from 'components/Input/KoujiTagComponent';
import { KoujiTagSelectComponent } from 'components/Input/KoujiTagSelectComponent';

import { getAddress } from 'utils/addresUtils';
import { ClientRanks, Lines } from 'constants/constants';
import { checkRequired, checkNumber } from 'utils/validationUtils';

import { format } from 'date-fns';
import { toDate } from 'utils/dateUtils';

// 契約後情報の編集画面
function ClientCardAfterContractModal(props) {
  const master = useMaster();
  const restApi = useRestApi();

  const [show, setShow] = useState(false);
  // 顧客情報
  const [client, setClient] = useState({});

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});
  // 取引先リスト
  const [toriList, setToriList] = useState([]);
  // 入金一覧
  const [nyukins, setNyukins] = useState([]);
  // エラーフィールド
  const [errorFileds, setErrorFileds] = useState([]);
  // 売上情報
  const [uriage, setUriage] = useState([
    {
      KEI_KIN: 0,
      ZOUGEN_KIN: 0,
      SEIKSAN_KIN: 0,
      NYUKIN_TOTAL: 0,
      KAN_KIN: 0,
      SEIKYU_DATE: null,
      SEIKYU_NYUKIN_DATE: null,
    },
  ]);

  // 工事内容タグ
  const [koujiTags, setKoujiTags] = useState([]);
  // 工事タグの追加
  const addKoujiTag = (tagCodes) => {
    setKoujiTags([...koujiTags, ...tagCodes.filter((x) => !koujiTags.some((k) => k == x))]);
  };
  // 工事タグの削除
  const deleteKoujiTag = (tagCode) => {
    setKoujiTags(koujiTags.filter((x) => x != tagCode));
  };

  // 編集処理
  const handleOnChange = (prop) => (target) => {
    setClient({ ...client, [prop]: target?.value ?? target?.target.value });
  };

  // 編集処理(チェックボックス)
  const handleOnChangeCheck = (prop) => (event) => {
    setClient({ ...client, [prop]: event.target?.checked ? 1 : 0 });
  };

  // 初期表示処理
  useEffect(() => {
    // 取引先を取得
    restApi.get('/api/master/tori', (data) => {
      setToriList(data);
    });
  }, []);

  useEffect(() => {
    if (props?.params?.show) {
      const _client = props.params.client;
      setClient({ ..._client });
      setKoujiTags(props.params?.koujiTags);
      setNyukins([...props.params?.nyukins]);
      setUriage([
        {
          KEI_KIN: _client.KEI_KIN,
          ZOUGEN_KIN: _client.ZOUGEN_KIN,
          SEIKSAN_KIN: _client.SEIKSAN_KIN,
          NYUKIN_TOTAL: _client.NYUKIN_TOTAL,
          KAN_KIN: _client.KAN_KIN,
          SEIKYU_DATE: toDate(_client.SEIKYU_DATE),
          SEIKYU_NYUKIN_DATE: toDate(_client.SEIKYU_NYUKIN_DATE),
        },
      ]);
      setShow(true);
    }
  }, [props.params]);

  // 郵便番号検索
  const searchZip = (prefix) => {
    getAddress(
      restApi,
      client[prefix + 'POST_CODE'],
      (json) => {
        let result = json['results'][0];
        setClient({
          ...client,
          [prefix + 'PREF']: result['address1'],
          [prefix + 'CITY']: result['address2'] + result['address3'],
        });
      },
      setDialog
    );
  };

  // 入力チェック
  const validate = () => {
    let errors = [];
    checkNumber(client.DM_NO, 'DMナンバー', 'DM_NO', errors);
    nyukins.forEach((n) => {
      checkRequired(n.NYUKIN_DATE, '入金日', `NYUKIN_DATE_${n.NO}`, errors);
      checkRequired(n.NYUKIN_KIN, '入金額', `NYUKIN_KIN_${n.NO}`, errors);
    });

    setErrorFileds(errors.flatMap((x) => x.fields));

    return errors.map((x) => x.msg);
  };

  // 保存ボタンのクリック処理
  const handleOnClickSave = () => {
    var error = validate();
    if (error.length > 0) {
      setDialog({
        type: 'error',
        message: error,
      });
      return;
    }

    const _nyukins = nyukins.map((x) => ({
      ...x,
      NYUKIN_DATE: x.NYUKIN_DATE ? format(x.NYUKIN_DATE, 'yyyy/MM/dd') : null,
    }));

    restApi.post(
      `/api/client/upd`,
      {
        CLIENT_CD: client.CLIENT_CD,
        DM_NO: client.DM_NO,
        PRINT_FLG: client.PRINT_FLG,
        NAME_1: client.NAME_1,
        NAME_KANA_1: client.NAME_KANA_1,
        NAME_2: client.NAME_2,
        NAME_KANA_2: client.NAME_KANA_2,
        BUILD_POST_CODE: client.BUILD_POST_CODE,
        BUILD_CITY: client.BUILD_CITY,
        TEL1: client.TEL1,
        TEL2: client.TEL2,
        TEL3: client.TEL3,
        LANK: client.LANK,
        LINE: client.LINE,
        KOUJI_TORI_CD: client.KOUJI_TORI_CD,
        ARUMI_KOUJI_TORI_CD: client.ARUMI_KOUJI_TORI_CD,
        PLANT_KOUJI_TORI_CD: client.PLANT_KOUJI_TORI_CD,
        OTHER_KOUJI_TORI_CD: client.OTHER_KOUJI_TORI_CD,
        ST_DATE: client.ST_DATE ? client.ST_DATE : null,
        ED_DATE: client.ED_DATE ? client.ED_DATE : null,
        WARRANTY_RELEASE_FLG: client.WARRANTY_RELEASE_FLG,
        WARRANTY_RELEASE_DATE: client.WARRANTY_RELEASE_DATE,

        KEI_KIN: uriage[0].KEI_KIN,
        ZOUGEN_KIN: uriage[0].ZOUGEN_KIN,
        SEIKSAN_KIN: uriage[0].SEIKSAN_KIN,
        NYUKIN_TOTAL: uriage[0].NYUKIN_TOTAL,
        KAN_KIN: uriage[0].KAN_KIN,
        SEIKYU_DATE: uriage[0].SEIKYU_DATE ? format(uriage[0].SEIKYU_DATE, 'yyyy/MM/dd') : null,
        SEIKYU_NYUKIN_DATE: uriage[0].SEIKYU_NYUKIN_DATE
          ? format(uriage[0].SEIKYU_NYUKIN_DATE, 'yyyy/MM/dd')
          : null,
        koujiTags: koujiTags,
        nyukins: _nyukins,
      },
      (data) => {
        props.params.searchCard();
        setShow(false);
      }
    );
  };

  // 閉じるボタンのクリック処理
  const handleClose = () => {
    props.params.searchCard();
    setShow(false);
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '1120px',
              backgroundColor: '#f1f2f4',
              marginTop: props?.params?.mode == 'add' ? '20px' : '60px',
            },
          },
        }}
        disableEnforceFocus={true} // フォーカス制御を無効にする
        open={show}
      >
        <DialogTitle className="p-3">
          <>契約後情報設定</>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="px-3 py-3">
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <Row>
                <Col md={4}>
                  <label className="client-card-label">DMナンバー</label>
                  <TextComponent
                    type="text"
                    // 年間管理が設定されているDMナンバーは修正不可
                    disabled={props?.params?.yearManages?.length > 0}
                    value={client.DM_NO}
                    onChange={handleOnChange('DM_NO')}
                    error={errorFileds.some((x) => x == 'DM_NO')}
                  />
                </Col>
                <Col md={4}>
                  <label className="client-card-label">宛名印刷</label>
                  <SelectComponent value={client.PRINT_FLG} onChange={handleOnChange('PRINT_FLG')}>
                    <option value={1}>印刷する</option>
                    <option value={0}>印刷しない</option>
                  </SelectComponent>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label className="client-card-label">顧客名１</label>
                  <TextComponent
                    type="text"
                    value={client.NAME_1}
                    onChange={handleOnChange('NAME_1')}
                  />
                </Col>
                <Col md={5}>
                  <label className="client-card-label">顧客名かな１</label>
                  <TextComponent
                    type="text"
                    value={client.NAME_KANA_1}
                    onChange={handleOnChange('NAME_KANA_1')}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label className="client-card-label">顧客名２</label>
                  <TextComponent
                    type="text"
                    value={client.NAME_2}
                    onChange={handleOnChange('NAME_2')}
                  />
                </Col>
                <Col md={5}>
                  <label className="client-card-label">顧客名かな２</label>
                  <TextComponent
                    type="text"
                    value={client.NAME_KANA_2}
                    onChange={handleOnChange('NAME_KANA_2')}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="client-card-label">郵便番号</label>
                  <span style={{ display: 'flex' }}>
                    <TextComponent
                      type="tel"
                      value={client.BUILD_POST_CODE}
                      onChange={handleOnChange('BUILD_POST_CODE')}
                    ></TextComponent>
                    <Button
                      size="sm"
                      variant="default"
                      className="post-no"
                      onClick={() => {
                        searchZip('BUILD_');
                      }}
                    >
                      検索
                    </Button>
                  </span>
                </Col>
                <Col md={7}>
                  <label className="client-card-label">市町村以下</label>
                  <TextComponent
                    type="text"
                    value={client.BUILD_CITY}
                    onChange={handleOnChange('BUILD_CITY')}
                  ></TextComponent>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="client-card-label">電話（ご主人）</label>
                  <TextComponent
                    type="tel"
                    value={client.TEL1}
                    onChange={handleOnChange('TEL1')}
                  ></TextComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">電話（奥様）</label>
                  <TextComponent
                    type="tel"
                    value={client.TEL2}
                    onChange={handleOnChange('TEL2')}
                  ></TextComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">電話（固定）</label>
                  <TextComponent
                    type="tel"
                    value={client.TEL3}
                    onChange={handleOnChange('TEL3')}
                  ></TextComponent>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={12}>
                  <label className="client-card-label">工事内容タグ</label>
                  <br />
                  {koujiTags.map((x) => (
                    <KoujiTagComponent
                      labels={master.label}
                      tagCode={x}
                      onDelete={deleteKoujiTag}
                    />
                  ))}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={8}>
                  <KoujiTagSelectComponent labels={master.label} callAddTag={addKoujiTag} />
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <label className="client-card-label">お客様ランク</label>
                  <SelectComponent value={client.LANK} onChange={handleOnChange('LANK')}>
                    {ClientRanks.map((x, i) => (
                      <option key={i} value={x.key}>
                        {x.value}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
                <Col md={2}>
                  <label className="client-card-label">LINE登録</label>
                  <SelectComponent value={client.LINE} onChange={handleOnChange('LINE')}>
                    {Lines.map((x, i) => (
                      <option key={i} value={x.key}>
                        {x.value}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="client-card-label">工事全般</label>
                  <SelectComponent
                    value={client.KOUJI_TORI_CD}
                    onChange={handleOnChange('KOUJI_TORI_CD')}
                  >
                    <option key="-1" value={0}>
                      {'　'}
                    </option>
                    {toriList.map((x, i) => (
                      <option key={i} value={x.TRI_CD}>
                        {x.RYAKU}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">アルミ工事</label>
                  <SelectComponent
                    value={client.ARUMI_KOUJI_TORI_CD}
                    onChange={handleOnChange('ARUMI_KOUJI_TORI_CD')}
                  >
                    <option key="-1" value={0}>
                      {'　'}
                    </option>
                    {toriList.map((x, i) => (
                      <option key={i} value={x.TRI_CD}>
                        {x.RYAKU}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">植栽工事</label>
                  <SelectComponent
                    value={client.PLANT_KOUJI_TORI_CD}
                    onChange={handleOnChange('PLANT_KOUJI_TORI_CD')}
                  >
                    <option key="-1" value={0}>
                      {'　'}
                    </option>
                    {toriList.map((x, i) => (
                      <option key={i} value={x.TRI_CD}>
                        {x.RYAKU}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>

                <Col md={3}>
                  <label className="client-card-label">その他</label>
                  <SelectComponent
                    value={client.OTHER_KOUJI_TORI_CD}
                    onChange={handleOnChange('OTHER_KOUJI_TORI_CD')}
                  >
                    <option key="-1" value={0}>
                      {'　'}
                    </option>
                    {toriList.map((x, i) => (
                      <option key={i} value={x.TRI_CD}>
                        {x.RYAKU}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="client-card-label">着工日</label>
                  <TextComponent
                    type="date"
                    value={client.ST_DATE}
                    onChange={handleOnChange('ST_DATE')}
                  ></TextComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">工事完工日</label>
                  <TextComponent
                    type="date"
                    value={client.ED_DATE}
                    onChange={handleOnChange('ED_DATE')}
                  ></TextComponent>
                </Col>
              </Row>
              {/* 入金・売上情報 */}
              <Row>
                <Col md="12">
                  <NyukinList
                    uriage={uriage}
                    setUriage={setUriage}
                    nyukins={nyukins}
                    setNyukins={setNyukins}
                  ></NyukinList>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={3}>
                  <label className="client-card-label">保証書発行</label>
                  <div style={{ display: 'flex' }}>
                    <input
                      className="ml-3 mr-3"
                      style={{ transform: 'scale(1.5)' }}
                      type="checkbox"
                      checked={client.WARRANTY_RELEASE_FLG == 1}
                      onChange={handleOnChangeCheck('WARRANTY_RELEASE_FLG')}
                    ></input>
                    <TextComponent
                      type="date"
                      disabled={client.WARRANTY_RELEASE_FLG == 0}
                      value={client.WARRANTY_RELEASE_DATE}
                      onChange={handleOnChange('WARRANTY_RELEASE_DATE')}
                    ></TextComponent>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button
            variant="primary"
            className="btn-fill  ml-4 mr-2"
            onClick={handleOnClickSave}
            style={{ flexGrow: 1 }}
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
      {/* メッセージダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}

export default ClientCardAfterContractModal;
