import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { FlexGrid, FlexGridColumn, FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import { InputDate } from '@grapecity/wijmo.input';
import { KeyAction } from '@grapecity/wijmo.grid';

import { MessageModal } from 'components/modal/MessageModal';
import './ClientCard.css';
import './ClientCardInquiry.css';
import { toFloat, toInt } from 'utils/numberUtils';

// 入金一覧
const NyukinList = (props) => {
  const flexGridNyukin = useRef();
  // 売上情報
  const uriage = props.uriage;
  const setUriage = props.setUriage;

  // 入金一覧
  const nyukins = props.nyukins;
  const setNyukins = props.setNyukins;

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // 追加ボタン
  const handleClickAdd = () => {
    setNyukins([
      ...nyukins,
      {
        NO: nyukins.length + 1,
        NYUKIN_DATE: null,
        NYUKIN_KIN: 0,
      },
    ]);
  };

  // 削除ボタン押下
  const handleClickDelete = (item) => {
    const _nyukins = nyukins.filter((x) => x != item).map((x, i) => ({ ...x, NO: i + 1 }));
    setNyukins(_nyukins);
    calcUriage(_nyukins);
  };

  // 売上情報の計算処理
  const calcUriage = (details) => {
    // 入金額の合計
    let nyukinTotal = details.reduce((a, x) => a + toFloat(x['NYUKIN_KIN']), 0);
    // 最後の入金日
    let lastNyukin = null;
    details.forEach((x) => {
      if (lastNyukin == null || lastNyukin < x.NYUKIN_DATE) {
        lastNyukin = x.NYUKIN_DATE;
      }
    });

    // 売上情報を反映
    setUriage((prev) => [
      {
        KEI_KIN: prev[0].KEI_KIN,
        ZOUGEN_KIN: prev[0].ZOUGEN_KIN,
        SEIKSAN_KIN: prev[0].SEIKSAN_KIN,
        NYUKIN_TOTAL: nyukinTotal,
        KAN_KIN:
          prev[0].SEIKSAN_KIN -
          details
            .filter((x) => {
              return !prev[0].SEIKYU_DATE || !x.NYUKIN_DATE || x.NYUKIN_DATE < prev[0].SEIKYU_DATE;
            })
            .reduce((a, x) => a + toFloat(x['NYUKIN_KIN']), 0),
        SEIKYU_DATE: prev[0].SEIKYU_DATE,
        SEIKYU_NYUKIN_DATE:
          lastNyukin && prev[0].SEIKYU_DATE && lastNyukin >= prev[0].SEIKYU_DATE
            ? lastNyukin
            : null,
      },
    ]);
  };

  // グリッドの初期化処理
  const handleInitialized = useCallback((flexGrid) => {
    flexGrid.columns.forEach((col) => {
      // 入金日(カレンダ選択)
      if (
        col.binding === 'NYUKIN_DATE' ||
        col.binding === 'SEIKYU_NYUKIN_DATE' ||
        col.binding === 'SEIKYU_DATE'
      ) {
        col.format = 'yyyy/MM/dd';
        const inputDate = new InputDate(document.createElement('div'), {
          format: 'yyyy/MM/dd',
          isRequired: false,
        });
        inputDate.hostElement.classList.add('grid-selector');
        col.editor = inputDate;
      }
    });
  }, []);

  // 入金情報の貼り付け後の処理
  const pastedNyukin = (grid, args) => {
    cellEditEndedNyukin(grid, args);
  };
  // 入金情報の編集終了処理
  const cellEditEndedNyukin = (grid, args) => {
    var col = grid.columns[args.col];

    // 入金額、入金日の場合
    if (col['binding'] == 'NYUKIN_KIN' || col['binding'] == 'NYUKIN_DATE') {
      // 合計を計算
      calcUriage(grid.rows.map((x) => x.dataItem));
    }
  };

  // 売上情報の貼り付け後の処理
  const pastedUriage = (grid, args) => {
    cellEditEnded(grid, args);
  };

  // 売上情報の編集終了処理
  const cellEditEndedUriage = (grid, args) => {
    var dataItem = grid.rows[args.row].dataItem;
    var col = grid.columns[args.col];

    // 増減工事、請求日の変更の場合
    if (col['binding'] == 'ZOUGEN_KIN' || col['binding'] == 'SEIKYU_DATE') {
      let seisanKin = toFloat(dataItem['KEI_KIN']) + toFloat(dataItem['ZOUGEN_KIN']);
      let seikyuDate = dataItem['SEIKYU_DATE'];

      let _nyukins = flexGridNyukin.current.control.rows.map((x) => x.dataItem);
      let nyukingaku = _nyukins
        .filter((x) => {
          return !seikyuDate || !x.NYUKIN_DATE || x.NYUKIN_DATE < seikyuDate;
        })
        .reduce((a, x) => a + toFloat(x.NYUKIN_KIN), 0);
      let kanKin = seisanKin - nyukingaku;

      // 最後の入金日
      let lastNyukin = null;
      _nyukins.forEach((x) => {
        if (lastNyukin == null || lastNyukin < x.NYUKIN_DATE) {
          lastNyukin = x.NYUKIN_DATE;
        }
      });

      dataItem['SEIKSAN_KIN'] = seisanKin;
      dataItem['KAN_KIN'] = kanKin;
      // 請求日より後の入金の場合に最終入金日を表示
      dataItem['SEIKYU_NYUKIN_DATE'] =
        lastNyukin && seikyuDate && lastNyukin >= seikyuDate ? lastNyukin : null;
      grid.beginUpdate();
      grid.setCellData(args.row, 2, dataItem['SEIKSAN_KIN'], true);
      grid.setCellData(args.row, 4, dataItem['KAN_KIN'], true);
      grid.setCellData(args.row, 6, dataItem['SEIKYU_NYUKIN_DATE'], true);
      grid.endUpdate();
    }
  };

  return (
    <>
      <Row className="mt-2">
        <Col md="5">
          <Row>
            <Col md="4">
              <label className="client-card-label">入金情報</label>
            </Col>
            <Col md="8" className="text-right">
              <Button
                className="material-symbols-rounded cell-icon-sm btn-fill"
                title="追加"
                disabled={nyukins.length >= 8}
                onClick={handleClickAdd}
              >
                add
              </Button>
            </Col>
          </Row>

          <FlexGrid
            ref={flexGridNyukin}
            allowSorting={false}
            itemsSource={nyukins}
            headersVisibility={'Column'}
            initialized={handleInitialized}
            keyActionTab={KeyAction.Cycle}
            pasted={pastedNyukin}
            cellEditEnded={cellEditEndedNyukin}
          >
            <FlexGridColumn
              binding="NO"
              header="No"
              width={62}
              format="d"
              isReadOnly={true}
              cssClass="cell-readonly"
            ></FlexGridColumn>
            <FlexGridColumn
              binding="NYUKIN_DATE"
              header="入金日"
              format="yyyy/MM/dd"
              width={'1*'}
            ></FlexGridColumn>
            <FlexGridColumn
              binding="NYUKIN_KIN"
              header="入金額"
              width={'1*'}
              format="n0"
              dataType="Number"
              isRequired={false}
            ></FlexGridColumn>
            <FlexGridColumn header="" binding="" isReadOnly={true} width={28}>
              <FlexGridCellTemplate
                cellType="Cell"
                template={(cell) => (
                  <Button
                    className="material-symbols-rounded btn-fill icon-button-sm"
                    variant="danger"
                    onClick={() => handleClickDelete(cell.item)}
                  >
                    Delete
                  </Button>
                )}
              />
            </FlexGridColumn>
          </FlexGrid>
          <MessageModal dialog={dialog}></MessageModal>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <label className="client-card-label">売上情報</label>
        </Col>
      </Row>
      <Row>
        <Col md="10">
          <FlexGrid
            allowSorting={false}
            itemsSource={uriage}
            headersVisibility={'Column'}
            initialized={handleInitialized}
            keyActionTab={KeyAction.Cycle}
            pasted={pastedUriage}
            cellEditEnded={cellEditEndedUriage}
          >
            <FlexGridColumn
              binding="KEI_KIN"
              header="請負工事金額"
              width={'1*'}
              format="n0"
              isReadOnly={true}
              cssClass="cell-readonly"
            ></FlexGridColumn>
            <FlexGridColumn
              binding="ZOUGEN_KIN"
              header="増減工事"
              width={'1*'}
              format="n0"
            ></FlexGridColumn>
            <FlexGridColumn
              binding="SEIKSAN_KIN"
              header="精算金額"
              width={'1*'}
              format="n0"
              isReadOnly={true}
              cssClass="cell-readonly"
            ></FlexGridColumn>
            <FlexGridColumn
              binding="NYUKIN_TOTAL"
              header="入金額合計"
              width={'1*'}
              format="n0"
              isReadOnly={true}
              cssClass="cell-readonly"
            ></FlexGridColumn>
            <FlexGridColumn
              binding="KAN_KIN"
              header="最終請求金額"
              width={'1*'}
              format="n0"
              isReadOnly={true}
              cssClass="cell-readonly"
            ></FlexGridColumn>
            <FlexGridColumn
              binding="SEIKYU_DATE"
              header="請求日"
              width={'1*'}
              format="yyyy/MM/dd"
              isRequired={false}
            ></FlexGridColumn>
            <FlexGridColumn
              binding="SEIKYU_NYUKIN_DATE"
              header="最終請求入金日"
              width={'1*'}
              format="yyyy/MM/dd"
              isReadOnly={true}
              cssClass="cell-readonly"
            ></FlexGridColumn>
          </FlexGrid>
        </Col>
      </Row>
    </>
  );
};

export default NyukinList;
