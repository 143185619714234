import { useState, useEffect, useCallback } from 'react';
import { Snackbar, Alert } from '@mui/material';

/** トーストメッセージ */
export const ToastMessage = (props) => {
  const [show, setShow] = useState(false);

  const toast = props.toast;
  useEffect(() => {
    if (toast.message) {
      setShow(true);
    }
  }, [toast]);

  return (
    <Snackbar
      open={show}
      autoHideDuration={3000}
      onClose={() => {
        setShow(false);
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={() => {}}
    >
      <Alert severity={'info'} style={{ fontSize: '13pt' }}>
        {toast.message}
      </Alert>
    </Snackbar>
  );
};
