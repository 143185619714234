import { Form } from 'react-bootstrap';

// コンボボックスコンポーネント
function SelectComponent(props) {
  return (
    <Form.Select
      className={`form-control ${props.className}`}
      value={props.value}
      disabled={props.disabled}
      onChange={(e) => (props.onChange ? props.onChange(e.target) : null)}
      style={{
        background: props.error ? 'lightpink' : props.disabled ? '#eeeeee' : '',
      }}
    >
      {props.children}
    </Form.Select>
  );
}

export default SelectComponent;
