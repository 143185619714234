import { CellRange, DataMap } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { CollectionView } from '@grapecity/wijmo';
import { CellType } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import TextComponent from 'components/Input/TextComponent';
import { useAuth } from 'context/AuthContext';
import { useMaster } from 'context/MasterContext';
import { useRestApi } from 'context/RestApiContext';
import { MessageModal } from 'components/modal/MessageModal';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { KoujiTagComponent } from 'components/Input/KoujiTagComponent';
import { KoujiTagSelectComponent } from 'components/Input/KoujiTagSelectComponent';
import SelectComponent from 'components/Input/SelectComponent';
import ClientCardEditModal from 'views/ClientCards/ClientCardEditModal';
import AtenaPrintModal from 'views/ClientManage/AtenaPrintModal';
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import './ClientManage.css';
import { toDate } from 'utils/dateUtils';

import { ClientRanks } from 'constants/constants';

const ClientManage = (props) => {
  const auth = useAuth();

  const restApi = useRestApi();

  var clientRankMap = new DataMap([...ClientRanks, { key: 0, value: '' }], 'key', 'value');

  // グリッド
  const flexGrid = useRef();

  // スクロール位置
  const scrollPosition = useRef(0);

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // 顧客一覧情報
  const [clients, setClients] = useState(new CollectionView([]));

  // 一覧の件数
  const [count, setCount] = useState(0);

  // 顧客検索条件
  const [searchCondition, setSearchCondition] = useState({
    NAME: '',
    DM_CHOFUKU: 0,
    DM_START: '',
    DM_END: '',
  });

  // 工事内容タグ
  const [koujiTags, setKoujiTags] = useState([]);
  // 工事タグの追加
  const addKoujiTag = (tagCodes) => {
    setKoujiTags([...koujiTags, ...tagCodes.filter((x) => !koujiTags.some((k) => k == x))]);
  };
  // 工事タグの削除
  const deleteKoujiTag = (tagCode) => {
    setKoujiTags(koujiTags.filter((x) => x != tagCode));
  };

  // マスタ情報
  const master = useMaster();

  // 顧客情報を画面用の表示データに変換
  const mapToClient = (x) => {
    return {
      CARD_ID: x.CARD_ID,
      CLIENT_CD: x.CLIENT_CD,
      DM_NO: x.DM_NO,
      LANK: x.LANK,
      NAME_1: x.NAME_1,
      TITLE: x.TITLE,
      KOUJI_MEI: x.KOUJI_MEI,
      ZIP: `〒${x.BUILD_POST_CODE}`,
      BUILD_CITY: x.BUILD_CITY,
      TEL: !x.TEL1 ? x.TEL2 : x.TEL1,
      ED_DATE: toDate(x.ED_DATE),
      SEIKYU_DATE: toDate(x.SEIKYU_DATE),
      ARCHIVE_FLG: x.ARCHIVE_FLG,
    };
  };

  // 検索処理
  const searchClients = (condition) => {
    restApi.post('/api/client/list', { condition: condition, koujiTags: koujiTags }, (data) => {
      let sorted = [];

      // 一番古いデータを表示の場合
      if (condition.DM_CHOFUKU == 1) {
        let uniqueDMNos = new Set();
        // DM_NO
        sorted = data
          .map((x) => mapToClient(x))
          .sort((a, b) => {
            if (a.DM_NO == b.DM_NO) {
              const aValue = a.SEIKYU_DATE;
              const bValue = b.SEIKYU_DATE;
              if (!aValue) return 1; // aが日付変換できなければaを後ろに
              if (!bValue) return -1; // bが日付変換できなければbを後ろに
              return bValue < aValue ? 1 : -1;
            }

            return a.DM_NO > b.DM_NO ? -1 : 1;
          }) // DMナンバーの重複を排除
          .filter((client) => {
            if (!client.DM_NO || uniqueDMNos.has(client.DM_NO)) {
              return false;
            }
            uniqueDMNos.add(client.DM_NO);
            return true;
          })
          .sort((a, b) => {
            const aValue = a.SEIKYU_DATE;
            const bValue = b.SEIKYU_DATE;
            // aValue または bValue が日付に変換できなかった場合、先頭に移動する
            if (!aValue) return -1; // aが日付変換できなければaを前に
            if (!bValue) return 1; // bが日付変換できなければbを前に
            return bValue < aValue ? -1 : 1;
          });
        // 一番新しいデータを表示の場合
      } else if (condition.DM_CHOFUKU == 2) {
        let uniqueDMNos = new Set();
        // DM_NO
        sorted = data
          .map((x) => mapToClient(x))
          .sort((a, b) => {
            if (a.DM_NO == b.DM_NO) {
              const aValue = a.SEIKYU_DATE;
              const bValue = b.SEIKYU_DATE;
              if (!aValue) return 1; // aが日付変換できなければaを後ろに
              if (!bValue) return -1; // bが日付変換できなければbを後ろに
              return bValue > aValue ? 1 : -1;
            }

            return a.DM_NO > b.DM_NO ? -1 : 1;
          }) // DMナンバーの重複を排除
          .filter((client) => {
            if (!client.DM_NO || uniqueDMNos.has(client.DM_NO)) {
              return false;
            }
            uniqueDMNos.add(client.DM_NO);
            return true;
          })
          .sort((a, b) => {
            const aValue = a.ED_DATE;
            const bValue = b.ED_DATE;
            // aValue または bValue が日付に変換できなかった場合、先頭に移動する
            if (!aValue) return -1; // aが日付変換できなければaを前に
            if (!bValue) return 1; // bが日付変換できなければbを前に
            return bValue < aValue ? -1 : 1;
          });
      } else {
        // 請求日でソート
        sorted = data
          .map((x) => mapToClient(x))
          .sort((a, b) => {
            const aValue = a.SEIKYU_DATE;
            const bValue = b.SEIKYU_DATE;
            // aValue または bValue が日付に変換できなかった場合、先頭に移動する
            if (!aValue) return -1; // aが日付変換できなければaを前に
            if (!bValue) return 1; // bが日付変換できなければbを前に
            return bValue < aValue ? -1 : 1;
          });
      }
      const collectionView = new CollectionView(sorted);
      setClients(collectionView);
      setCount(sorted.length);
    });
  };

  // 初期表示処理
  useEffect(() => {
    // 検索
    searchClients(searchCondition);
  }, [koujiTags]);

  // 対象の顧客番号の行を更新
  const updateRow = (targetClientCd) => {
    if (targetClientCd) {
      const index = clients.items.indexOf(
        clients.items.find((c) => c['CLIENT_CD'] == targetClientCd)
      );
      if (index >= 0) {
        restApi.get(`/api/client/${targetClientCd}`, (data) => {
          Object.assign(clients.items[index], mapToClient(data));
        });
      }
    }
  };

  // 検索ボタン
  const handleOnClickSearch = () => {
    searchClients(searchCondition);
  };

  // 編集処理
  const handleChange = (prop) => (target) => {
    const val = target?.value ?? target?.target.value;
    setSearchCondition({ ...searchCondition, [prop]: val });
    if (prop === 'DM_CHOFUKU') {
      searchClients({ ...searchCondition, DM_CHOFUKU: val });
    }
  };

  // 宛名印刷
  const [atenaPringModalParam, setAtenaPringModalParam] = useState({});
  const handleOnClickAtenaPrint = () => {
    const grid = flexGrid.current.control; // フィルタを考慮した項目を取得
    let items = grid.collectionView.items;
    setAtenaPringModalParam({
      show: true,
      clients: items,
    });
  };

  // 案件に表示
  const hendleClickUnArchive = (item) => {
    setDialog({
      type: 'confirm',
      message: `カードを案件全般に表示します。よろしいですか？`,
      okCallBack: () => {
        restApi.post(`/api/cards/unarchive/${item.CARD_ID}`, { ARCHIVE_FLG: '1' }, (_) => {
          const target = clients.items.find((c) => c.CARD_ID == item.CARD_ID);
          if (target) {
            target.ARCHIVE_FLG = '0';
          }
        });
      },
    });
  };

  // カード編集モーダルのパラメタ
  const [cardEditModalparam, setCardEditModalparam] = useState({});
  // カード編集モーダルの呼び出し処理
  const callCardEditModal = (card) => {
    // スクロール位置を保持
    scrollPosition.current = flexGrid.current.control.scrollPosition;

    setCardEditModalparam({
      card: card,
    });
  };

  // フィルタの変更処理
  const filterApplied = (s, e) => {
    let grid = s.grid;
    setCount(grid?.collectionView?.items.length);
  };

  return (
    <>
      <Card className="m-0 pl-3 pt-1  table-container">
        <Row>
          <Col className="pr-1" md="2">
            <label>DMナンバー</label>
            <div className="mt-1" style={{ display: 'flex', alignItems: 'center' }}>
              <TextComponent
                type="text"
                value={searchCondition.DM_START}
                onChange={handleChange('DM_START')}
              />
              &nbsp;～&nbsp;
              <TextComponent
                type="text"
                value={searchCondition.DM_END}
                onChange={handleChange('DM_END')}
              />
            </div>
          </Col>
          <Col className="pr-1" md="3">
            <label>施主名</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextComponent
                className="client_text"
                placeholder="部分一致で検索します"
                type="text"
                value={searchCondition.NAME}
                onChange={handleChange('NAME')}
              ></TextComponent>
              <Button
                style={{ width: '48px', height: '32px', marginTop: '5px' }}
                variant="success"
                className="btn-fill m-2"
                size="sm"
                onClick={handleOnClickSearch}
              >
                検索
              </Button>
              <Button
                style={{ width: '94px', height: '32px', marginTop: '5px' }}
                variant="primary"
                className="btn-fill m-2"
                size="sm"
                onClick={handleOnClickAtenaPrint}
              >
                <PrintOutlinedIcon className="mr-1" style={{ fontSize: 18 }} />
                宛名印刷
              </Button>
            </div>
          </Col>
          <Col md="2">
            <label>DMナンバーの重複</label>
            <SelectComponent
              value={searchCondition.DM_CHOFUKU}
              onChange={handleChange('DM_CHOFUKU')}
            >
              <option value={0}>すべて表示</option>
              <option value={2}>一番新しいデータを表示</option>
              <option value={1}>一番古いデータを表示</option>
            </SelectComponent>
          </Col>
          <Col md="5">
            <label>工事内容タグでの絞り込み</label>
            <div style={{ display: 'flex' }}>
              <KoujiTagSelectComponent labels={master.label} callAddTag={addKoujiTag} />
              <div className="ml-2 mt-1">
                {koujiTags.map((x) => (
                  <KoujiTagComponent labels={master.label} tagCode={x} onDelete={deleteKoujiTag} />
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <div>件数：{count}件</div>
        <Row>
          <Col>
            <div className="table-scroll" style={{ height: 'calc(100vh - 250px)' }}>
              <FlexGrid
                ref={flexGrid}
                isReadOnly={true}
                itemsSource={clients}
                selectionMode={'Row'}
                headersVisibility={'Column'}
                style={{ height: '100%' }}
                onDoubleClick={(e) => {
                  const grid = flexGrid.current.control;
                  const ht = grid.hitTest(e);
                  if (ht.cellType === CellType.Cell) {
                    const item = grid.rows[ht.row].dataItem;
                    callCardEditModal(item); // カードダイアログを開く処理
                  }
                }}
                copying={(s, e) => {
                  // セル単位でコピーする
                  const selection = s.selection;
                  const selectedCellContent = s.getCellData(selection.row, selection.col, true);
                  // クリップボードにコピーされる内容を上書き
                  e.cancel = true;
                  navigator.clipboard.writeText(selectedCellContent);
                }}
              >
                <FlexGridFilter filterApplied={filterApplied} />
                <FlexGridColumn header="" binding="" isReadOnly={true} width={42}>
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) => (
                      <span>
                        <Button
                          className="btn-fill detail_button"
                          variant="secondary"
                          title="カード表示"
                          onClick={() => callCardEditModal(cell.item)}
                        >
                          <ContactEmergencyOutlinedIcon />
                        </Button>
                      </span>
                    )}
                  />
                </FlexGridColumn>
                <FlexGridColumn
                  binding="DM_NO"
                  header="DM"
                  width={48}
                  cssClass="item_center"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="LANK"
                  dataMap={clientRankMap}
                  header="ランク"
                  width={64}
                  cssClass="item_center"
                ></FlexGridColumn>
                <FlexGridColumn binding="NAME_1" header="施主名１" width={120}></FlexGridColumn>
                <FlexGridColumn binding="TITLE" header="カード" width={220}></FlexGridColumn>
                <FlexGridColumn binding="KOUJI_MEI" header="工事名" width={240}></FlexGridColumn>
                <FlexGridColumn binding="ZIP" header="郵便番号" width={82}></FlexGridColumn>
                <FlexGridColumn
                  binding="BUILD_CITY"
                  minWidth={220}
                  header="住所"
                  width={'*'}
                ></FlexGridColumn>
                <FlexGridColumn binding="TEL" header="電話番号" width={150}></FlexGridColumn>
                <FlexGridColumn binding="ED_DATE" header="工事完工日" width={110}></FlexGridColumn>
                <FlexGridColumn binding="SEIKYU_DATE" header="請求日" width={110}></FlexGridColumn>
                <FlexGridColumn header="案件" binding="" isReadOnly={true} width={108}>
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) =>
                      cell.item.ARCHIVE_FLG == '1' ? (
                        <Button
                          className="btn-fill archive_button"
                          variant="warning"
                          onClick={() => {
                            hendleClickUnArchive(cell.item);
                          }}
                        >
                          <Inventory2OutlinedIcon />
                          カード非表示
                        </Button>
                      ) : (
                        <></>
                      )
                    }
                  />
                </FlexGridColumn>
              </FlexGrid>
            </div>
          </Col>
        </Row>
      </Card>
      {/** カード編集モーダル画面 */}
      <ClientCardEditModal
        params={cardEditModalparam}
        loadCards={() => {
          updateRow(cardEditModalparam.card.CLIENT_CD);
        }}
      />
      {/* メッセージダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
      {/** 宛名印刷モーダル画面 */}
      <AtenaPrintModal params={atenaPringModalParam} />
    </>
  );
};

export default ClientManage;
