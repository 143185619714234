import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Avatar } from '@mui/material';
import { useRestApi } from 'context/RestApiContext';
import { IconButton, Badge, Menu, MenuItem } from '@mui/material';
import ClientCard from 'views/ClientCards/ClientCard';
import ClientCardEditModal from 'views/ClientCards/ClientCardEditModal';
import ClientCardInquiryModal from 'views/ClientCards/ClientCardInquiryModal';
import ClientCardSearchModal from 'views/ClientCardSearch/ClientCardSearchModal';
import OBSearchModal from 'views/ClientCardSearch/OBSearchModal';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import './ClientCard.css';
import NewNotificationButtonIcon from 'views/ClientCards/NewNotificationButtonIcon';

const ClientCards = () => {
  const restApi = useRestApi();
  const [columns, setColumns] = useState([]);
  // 新着コメント
  const [newComments, setNewComments] = useState([]);
  // 新着コメントのカードID
  const [newCommentIds, setNewCommentIds] = useState(new Set());

  // カード編集モーダルのパラメタ
  const [editModalparam, setEditModalparam] = useState({});
  // カード編集モーダルの呼び出し処理
  const callEditModal = (card) => {
    setEditModalparam({
      card: card,
    });
  };

  // 問い合わせ情報モーダルのパラメタ
  const [inquiryModalparam, setInquiryModalparam] = useState({});
  // 問い合わせ情報モーダルの呼び出し処理
  const callAddModal = (lsistId) => {
    setInquiryModalparam({
      mode: 'add',
      LIST_ID: lsistId,
    });
  };

  // 顧客カード検索のパラメタ
  const [clientCardSearchModalparam, setClientCardSearchModalparam] = useState({});
  const handleOnClickCardSearch = () => {
    setClientCardSearchModalparam({
      show: true,
    });
  };

  // OB検索のパラメタ
  const [obSearchModalparam, setObSearchModalparam] = useState({});
  const handleOnClickObSearch = () => {
    setObSearchModalparam({
      show: true,
    });
  };

  // 初期表示
  useEffect(() => {
    loadCards();
  }, []);

  const loadCards = () => {
    // カード一覧を取得
    restApi.get('/api/cards', (data) => {
      setColumns(data.card_list);
      setNewComments(data.new_comments);
      setNewCommentIds(new Set(data.new_comments.map((x) => x.CARD_ID)));
    });
  };

  const updateCompleteCard = (card) => {
    restApi.post(`/api/cards/${card.CARD_ID}`, { COMPLETE_FLG: card.COMPLETE_FLG }, (data) => {});
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const sourceListId = source.droppableId;
    const sourcePosition = source.index;
    const destinationListId = destination.droppableId;

    // 移動したカードの情報
    const movedCardId = columns.find((col) => col.LIST_ID === sourceListId).CARDS[source.index]
      .CARD_ID;
    const newPosition = destination.index;

    // 同じ場所の場合は処理しない
    if (sourceListId === destinationListId && newPosition === sourcePosition) {
      return;
    }

    const sourceColumnIndex = columns.findIndex((column) => column.LIST_ID === source.droppableId);
    const destinationColumnIndex = columns.findIndex(
      (column) => column.LIST_ID === destination.droppableId
    );
    const sourceColumn = columns[sourceColumnIndex];
    const destinationColumn = columns[destinationColumnIndex];

    const [movedCard] = sourceColumn.CARDS.splice(source.index, 1);
    destinationColumn.CARDS.splice(destination.index, 0, movedCard);

    const newColumns = Array.from(columns);
    newColumns[sourceColumnIndex] = sourceColumn;
    newColumns[destinationColumnIndex] = destinationColumn;

    // APIに送信するデータ
    const requestData = {
      sourceListId: sourceListId,
      sourceList: newColumns[sourceColumnIndex].CARDS,
      destinationListId: destinationListId,
      destinationList: newColumns[destinationColumnIndex].CARDS,
    };
    restApi.post(`/api/cards/move`, requestData, (_) => {});

    setColumns(newColumns);
  };

  return (
    <>
      <div className="ml-3 mb-2  d-flex align-items-center">
        <Button
          className="btn-fill p-1"
          size="sm"
          variant="secondary"
          onClick={handleOnClickCardSearch}
        >
          <SearchIcon />
          カード検索
        </Button>
        <Button
          className="btn-fill p-1 ml-3"
          size="sm"
          variant="primary"
          onClick={handleOnClickObSearch}
        >
          <HelpOutlineIcon />
          OB問い合わせ
        </Button>
        {/* 新着 */}
        <div className="ml-auto mr-3">
          <NewNotificationButtonIcon newComments={newComments} callEditModal={callEditModal} />
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="App">
          <div className="columns-container">
            {columns.map((column) => (
              <Droppable key={column.LIST_ID} droppableId={column.LIST_ID}>
                {(provided) => (
                  <div
                    className="column"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ background: column.COLOR, width: 250 }}
                  >
                    {/* 追加ボタン（問い合わせのみ表示） */}
                    {column.LIST_NAME == '問い合わせ' && (
                      <a
                        href="#"
                        className="list-add-icon"
                        onClick={() => {
                          callAddModal(column.LIST_ID);
                        }}
                      >
                        <Avatar sx={{ width: 24, height: 24 }}>
                          <i className="material-symbols-rounded" style={{ fontSize: 16 }}>
                            add
                          </i>
                        </Avatar>
                      </a>
                    )}

                    <div className="listName">{column.LIST_NAME}</div>
                    {column.CARDS.map((card, index) => (
                      <Draggable key={card.CARD_ID} draggableId={card.CARD_ID} index={index}>
                        {(provided) => (
                          <ClientCard
                            provided={provided}
                            card={card}
                            newCommentIds={newCommentIds}
                            lists={columns}
                            callEditModal={callEditModal}
                            updateCompleteCard={updateCompleteCard}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </div>
      </DragDropContext>

      {/** カード編集モーダル画面 */}
      <ClientCardEditModal params={editModalparam} loadCards={loadCards} />
      {/** 問い合わせ情報モーダル画面 */}
      <ClientCardInquiryModal params={inquiryModalparam} loadCards={loadCards} />
      {/** OB検索モーダル画面 */}
      <OBSearchModal
        params={obSearchModalparam}
        loadCards={loadCards}
        setInquiryModalparam={setInquiryModalparam}
      />
      {/** カード検索モーダル画面 */}
      <ClientCardSearchModal
        params={clientCardSearchModalparam}
        loadCards={loadCards}
        callEditModal={callEditModal}
      />
    </>
  );
};

export default ClientCards;
