import { useState, useEffect } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { useRestApi } from 'context/RestApiContext';
import { useMaster } from 'context/MasterContext';
import { MessageModal } from 'components/modal/MessageModal';
import './ClientCard.css';
import './ClientCardInquiry.css';

import TextComponent from 'components/Input/TextComponent';
import SelectComponent from 'components/Input/SelectComponent';

import { getAddress } from 'utils/addresUtils';
import {
  ToiawaseCodes,
  WorkKbns,
  WishPlans,
  Budgets,
  Exists,
  LoanCompanies,
  MeetingStatus,
} from 'constants/constants';

// 問い合わせ情報の編集画面
function ClientCardInquiryModal(props) {
  const master = useMaster();
  const restApi = useRestApi();

  const [show, setShow] = useState(false);
  // 問い合わせ情報
  const [inquiry, setInquiry] = useState({});

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // モード
  const [mode, setMode] = useState('');

  // 編集処理
  const handleOnChange = (prop) => (target) => {
    setInquiry({ ...inquiry, [prop]: target?.value ?? target?.target.value });
  };

  useEffect(() => {
    setMode(props?.params?.mode);
    if (props?.params?.mode == 'add') {
      setInquiry({
        TITLE: '',
        LIST_ID: props.params.LIST_ID,
        POST_CODE: '',
        PREF: '',
        CITY: '',
        BUILD_POST_CODE: '',
        BUILD_PREF: '',
        BUILD_CITY: '',
        UKE_DATE: '',
        COMPLETE_DATE: '',
        DELIVERY_DATE: '',
        MOVING_DATE: '',
        BUILD_AGE: '',
      });
      setShow(true);
    } else if (props?.params?.mode == 'upd') {
      setInquiry(props.params.inquiry);
      setShow(true);
    } else if (props?.params?.mode == 'copy') {
      setInquiry(props.params.inquiry);
      setShow(true);
    }
  }, [props.params]);

  // 郵便番号検索
  const searchZip = (prefix) => {
    getAddress(
      restApi,
      inquiry[prefix + 'POST_CODE'],
      (json) => {
        let result = json['results'][0];
        setInquiry({
          ...inquiry,
          [prefix + 'PREF']: result['address1'],
          [prefix + 'CITY']: result['address2'] + result['address3'],
        });
      },
      setDialog
    );
  };

  // 保存ボタンのクリック処理
  const handleOnClickSave = () => {
    let _inquiry = {
      ...inquiry,
      BIRTHDAY: inquiry.BIRTHDAY ? inquiry.BIRTHDAY : null,
      UKE_DATE: inquiry.UKE_DATE ? inquiry.UKE_DATE : null,
      COMPLETE_DATE: inquiry.COMPLETE_DATE ? inquiry.COMPLETE_DATE : null,
      DELIVERY_DATE: inquiry.DELIVERY_DATE ? inquiry.DELIVERY_DATE : null,
      MOVING_DATE: inquiry.MOVING_DATE ? inquiry.MOVING_DATE : null,
    };

    // OBからコピーの場合は追加と同じ
    let _mode = mode == 'copy' ? 'add' : mode;
    restApi.post(`/api/inquiry/${_mode}`, _inquiry, (data) => {
      props.loadCards('inquiry');
      setShow(false);
    });
  };

  // 閉じるボタンのクリック処理
  const handleClose = () => {
    props.loadCards('inquiry');
    setShow(false);
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '1120px',
              backgroundColor: '#f1f2f4',
              marginTop: props?.params?.mode == 'add' ? '20px' : '60px',
            },
          },
        }}
        open={show}
        disableEnforceFocus={true} // フォーカス制御を無効にする
      >
        <DialogTitle className="p-3">
          {props?.params?.mode != 'upd' ? (
            <Form.Control
              type="text"
              placeholder="カード名を入力してください"
              value={inquiry.TITLE}
              onChange={handleOnChange('TITLE')}
              style={{ width: '600px' }}
            />
          ) : (
            <>{inquiry.TITLE}</>
          )}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="px-3 py-3">
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <div className="description-label">
                <i className="material-symbols-rounded">contact_page</i>
                <b>問い合わせ情報</b>
              </div>
              <Row>
                <Col md={4}>
                  <label className="client-card-label">名前</label>
                  <TextComponent
                    type="text"
                    value={inquiry.NAME_1}
                    onChange={handleOnChange('NAME_1')}
                  />
                </Col>
                <Col md={5}>
                  <label className="client-card-label">ふりがな</label>
                  <TextComponent
                    type="text"
                    value={inquiry.NAME_KANA_1}
                    onChange={handleOnChange('NAME_KANA_1')}
                  />
                </Col>
                <Col md="2">
                  <label className="client-card-label">生年月日</label>
                  <TextComponent
                    type="date"
                    value={inquiry.BIRTHDAY}
                    onChange={handleOnChange('BIRTHDAY')}
                  ></TextComponent>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label className="client-card-label">名前２</label>
                  <TextComponent
                    type="text"
                    value={inquiry.NAME_2}
                    onChange={handleOnChange('NAME_2')}
                  />
                </Col>
                <Col md={5}>
                  <label className="client-card-label">ふりがな２</label>
                  <TextComponent
                    type="text"
                    value={inquiry.NAME_KANA_2}
                    onChange={handleOnChange('NAME_KANA_2')}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="client-card-label">引き合い日</label>
                  <TextComponent
                    type="date"
                    value={inquiry.UKE_DATE}
                    onChange={handleOnChange('UKE_DATE')}
                  />
                </Col>
                <Col md={3}>
                  <label className="client-card-label">問い合わせ方法</label>
                  <SelectComponent
                    value={inquiry.TOIAWASE_CD}
                    onChange={handleOnChange('TOIAWASE_CD')}
                  >
                    {ToiawaseCodes.map((x, i) => (
                      <option key={i} value={x.key}>
                        {x.value}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">区分</label>
                  <SelectComponent value={inquiry.WORK_KBN} onChange={handleOnChange('WORK_KBN')}>
                    {WorkKbns.map((x, i) => (
                      <option key={i} value={x.key}>
                        {x.value}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="client-card-label">プラン希望</label>
                  <SelectComponent value={inquiry.WISH_PLAN} onChange={handleOnChange('WISH_PLAN')}>
                    {WishPlans.map((x, i) => (
                      <option key={i} value={x.key}>
                        {x.value}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
                <Col md={6}>
                  <label className="client-card-label">打合せ状況について</label>
                  <SelectComponent
                    value={inquiry.MEETING_STATUS}
                    onChange={handleOnChange('MEETING_STATUS')}
                  >
                    {MeetingStatus.map((x, i) => (
                      <option key={i} value={x.key}>
                        {x.value}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="client-card-label">受付</label>
                  <SelectComponent
                    value={inquiry.UKE_TAN_CD}
                    onChange={handleOnChange('UKE_TAN_CD')}
                  >
                    <option key="-1" value={0}>
                      {'　'}
                    </option>
                    {master.planer.map((x, i) => (
                      <option key={i} value={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">プランナー</label>
                  <SelectComponent
                    value={inquiry.PLANNER_CD}
                    onChange={handleOnChange('PLANNER_CD')}
                  >
                    <option key="-1" value={0}>
                      {'　'}
                    </option>
                    {master.planer.map((x, i) => (
                      <option key={i} value={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">CAD</label>
                  <SelectComponent
                    value={inquiry.CAD_TAN_CD}
                    onChange={handleOnChange('CAD_TAN_CD')}
                  >
                    <option key="-1" value={0}>
                      {'　'}
                    </option>
                    {master.planer.map((x, i) => (
                      <option key={i} value={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">見積</label>
                  <SelectComponent
                    value={inquiry.QUOTE_TAN_CD}
                    onChange={handleOnChange('QUOTE_TAN_CD')}
                  >
                    <option key="-1" value={0}>
                      {'　'}
                    </option>
                    {master.planer.map((x, i) => (
                      <option key={i} value={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="client-card-label">建築前 郵便番号</label>
                  <span style={{ display: 'flex' }}>
                    <TextComponent
                      type="tel"
                      value={inquiry.POST_CODE}
                      onChange={handleOnChange('POST_CODE')}
                    ></TextComponent>
                    <Button
                      size="sm"
                      variant="default"
                      className="post-no"
                      onClick={() => {
                        searchZip('');
                      }}
                    >
                      検索
                    </Button>
                  </span>
                </Col>
                <Col md={7}>
                  <label className="client-card-label">建築前 市町村以下</label>
                  <TextComponent
                    type="text"
                    value={inquiry.CITY}
                    onChange={handleOnChange('CITY')}
                  ></TextComponent>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="client-card-label">建築地&nbsp;郵便番号</label>
                  <span style={{ display: 'flex' }}>
                    <TextComponent
                      type="tel"
                      value={inquiry.BUILD_POST_CODE}
                      onChange={handleOnChange('BUILD_POST_CODE')}
                    ></TextComponent>
                    <Button
                      size="sm"
                      variant="default"
                      className="post-no"
                      onClick={() => {
                        searchZip('BUILD_');
                      }}
                    >
                      検索
                    </Button>
                  </span>
                </Col>
                <Col md={7}>
                  <label className="client-card-label">建築地&nbsp;市町村以下</label>
                  <TextComponent
                    type="text"
                    value={inquiry.BUILD_CITY}
                    onChange={handleOnChange('BUILD_CITY')}
                  ></TextComponent>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="client-card-label">建物完成予定日</label>
                  <TextComponent
                    type="date"
                    value={inquiry.COMPLETE_DATE}
                    onChange={handleOnChange('COMPLETE_DATE')}
                  ></TextComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">引き渡し予定日</label>
                  <TextComponent
                    type="date"
                    value={inquiry.DELIVERY_DATE}
                    onChange={handleOnChange('DELIVERY_DATE')}
                  ></TextComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">引っ越し予定日</label>
                  <TextComponent
                    type="date"
                    value={inquiry.MOVING_DATE}
                    onChange={handleOnChange('MOVING_DATE')}
                  ></TextComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">住宅建築年</label>
                  <TextComponent
                    type="date"
                    selectionMode="Month"
                    value={inquiry.BUILD_AGE}
                    onChange={handleOnChange('BUILD_AGE')}
                  ></TextComponent>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label className="client-card-label">建築会社</label>
                  <SelectComponent
                    value={inquiry.HOUSE_MAKER}
                    onChange={handleOnChange('HOUSE_MAKER')}
                  >
                    <option key="-1" value={' '}>
                      {'　'}
                    </option>
                    {master.houseMaker.map((x, i) => (
                      <option key={i} value={x['id']}>
                        {x['name']}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="client-card-label">引き合い動機（1位）</label>
                  <SelectComponent
                    value={inquiry.CHANEL_KBN}
                    onChange={handleOnChange('CHANEL_KBN')}
                  >
                    <option key="-1" value={' '}>
                      {'　'}
                    </option>
                    {master.channelCd.map((x, i) => (
                      <option key={i} value={x['CHANNEL_CD']}>
                        {x['CHANNEL_NM']}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">引き合い動機（2位）</label>
                  <SelectComponent
                    value={inquiry.CHANEL_KBN2}
                    onChange={handleOnChange('CHANEL_KBN2')}
                  >
                    <option key="-1" value={' '}>
                      {'　'}
                    </option>
                    {master.channelCd.map((x, i) => (
                      <option key={i} value={x['CHANNEL_CD']}>
                        {x['CHANNEL_NM']}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">引き合い動機（3位）</label>
                  <SelectComponent
                    value={inquiry.CHANEL_KBN3}
                    onChange={handleOnChange('CHANEL_KBN3')}
                  >
                    <option key="-1" value={' '}>
                      {'　'}
                    </option>
                    {master.channelCd.map((x, i) => (
                      <option key={i} value={x['CHANNEL_CD']}>
                        {x['CHANNEL_NM']}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <label className="client-card-label">電話（ご主人）</label>
                  <TextComponent
                    type="tel"
                    value={inquiry.TEL1}
                    onChange={handleOnChange('TEL1')}
                  ></TextComponent>
                </Col>
                <Col md={4}>
                  <label className="client-card-label">電話（奥様）</label>
                  <TextComponent
                    type="tel"
                    value={inquiry.TEL2}
                    onChange={handleOnChange('TEL2')}
                  ></TextComponent>
                </Col>
                <Col md={4}>
                  <label className="client-card-label">電話（固定）</label>
                  <TextComponent
                    type="tel"
                    value={inquiry.TEL3}
                    onChange={handleOnChange('TEL3')}
                  ></TextComponent>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label className="client-card-label">メールアドレス1</label>
                  <TextComponent
                    type="tel"
                    value={inquiry.MAIL}
                    onChange={handleOnChange('MAIL')}
                  ></TextComponent>
                </Col>
                <Col md={6}>
                  <label className="client-card-label">メールアドレス2</label>
                  <TextComponent
                    type="tel"
                    value={inquiry.MAIL2}
                    onChange={handleOnChange('MAIL2')}
                  ></TextComponent>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <label className="client-card-label">家族構成</label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextComponent
                      className="family-count"
                      type="number"
                      max={999}
                      value={inquiry.FAMILY_COUNT}
                      onChange={handleOnChange('FAMILY_COUNT')}
                    ></TextComponent>
                    <span className="ml-2">名</span>
                    <TextComponent
                      placeholder="10歳未満△名【◇歳(男・女)、◇歳(男・女)、◇歳 (男・女)】 10代△名 20代△名 30代△名 40代△名 50代△名 60歳以上△名"
                      className="ml-2"
                      type="text"
                      value={inquiry.FAMILY}
                      onChange={handleOnChange('FAMILY')}
                    ></TextComponent>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label className="client-card-label">車の台数・車種など</label>
                  <TextComponent
                    type="text"
                    value={inquiry.CARS}
                    onChange={handleOnChange('CARS')}
                  ></TextComponent>
                </Col>
                <Col md={6}>
                  <label className="client-card-label">ペット</label>
                  <TextComponent
                    type="text"
                    value={inquiry.PET}
                    onChange={handleOnChange('PET')}
                  ></TextComponent>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <label className="client-card-label">予算</label>
                  <SelectComponent value={inquiry.BUDGET} onChange={handleOnChange('BUDGET')}>
                    {Budgets.map((x, i) => (
                      <option key={i} value={x.key}>
                        {x.value}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">ガーデンライフプラン申し込み</label>
                  <SelectComponent
                    value={inquiry.GARDEN_LIFE_PLAN}
                    onChange={handleOnChange('GARDEN_LIFE_PLAN')}
                  >
                    {Exists.map((x, i) => (
                      <option key={i} value={x.key}>
                        {x.value}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
                <Col md={3}>
                  <label className="client-card-label">ガーデンリフォームローン使用</label>
                  <SelectComponent
                    value={inquiry.GARDEN_REFORM_LOAN_FLG}
                    onChange={handleOnChange('GARDEN_REFORM_LOAN_FLG')}
                  >
                    {Exists.map((x, i) => (
                      <option key={i} value={x.key}>
                        {x.value}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
                <Col md={2}>
                  <label className="client-card-label"></label>
                  <SelectComponent
                    value={inquiry.GARDEN_REFORM_LOAN_COMPANY}
                    onChange={handleOnChange('GARDEN_REFORM_LOAN_COMPANY')}
                  >
                    {LoanCompanies.map((x, i) => (
                      <option key={i} value={x.key}>
                        {x.value}
                      </option>
                    ))}
                  </SelectComponent>
                </Col>
              </Row>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="p-3" style={{ justifyContent: 'space-between' }}>
          <Button variant="secondary" className="footer-button" onClick={handleClose}>
            閉じる
          </Button>
          <Button
            variant="primary"
            className="btn-fill  ml-4 mr-2"
            onClick={handleOnClickSave}
            disabled={!inquiry.TITLE}
            style={{ flexGrow: 1 }} // 残りのスペースを保存ボタンに割り当てる
          >
            保存
          </Button>
        </DialogActions>
      </Dialog>
      {/* メッセージダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}

export default ClientCardInquiryModal;
