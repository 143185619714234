import Big from 'big.js';

/**
 * Floatに変換（小数点は指定桁数以下は切り捨て）
 * @param {*} val 値
 * @param {*} decimalplace 少数桁数
 * @returns Float
 */
export function toFloat(val, decimalplace = 0) {
  if (isNaN(parseFloat(val))) {
    return parseFloat(0);
  }

  let v = new Big(parseFloat(val));
  return parseFloat(v.toFixed(decimalplace, 0));
}

/**
 * Intに変換（変換できない場合はnull)
 * @param {*} val 値
 * @returns Float
 */
export function toInt(val) {
  let n = parseInt(val);
  if (isNaN(n)) {
    return null;
  }
  return parseInt((val * 1).toFixed(0));
}
