import { CellRange, DataMap } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { CellType } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import { useRestApi } from 'context/RestApiContext';
import { MessageModal } from 'components/modal/MessageModal';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import ClientCardEditModal from 'views/ClientCards/ClientCardEditModal';
import SelectComponent from 'components/Input/SelectComponent';
import { toFloat } from 'utils/numberUtils';
import { toDate } from 'utils/dateUtils';

// 年間管理一覧
const YearManageList = (props) => {
  const restApi = useRestApi();

  // グリッド
  const flexGrid = useRef();

  // 精算金額合計
  const [totalSeisan, setTotalSeisan] = useState(0);

  // 確認ダイアログ
  const [dialog, setDialog] = useState({});

  // 顧客一覧情報
  const [clients, setClients] = useState([]);

  // カード編集モーダルパラメタ
  const [cardEditModalParam, setCardEditModalParam] = useState({});

  // 選択中の管理ID
  const [targetManageId, setTargetManageId] = useState();

  // 顧客検索条件
  const [searchCondition, setSearchCondition] = useState({
    YEAR: '',
    MONTH: '',
  });

  var maruMap = new DataMap(
    [
      { key: 0, value: '' },
      { key: 1, value: '〇' },
    ],
    'key',
    'value'
  );

  // 検索時に再選択
  useEffect(() => {
    if (targetManageId) {
      const index = clients.indexOf(clients.find((c) => c['MANAGE_ID'] == targetManageId));
      if (index >= 0) {
        setTimeout(() => {
          flexGrid.current.control.select(new CellRange(index), true);
        }, 10);
      }
    }
    // 合計金額を計算
    setTotalSeisan(clients.reduce((a, x) => a + toFloat(x['KAKAKU']), 0));
  }, [clients]);

  // 初期表示処理
  useEffect(() => {
    searchClients();
  }, [searchCondition]);

  // 編集処理
  const handleChange = (prop) => (target) => {
    const val = target?.value ?? target?.target.value;
    let _searchCondition = { ...searchCondition, [prop]: val };

    if (prop == 'YEAR' && !val) {
      _searchCondition = { ..._searchCondition, MONTH: '' };
    }
    setSearchCondition(_searchCondition);
  };

  // 検索処理
  const searchClients = (manageId = null) => {
    setTargetManageId(manageId);
    restApi.post('/api/year_manage/list', searchCondition, (data) => {
      setClients(
        data.map((x) => ({
          ...x,
          LAST_UPDATE: toDate(x.LAST_UPDATE),
        }))
      );
    });
  };

  // 編集ボタン
  const handleOnClickEditClient = useCallback((card) => {
    setCardEditModalParam({
      show: true,
      card: card,
    });
  }, []);

  // 一覧の更新時に件数を更新する
  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(clients.length);
  }, [clients]);

  // フィルタの変更処理
  const filterApplied = (s, e) => {
    let grid = s.grid;
    setCount(grid?.collectionView?.items.length);
  };

  return (
    <>
      <Card className="m-0 pl-3 pt-1  table-container">
        <Row>
          <div className="ml-3 pr-1">
            <label>年</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SelectComponent value={searchCondition.YEAR} onChange={handleChange('YEAR')}>
                <option key={0} value={''}>
                  {'　'}
                </option>

                {Array.from({ length: new Date().getFullYear() - 2018 + 1 }, (_, i) => 2018 + i)
                  .reverse()
                  .map((i) => (
                    <option key={i} value={i}>
                      {i}年
                    </option>
                  ))}
              </SelectComponent>
            </div>
          </div>
          {searchCondition.YEAR && (
            <div className="ml-3 pr-1">
              <label>月</label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <SelectComponent value={searchCondition.MONTH} onChange={handleChange('MONTH')}>
                  <option key={0} value={''}>
                    {'　'}
                  </option>

                  {Array.from({ length: 12 }, (_, i) => i + 1).map((i) => (
                    <option key={i} value={i}>
                      {i}月
                    </option>
                  ))}
                </SelectComponent>
              </div>
            </div>
          )}
          {searchCondition.MONTH && (
            <div className="ml-3 pr-1">
              <label>価格合計</label>
              <div className="mt-2 text-right" style={{ fontSize: '15pt', width: 160 }}>
                {totalSeisan.toLocaleString()}円
              </div>
            </div>
          )}
        </Row>
        <div className="mt-2">件数：{count}件</div>
        <Row>
          <Col>
            <div className="table-scroll" style={{ height: 'calc(100vh - 208px)' }}>
              <FlexGrid
                ref={flexGrid}
                isReadOnly={true}
                itemsSource={clients}
                selectionMode={'Row'}
                headersVisibility={'Column'}
                style={{ height: '100%' }}
                onDoubleClick={(e) => {
                  const grid = flexGrid.current.control;
                  const ht = grid.hitTest(e);
                  if (ht.cellType === CellType.Cell) {
                    const item = grid.rows[ht.row].dataItem;
                    handleOnClickEditClient(item); // カードダイアログを開く処理
                  }
                }}
                copying={(s, e) => {
                  // セル単位でコピーする
                  const selection = s.selection;
                  const selectedCellContent = s.getCellData(selection.row, selection.col, true);
                  // クリップボードにコピーされる内容を上書き
                  e.cancel = true;
                  navigator.clipboard.writeText(selectedCellContent);
                }}
              >
                <FlexGridFilter filterApplied={filterApplied} />
                <FlexGridColumn header="" binding="" isReadOnly={true} width={42}>
                  <FlexGridCellTemplate
                    cellType="Cell"
                    template={(cell) => (
                      <span>
                        <Button
                          className="material-symbols-rounded cell-icon btn-fill"
                          variant="primary"
                          title="編集"
                          onClick={() => handleOnClickEditClient(cell.item)}
                        >
                          edit
                        </Button>
                      </span>
                    )}
                  />
                </FlexGridColumn>
                <FlexGridColumn
                  binding="YEAR"
                  header="年"
                  width={62}
                  align="center"
                  format="d"
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="DM_NO"
                  header="DM"
                  width={58}
                  align="center"
                ></FlexGridColumn>
                <FlexGridColumn binding="TITLE" header="邸名" width={150}></FlexGridColumn>
                <FlexGridColumn binding="LAST_UPDATE" header="更新日" width={100}></FlexGridColumn>
                <FlexGridColumn binding="KAKAKU" header="価格" width={100}></FlexGridColumn>
                <FlexGridColumn binding="DESCRIPTION" header="内容" width={'*'}></FlexGridColumn>
                <FlexGridColumn
                  binding="M1"
                  header="1月"
                  align="center"
                  width={50}
                  dataMap={maruMap}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="M2"
                  header="2月"
                  align="center"
                  width={50}
                  dataMap={maruMap}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="M3"
                  header="3月"
                  align="center"
                  width={50}
                  dataMap={maruMap}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="M4"
                  header="4月"
                  width={50}
                  align="center"
                  dataMap={maruMap}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="M5"
                  header="5月"
                  width={50}
                  align="center"
                  dataMap={maruMap}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="M6"
                  header="6月"
                  width={50}
                  align="center"
                  dataMap={maruMap}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="M7"
                  header="7月"
                  width={50}
                  align="center"
                  dataMap={maruMap}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="M8"
                  header="8月"
                  width={50}
                  align="center"
                  dataMap={maruMap}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="M9"
                  header="9月"
                  width={50}
                  align="center"
                  dataMap={maruMap}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="M10"
                  header="10月"
                  width={50}
                  align="center"
                  dataMap={maruMap}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="M11"
                  header="11月"
                  width={50}
                  align="center"
                  dataMap={maruMap}
                ></FlexGridColumn>
                <FlexGridColumn
                  binding="M12"
                  header="12月"
                  width={50}
                  align="center"
                  dataMap={maruMap}
                ></FlexGridColumn>
              </FlexGrid>
            </div>
          </Col>
        </Row>
      </Card>
      {/* メッセージダイアログ */}
      <MessageModal dialog={dialog}></MessageModal>
      {/** カード編集モーダル画面 */}
      <ClientCardEditModal
        params={cardEditModalParam}
        selectedTab={'year_manage'}
        loadCards={() => {
          searchClients(cardEditModalParam.card?.MANAGE_ID);
        }}
      />
    </>
  );
};

export default YearManageList;
