import React, { useState, useEffect, useRef } from 'react';
import { CollectionView } from '@grapecity/wijmo';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { CellType } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextComponent from 'components/Input/TextComponent';
import SelectComponent from 'components/Input/SelectComponent';

import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined';

import { useRestApi } from 'context/RestApiContext';
import { MessageModal } from 'components/modal/MessageModal';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';

// 顧客カード検索
function ClientCardSearchModal(props) {
  const restApi = useRestApi();
  const gridRef = useRef(null);

  const [show, setShow] = useState(false);
  // メッセージダイアログ
  const [dialog, setDialog] = useState({});

  // 顧客一覧情報
  const [clients, setClients] = useState(new CollectionView());
  // リスト一覧
  const [lists, setLists] = useState([]);

  // 検索条件
  const [searchCondition, setSearchCondition] = useState({
    NAME: '',
    TEL: '',
    LIST_ID: 0,
    CARD_STATUS: -1,
  });

  // 検索処理
  const searchClients = (condition) => {
    restApi.post('/api/client/cardsearch', condition, (data) => {
      setClients(new CollectionView(data.client));
    });
  };

  useEffect(() => {
    if (props?.params?.show) {
      setShow(true);
      setClients(new CollectionView());
      setSearchCondition({
        NAME: '',
        TEL: '',
        LIST_ID: 0,
        CARD_STATUS: -1,
      });
      restApi.get('/api/master/lists/1', (data) => {
        setLists(data.lists);
      });
    }
  }, [props.params]);

  // 編集処理
  const handleChange = (prop) => (target) => {
    const _searchConditon = { ...searchCondition, [prop]: target?.value ?? target?.target.value };
    setSearchCondition(_searchConditon);
    // リストと案件の表示状態の場合は再検索
    if (['LIST_ID', 'CARD_STATUS'].includes(prop)) {
      searchClients(_searchConditon);
    }
  };

  // 表示フラグの一括変更
  const toggleArchiveFlag = (flag) => {
    const grid = gridRef.current.control;
    const cardIds = grid.selectedRows.map((row) => row.dataItem.CARD_ID);
    const listIds = [...new Set(grid.selectedRows.map((row) => row.dataItem.LIST_ID))];
    if (cardIds.length == 0) {
      return;
    }
    setDialog({
      type: 'confirm',
      message: `${cardIds.length}件のカードを一括で${
        flag == 0 ? '表示' : '非表示'
      }にします。よろしいですか？`,
      okCallBack: () => {
        restApi.post(
          `/api/cards/togglearchive/${flag}`,
          { cardIds: cardIds, listIds: listIds },
          (_) => {
            searchClients(searchCondition);
          }
        );
      },
    });
  };

  // 検索ボタン
  const handleOnClickSearch = () => {
    searchClients(searchCondition);
  };

  // 案件全般に表示
  const hendleClickUnArchive = (item) => {
    setDialog({
      type: 'confirm',
      message: `カードを案件全般に表示します。よろしいですか？`,
      okCallBack: () => {
        restApi.post(`/api/cards/unarchive/${item.CARD_ID}`, { ARCHIVE_FLG: '1' }, (_) => {
          const target = clients.items.find((c) => c.CARD_ID == item.CARD_ID);
          if (target) {
            target.ARCHIVE_FLG = '0';
          }
        });
      },
    });
  };
  // 詳細を選択
  const hendleClickDetail = (item) => {
    props.callEditModal(item);
  };

  // 閉じる処理
  const handleClose = () => {
    props.loadCards();
    setShow(false);
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '1340px',
              backgroundColor: '#f1f2f4',
              marginTop: '40px',
            },
          },
        }}
        open={show}
        onClose={(e, reason) => {
          if (reason === 'backdropClick') {
            handleClose();
          }
        }}
      >
        <DialogTitle>
          カード検索
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Card style={{ height: 720 }}>
            <Row>
              <Col className="pr-1" md="2">
                <label>名前</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextComponent
                    placeholder="部分一致で検索します"
                    type="text"
                    value={searchCondition.NAME}
                    onChange={handleChange('NAME')}
                  ></TextComponent>
                </div>
              </Col>
              <Col className="pr-1" md="3">
                <label>電話</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextComponent
                    placeholder="部分一致で検索します"
                    type="tel"
                    value={searchCondition.TEL}
                    onChange={handleChange('TEL')}
                  ></TextComponent>
                  <Button
                    className="ml-2 btn-fill"
                    style={{ width: 80 }}
                    variant="success"
                    size="sm"
                    onClick={handleOnClickSearch}
                  >
                    検索
                  </Button>
                </div>
              </Col>
              <Col className="pr-1" md="2">
                <label>リスト</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SelectComponent
                    value={searchCondition.LIST_ID}
                    onChange={handleChange('LIST_ID')}
                  >
                    <option key={0} value={0}>
                      すべて
                    </option>
                    {lists.map((x, i) => (
                      <option key={i} value={x.LIST_ID}>
                        {x.LIST_NAME}
                      </option>
                    ))}
                  </SelectComponent>
                </div>
              </Col>
              <Col className="pr-1" md="2">
                <label>案件の表示状態</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <SelectComponent
                    value={searchCondition.CARD_STATUS}
                    onChange={handleChange('CARD_STATUS')}
                  >
                    <option value={-1}>すべて</option>
                    <option value={0}>表示中</option>
                    <option value={1}>非表示</option>
                  </SelectComponent>
                </div>
              </Col>
              <Col className="pr-1" md="3">
                <label>　</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    className="pb-1 btn-fill"
                    variant="warning"
                    onClick={() => {
                      toggleArchiveFlag(1);
                    }}
                  >
                    一括非表示
                  </Button>
                  <Button
                    className="ml-2 pb-1 btn-fill"
                    onClick={() => {
                      toggleArchiveFlag(0);
                    }}
                  >
                    一括表示
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <div>
                  <FlexGrid
                    ref={gridRef}
                    isReadOnly={true}
                    itemsSource={clients}
                    selectionMode={'ListBox'}
                    headersVisibility={'Column'}
                    style={{ height: '100%' }}
                    onDoubleClick={(e) => {
                      const grid = gridRef.current.control;
                      const ht = grid.hitTest(e);
                      if (ht.cellType === CellType.Cell) {
                        const item = grid.rows[ht.row].dataItem;
                        props.callEditModal(item); // 詳細ダイアログを開く処理
                      }
                    }}
                    copying={(s, e) => {
                      // セル単位でコピーする
                      const selection = s.selection;
                      const selectedCellContent = s.getCellData(selection.row, selection.col, true);
                      // クリップボードにコピーされる内容を上書き
                      e.cancel = true;
                      navigator.clipboard.writeText(selectedCellContent);
                    }}
                  >
                    <FlexGridFilter />
                    <FlexGridColumn
                      binding="LIST_NAME"
                      header="リスト"
                      width={120}
                    ></FlexGridColumn>
                    <FlexGridColumn binding="TITLE" header="カード" width={'*'}></FlexGridColumn>
                    <FlexGridColumn binding="NAME_1" header="名前" width={'*'}></FlexGridColumn>
                    <FlexGridColumn
                      binding="TEL1"
                      header="電話(ご主人)"
                      width={150}
                    ></FlexGridColumn>
                    <FlexGridColumn binding="TEL2" header="電話(奥様)" width={150}></FlexGridColumn>
                    <FlexGridColumn binding="TEL3" header="電話(固定)" width={150}></FlexGridColumn>
                    <FlexGridColumn header="案件" binding="" isReadOnly={true} width={108}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) =>
                          cell.item.ARCHIVE_FLG == '1' ? (
                            <Button
                              className="btn-fill archive_button"
                              variant="warning"
                              onClick={() => {
                                hendleClickUnArchive(cell.item);
                              }}
                            >
                              <Inventory2OutlinedIcon />
                              カード非表示
                            </Button>
                          ) : (
                            <></>
                          )
                        }
                      />
                    </FlexGridColumn>
                    <FlexGridColumn header="詳細" binding="" isReadOnly={true} width={48}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <Button
                            className="btn-fill detail_button"
                            variant="secondary"
                            onClick={() => {
                              hendleClickDetail(cell.item);
                            }}
                          >
                            <ContactEmergencyOutlinedIcon />
                          </Button>
                        )}
                      />
                    </FlexGridColumn>
                  </FlexGrid>
                </div>
              </Col>
            </Row>
          </Card>
        </DialogContent>
      </Dialog>
      <MessageModal dialog={dialog}></MessageModal>
    </>
  );
}
export default ClientCardSearchModal;
