import React, { useState, useEffect, useRef } from 'react';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn } from '@grapecity/wijmo.react.grid';
import { CellType } from '@grapecity/wijmo.grid';
import { FlexGridFilter } from '@grapecity/wijmo.react.grid.filter';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextComponent from 'components/Input/TextComponent';
import ClientCardInquiryModal from 'views/ClientCards/ClientCardInquiryModal';

import { useRestApi } from 'context/RestApiContext';
import { MessageModal } from 'components/modal/MessageModal';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';

// 顧客カード検索
function OBSearchModal(props) {
  const restApi = useRestApi();
  const gridRef = useRef(null);

  const [show, setShow] = useState(false);
  // メッセージダイアログ
  const [dialog, setDialog] = useState({});

  // 顧客一覧情報
  const [clients, setClients] = useState([]);

  // 検索条件
  const [searchCondition, setSearchCondition] = useState({
    NAME: '',
    TEL: '',
  });

  // 検索処理
  const searchClients = (condition) => {
    restApi.post('/api/client/obsearch', condition, (data) => {
      setClients(data);
    });
  };

  useEffect(() => {
    if (props?.params?.show) {
      setShow(true);
      setClients([]);
    }
  }, [props.params]);

  // 編集処理
  const handleChange = (prop) => (target) => {
    setSearchCondition({ ...searchCondition, [prop]: target?.value ?? target?.target.value });
  };

  // 詳細を選択
  // 問い合わせ情報モーダルのパラメタ
  const [inquiryModalparam, setInquiryModalparam] = useState({});
  const hendleClickDetail = (item) => {
    setInquiryModalparam({
      mode: 'copy',
      inquiry: {
        TITLE: '',
        LIST_ID: 1, // 問い合わせ
        POST_CODE: '',
        PREF: '',
        CITY: '',
        NAME_1: item.NAME_1,
        NAME_KANA_1: item.NAME_KANA_1,
        TEL1: item.TEL1,
        TEL2: item.TEL2,
        TEL3: item.TEL3,
        DM_NO: item.DM_NO,
        BUILD_POST_CODE: item.BUILD_POST_CODE,
        BUILD_PREF: item.BUILD_PREF,
        BUILD_CITY: item.BUILD_CITY,
        BIRTHDAY: item.BIRTHDAY,
        BUILD_AGE: item.BUILD_AGE,
        HOUSE_MAKER: item.HOUSE_MAKER,
        MAIL: item.MAIL,
        MAIL2: item.MAIL2,
        FAMILY_COUNT: item.FAMILY_COUNT,
        FAMILY: item.FAMILY,
        PET: item.PET,
        KOUJI_HISTORY: item.KOUJI_HISTORY,
        KOUJI_MEMO: item.KOUJI_MEMO,
        OTHER_MEMO: item.OTHER_MEMO,
        PRINT_FLG: item.PRINT_FLG,
        PRINT_MEMO: item.PRINT_MEMO,
        UKE_DATE: '',
        COMPLETE_DATE: '',
        DELIVERY_DATE: '',
        MOVING_DATE: '',
        BUILD_AGE: '',
      },
    });
  };

  // 検索ボタン
  const handleOnClickSearch = () => {
    searchClients(searchCondition);
  };

  // 閉じる処理
  const handleClose = () => {
    props.loadCards();
    setShow(false);
  };

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '1240px',
              backgroundColor: '#f1f2f4',
              marginTop: '40px',
            },
          },
        }}
        open={show}
        onClose={(e, reason) => {
          if (reason === 'backdropClick') {
            handleClose();
          }
        }}
      >
        <DialogTitle>
          問い合わせがあったOBを選択してください
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Card style={{ height: 720 }}>
            <Row>
              <Col className="pr-1" md="4">
                <label>名前</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextComponent
                    placeholder="部分一致で検索します"
                    type="text"
                    value={searchCondition.NAME}
                    onChange={handleChange('NAME')}
                  ></TextComponent>
                </div>
              </Col>
              <Col className="pr-1" md="4">
                <label>電話</label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <TextComponent
                    placeholder="部分一致で検索します"
                    type="tel"
                    value={searchCondition.TEL}
                    onChange={handleChange('TEL')}
                  ></TextComponent>
                  <Button
                    className="ml-2 btn-fill"
                    style={{ width: 80 }}
                    variant="success"
                    size="sm"
                    onClick={handleOnClickSearch}
                  >
                    検索
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <div>
                  <FlexGrid
                    ref={gridRef}
                    isReadOnly={true}
                    itemsSource={clients}
                    selectionMode={'Row'}
                    headersVisibility={'Column'}
                    style={{ height: '100%' }}
                    onDoubleClick={(e) => {
                      const grid = gridRef.current.control;
                      const ht = grid.hitTest(e);
                      if (ht.cellType === CellType.Cell) {
                        const item = grid.rows[ht.row].dataItem;
                        hendleClickDetail(item); // 詳細ダイアログを開く処理
                      }
                    }}
                    copying={(s, e) => {
                      // セル単位でコピーする
                      const selection = s.selection;
                      const selectedCellContent = s.getCellData(selection.row, selection.col, true);
                      // クリップボードにコピーされる内容を上書き
                      e.cancel = true;
                      navigator.clipboard.writeText(selectedCellContent);
                    }}
                  >
                    <FlexGridFilter />
                    <FlexGridColumn
                      binding="DM_NO"
                      header="DMナンバー"
                      width={120}
                    ></FlexGridColumn>
                    <FlexGridColumn binding="NAME_1" header="名前" width={'*'}></FlexGridColumn>
                    <FlexGridColumn
                      binding="BUILD_CITY"
                      header="住所"
                      width={'2*'}
                    ></FlexGridColumn>
                    <FlexGridColumn
                      binding="TEL1"
                      header="電話(ご主人)"
                      width={150}
                    ></FlexGridColumn>
                    <FlexGridColumn binding="TEL2" header="電話(奥様)" width={150}></FlexGridColumn>
                    <FlexGridColumn binding="TEL3" header="電話(固定)" width={150}></FlexGridColumn>
                    <FlexGridColumn header="選択" binding="" isReadOnly={true} width={58}>
                      <FlexGridCellTemplate
                        cellType="Cell"
                        template={(cell) => (
                          <Button
                            className="btn-fill"
                            size="sm"
                            variant="secondary"
                            onClick={() => {
                              hendleClickDetail(cell.item);
                            }}
                          >
                            選択
                          </Button>
                        )}
                      />
                    </FlexGridColumn>
                  </FlexGrid>
                </div>
              </Col>
            </Row>
          </Card>
        </DialogContent>
      </Dialog>
      <MessageModal dialog={dialog}></MessageModal>
      {/** 問い合わせ情報モーダル画面 */}
      <ClientCardInquiryModal
        params={inquiryModalparam}
        loadCards={() => {
          props.loadCards();
          setShow(false);
        }}
      />
    </>
  );
}
export default OBSearchModal;
