import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Checkbox, Select, MenuItem } from '@mui/material';
import { toInt } from 'utils/numberUtils';
import { getTagName } from 'components/Input/KoujiTagComponent';
import SelectComponent from 'components/Input/SelectComponent';

// 工事タグ選択コンポーネント
export const KoujiTagSelectComponent = (props) => {
  const labels = props.labels;

  // タグの種類(seireki：西暦,kouki:期,other:その他)
  const [tagType, setTagType] = useState('');
  const [tag, setTag] = useState({
    term: 0, // 期
    year: 0, // 西暦
  });

  // 選択中のタグ
  const [selectedTags, setSelectedTags] = useState([]);

  // タグ種別の編集処理
  const handleOnChangeTagType = (event) => {
    setTagType(event.value);
  };

  // 編集処理
  const handleOnChange = (prop) => (target) => {
    setTag({ ...tag, [prop]: target?.value ?? target?.target.value });
  };
  // 編集処理
  const handleOnChangeMain = (value) => {
    setSelectedTags(value);
  };

  // タグコードを取得
  const getTagCodes = () => {
    if (tagType == 'seireki') {
      return [tag.year];
    } else if (tagType == 'kouki') {
      return [tag.term];
    } else if (tagType == 'other') {
      return selectedTags;
    }
    return [];
  };

  // タグ名を表示
  const getSelectedTagNames = () => {
    return selectedTags.map((t) => getTagName(labels, t)).join('、');
  };

  // 追加ボタン押下
  const handleOnClickAdd = (code) => {
    const tagCodes = getTagCodes();
    props.callAddTag(tagCodes);
    setTagType('');
    setTag({
      term: 0, // 期
      year: 0, // 西暦
    });
    setSelectedTags([]);
  };

  return (
    <div style={{ display: 'flex' }}>
      <SelectComponent
        className="kouji-tag-select"
        value={tagType}
        onChange={handleOnChangeTagType}
      >
        <option key={0} value={''}>
          {'　'}
        </option>
        <option key={1} value={'seireki'}>
          {'西暦'}
        </option>
        <option key={2} value={'kouki'}>
          {'追加工事'}
        </option>
        <option key={2} value={'other'}>
          {'工事内容'}
        </option>
      </SelectComponent>
      {/* その他工事内容タグ */}
      {tagType == 'other' && (
        <Select
          multiple
          displayEmpty
          className="ml-2"
          style={{ height: '38px', width: '320px' }}
          variant="standard"
          value={selectedTags}
          renderValue={(x) => {
            if (x.length === 0) {
              return (
                <em className="pl-2" style={{ color: '#ababab' }}>
                  工事内容タグを選択
                </em>
              );
            }
            return <div className="pl-1">{getSelectedTagNames()}</div>;
          }}
          onChange={(e) => {
            handleOnChangeMain(e.target.value);
          }}
        >
          <MenuItem disabled value="">
            <em>工事内容タグを選択</em>
          </MenuItem>
          {labels.map((x) => (
            <MenuItem key={x.LABEL_CD} value={x.LABEL_CD} className="m-0 p-0">
              <Checkbox checked={selectedTags.indexOf(x.LABEL_CD) > -1} className="m-0 ml-2 p-1" />
              <h5 className="m-0 p-0">{x.DISPLAY}</h5>
            </MenuItem>
          ))}
        </Select>
      )}
      {tagType == 'seireki' && (
        <SelectComponent
          className="ml-2 kouji-tag-select2"
          value={tag.year}
          onChange={handleOnChange('year')}
        >
          <option key={0} value={0}>
            {'　'}
          </option>

          {Array.from({ length: new Date().getFullYear() - 2018 + 1 }, (_, i) => 2018 + i)
            .reverse()
            .map((i) => (
              <option key={i} value={i}>
                {i}年
              </option>
            ))}
        </SelectComponent>
      )}
      {tagType == 'kouki' && (
        <SelectComponent
          className="ml-2 kouji-tag-select2"
          value={tag.term}
          onChange={handleOnChange('term')}
        >
          <option key={0} value={0}>
            {'　'}
          </option>
          {[...Array(10 - 2 + 1).keys()].map((i) => (
            <option key={i} value={i + 2}>
              {i + 2}期
            </option>
          ))}
        </SelectComponent>
      )}

      <Button
        size="sm"
        disabled={getTagCodes().length == 0}
        style={{ width: '48px', height: '32px', marginTop: '5px' }}
        variant="success"
        className="btn-fill ml-2"
        onClick={handleOnClickAdd}
      >
        追加
      </Button>
    </div>
  );
};
