import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextComponent from 'components/Input/TextComponent';
import SelectComponent from 'components/Input/SelectComponent';

import { useRestApi } from 'context/RestApiContext';

// 期限入力用のモーダルダイアログ
function ExpiryModal(props) {
  const restApi = useRestApi();

  const [show, setShow] = useState(false);
  const [expiry, setExpiry] = useState('');
  const [expiryTime, setExpiryTime] = useState('');

  const handleClose = () => setShow(false);

  useEffect(() => {
    if (props?.params?.show) {
      setExpiry(props?.params?.expiry);
      setExpiryTime(props?.params?.expiryTime);
      setShow(true);
    }
  }, [props.params]);

  // 期限の変更処理
  const handleChangeExpiry = (target) => {
    setExpiry(target.value);
  };

  // 期限時刻の変更処理
  const handleChangeExpiryTime = (target) => {
    setExpiryTime(target.value);
  };

  // 保存ボタンをクリック
  const hendleClickSave = () => {
    let expTime = expiryTime ? ` ${expiryTime}` : '';
    let exp = expiry ? expiry + expTime : null;

    restApi.post(`/api/cards/${props?.params?.cardId}`, { EXPIRY: exp }, (data) => {
      props.params.saveAction(expiry);
      setShow(false);
    });
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-start',
          '& .MuiPaper-root': {
            width: '380px',
            backgroundColor: '#f1f2f4', // 背景色の設定
            marginTop: '160px',
          },
        },
      }}
      open={show}
    >
      <DialogTitle>
        期限
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Row>
          <Col md="6" className="p-0 pl-2">
            <TextComponent type="date" value={expiry} onChange={handleChangeExpiry}></TextComponent>
          </Col>
          {expiry && (
            <Col md="6" className="p-0 pl-2">
              <SelectComponent value={expiryTime} onChange={handleChangeExpiryTime}>
                <option value={''}>{'　'}</option>
                {Array.from({ length: 11 }, (_, i) => i + 8)
                  .map((i) => `${i.toString().padStart(2, '0')}:00`)
                  .map((t) => (
                    <option key={t} value={t}>
                      {t}
                    </option>
                  ))}
              </SelectComponent>{' '}
            </Col>
          )}
        </Row>
      </DialogContent>
      <DialogActions>
        <Button className="btn-fill" variant="primary" size="sm" onClick={hendleClickSave}>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default ExpiryModal;
