import React, { useState, useEffect, useCallback } from 'react';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDropzone } from 'react-dropzone';
import { useRestApi } from 'context/RestApiContext';

function ImageImport(props) {
  const baseUrl = process.env.REACT_APP_REST_URL ?? '';
  const restApi = useRestApi();
  const [imageUrl, setImageUrl] = useState(
    props.image
      ? `${baseUrl}/api/client/image/${props.clientCd}/${props.image}?${Date.now()}`
      : null
  ); // 画像URLの状態

  // 画像をリサイズする関数
  const resizeImage = (file, maxWidth, maxHeight, callback) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        // 縦横比を維持してリサイズ
        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width * maxHeight) / height);
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob((blob) => {
          callback(new File([blob], file.name, { type: file.type, lastModified: Date.now() }));
        }, file.type);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  // ドロップ処理
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      // 画像ファイルかどうかをチェック
      if (file && file.type.startsWith('image/')) {
        // アップロード処理
        resizeImage(file, 800, 600, (resizedFile) => {
          restApi.postUpload(
            `/api/client/image/upload/${props.clientCd}/${props.imageNo}`,
            resizedFile,
            (data) => {
              setImageUrl(`${baseUrl}${data.imageUrl}?${Date.now()}`);
              props.callBack(data.fileName);
            }
          );
        });
      } else {
        props.setDialog({ type: 'error', message: '画像ファイルを選択してください' });
      }
    },
    [props.clientCd, props.imageNo, restApi, baseUrl]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  // ファイルの削除処理
  const handleFileRemove = () => {
    setImageUrl(null); // 画像プレビューをクリア
    props.callBack('');
  };

  useEffect(() => {
    if (props?.params?.show) {
      setShow(true);
    }
  }, [props.params]);

  return (
    <>
      {imageUrl ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '20px',
            height: '200px',
            border: '2px solid #ccc',
            borderRadius: '16px',
            position: 'relative',
          }}
        >
          <img
            src={imageUrl}
            alt="Uploaded"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: '16px',
              objectFit: 'cover',
            }}
          />
          <IconButton
            aria-label="delete"
            onClick={handleFileRemove}
            style={{ position: 'absolute', top: 10, right: 10 }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ) : (
        <div
          {...getRootProps()}
          style={{
            border: '2px dashed #ccc',
            borderRadius: '16px',
            padding: '20px',
            textAlign: 'center',
            height: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>ここにファイルをドロップしてください...</p>
          ) : (
            <p>ファイルをドラッグ & ドロップするか、ここをクリックしてファイルを選択してください</p>
          )}
        </div>
      )}
    </>
  );
}

export default ImageImport;
